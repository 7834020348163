import React from 'react';
import {
  useHandleBackgroundColor,
  useHandleHeaderBackClick,
  useNavigateWithParams,
  useProcessStoredOptions,
  useScopedTranslation,
} from '../../../../shared/hooks';
import { designSystem } from '../../../../shared/theme';
import { useOrderContext } from '../../hooks';
import {
  StyledAgreement,
  StyledButtonWrapper,
  StyledCheckoutOptionsWrapper,
  StyledHeader,
  StyledHint,
  StyledPrimaryText,
  StyledSubheader,
} from './styled';
import { OrderOptions } from '../../../../shared/constants/OrderOptions';
import { Button } from '../../../../shared/components/ui';
import { Paths } from '../../../../router/routes';
import useHandlePayment from './hooks/useHandlePayment';
import { CheckoutTotalPrice, MotionContainer } from '../../../../shared/components/custom';
import { Skeleton } from '@chakra-ui/react';
import { LocalStorageKeys } from '../../../../shared/constants/LocalStorageKeys';
import { CheckoutOption } from '../../components/CheckoutOption';

const OrderCheckout = () => {
  const { t } = useScopedTranslation('order.checkout_page');
  const { order } = useOrderContext();
  const { navigate } = useNavigateWithParams();

  useHandleBackgroundColor(designSystem.palette.white);
  useHandleHeaderBackClick(
    true,
    () => {
      if (order) navigate(Paths.orderOptions, { orderId: order?.uuid });
    },
    [order],
  );

  const { storedOptions, resetStoredOptions } = useProcessStoredOptions(
    LocalStorageKeys.orderAdditionalOptions,
  );

  const { createPayment, isPaying } = useHandlePayment();

  const onPay = () => {
    createPayment({ options: storedOptions });
  };

  return (
    <MotionContainer>
      <StyledHeader>
        {t('your_order')}{' '}
        {(order && <StyledPrimaryText>#{order?.id}</StyledPrimaryText>) || (
          <Skeleton w={'12rem'} h={'2rem'} />
        )}
      </StyledHeader>
      <StyledSubheader>{t('check_the_order')}</StyledSubheader>

      <StyledCheckoutOptionsWrapper>
        {storedOptions?.map((option: OrderOptions) => (
          <CheckoutOption
            key={option}
            name={option}
            storageKey={LocalStorageKeys.orderAdditionalOptions}
            onRemove={resetStoredOptions}
          />
        ))}
      </StyledCheckoutOptionsWrapper>

      <CheckoutTotalPrice options={storedOptions} withOrder={false} />

      {!!storedOptions.length && (
        <>
          <StyledButtonWrapper>
            <Button onClick={onPay} isLoading={isPaying} type={'button'} color={'primary'}>
              {t('pay')}
            </Button>
          </StyledButtonWrapper>
          <StyledHint>
            {t('hint')} <StyledAgreement href={'#'}>{t('agreement')}</StyledAgreement>
          </StyledHint>
        </>
      )}
    </MotionContainer>
  );
};

export default OrderCheckout;
