import styled from '@emotion/styled';
import { Flex, Slider, SliderFilledTrack, SliderTrack, Text } from '@chakra-ui/react';
import { Button } from '../Button';
import { ITheme, ThemeColorButton } from '../../../theme';
import { Icon } from '../../icons';

export const StyledPlayerWrapper = styled(Flex)`
  align-items: center;
`;

export const StyledPlayIcon = styled(Icon)`
  margin-left: 2px;
`;

export const StyledPlayPauseButton = styled(Button)<{ color: ThemeColorButton }>`
  min-width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg,
  path,
  rect {
    fill: ${({ color, theme }: { color: ThemeColorButton; theme: ITheme }) =>
      (color === 'secondary' && theme.palette.neutral80) || theme.colors.buttons.text[color]};
  }
`;

export const StyledSlider = styled(Slider)<{ color: ThemeColorButton }>`
  width: 100%;
  border-radius: 0.5rem;
  height: 0.75rem;
  margin-left: 0.75rem;
`;

export const StyledSliderTrack = styled(SliderTrack)`
  background: #7f859629;
  height: 0.75rem;
`;

export const StyledSliderFilledTrack = styled(SliderFilledTrack)<{ color: ThemeColorButton }>`
  height: 0.75rem;
  border-radius: 0.5rem;
  background: ${({ color, theme }: { color: ThemeColorButton; theme: ITheme }) =>
    (color === 'secondary' && theme.palette.neutral80) || theme.colors.buttons.bg.primary};
  transition: 0.3s;
`;

export const StyledLabel = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.main};
  margin-left: 0.75rem;
`;
