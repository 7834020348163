import React, { useMemo, useState } from 'react';
import {
  useHandleBackgroundColor,
  useHandleHeaderBackClick,
  useNavigateWithParams,
  useProcessStoredOptions,
  useScopedTranslation,
} from '../../../../shared/hooks';
import { designSystem } from '../../../../shared/theme';
import { useOrderContext } from '../../hooks';
import {
  StyledAgreement,
  StyledButtonWrapper,
  StyledCheckoutOptionsWrapper,
  StyledHeader,
  StyledHint,
  StyledPrimaryText,
  StyledSubheader,
} from './styled';
import { OrderOptions } from '../../../../shared/constants/OrderOptions';
import { Button } from '../../../../shared/components/ui';
import { Paths } from '../../../../router/routes';
import useHandlePayment from './hooks/useHandlePayment';
import {
  CheckoutPromocodeInput,
  CheckoutTotalPrice,
  MotionContainer,
} from '../../../../shared/components/custom';
import { Skeleton } from '@chakra-ui/react';
import { LocalStorageKeys } from '../../../../shared/constants/LocalStorageKeys';
import { DiscountObject } from '../../../../shared/components/custom/CheckoutPromocodeInput/CheckoutPromocodeInput';
import useProcessOrderOffer from '../../hooks/useProcessOrderOffer';
import { CheckoutOption } from '../../components/CheckoutOption';

const OrderCheckout = () => {
  const { t } = useScopedTranslation('order.checkout_page');
  const { order } = useOrderContext();
  const { navigate } = useNavigateWithParams();
  const [discount, setDiscount] = useState<DiscountObject>();
  const { price } = useProcessOrderOffer();
  const orderPrice = useMemo(() => price?.current, [price]);

  useHandleBackgroundColor(designSystem.palette.white);
  useHandleHeaderBackClick(
    true,
    () => {
      if (order) navigate(Paths.orderOptions, { orderId: order?.uuid });
    },
    [order],
  );

  const { storedOptions, resetStoredOptions } = useProcessStoredOptions(
    LocalStorageKeys.orderOptions,
  );

  const { createPayment, isPaying } = useHandlePayment();

  const onPay = () => {
    createPayment({ promocode: discount?.promocode ?? '', options: storedOptions });
  };

  return (
    <MotionContainer>
      <StyledHeader>
        {t('your_order')}{' '}
        {(order && <StyledPrimaryText>#{order?.id}</StyledPrimaryText>) || (
          <Skeleton w={'12rem'} h={'2rem'} />
        )}
      </StyledHeader>
      <StyledSubheader>{t('check_the_order')}</StyledSubheader>

      <StyledCheckoutOptionsWrapper>
        <CheckoutOption
          price={orderPrice}
          name={OrderOptions.duet}
          isOrder
          storageKey={LocalStorageKeys.orderOptions}
          onRemove={resetStoredOptions}
        />

        {storedOptions?.map((option: OrderOptions) => (
          <CheckoutOption
            key={option}
            storageKey={LocalStorageKeys.orderOptions}
            name={option}
            onRemove={resetStoredOptions}
          />
        ))}
      </StyledCheckoutOptionsWrapper>

      <CheckoutTotalPrice options={storedOptions} discount={discount?.discount ?? 0} />

      <CheckoutPromocodeInput onDiscountUpdate={setDiscount} />

      <StyledButtonWrapper>
        <Button onClick={onPay} isLoading={isPaying} type={'button'} color={'primary'}>
          {t('pay')}
        </Button>
      </StyledButtonWrapper>

      <StyledHint>
        {t('hint')} <StyledAgreement href={'#'}>{t('agreement')}</StyledAgreement>
      </StyledHint>
    </MotionContainer>
  );
};

export default OrderCheckout;
