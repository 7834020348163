import { OrderOptions } from '../../../shared/constants/OrderOptions';
import { useOrderContext } from './index';
import { useMemo } from 'react';

const useGetOrderOption = (name: OrderOptions) => {
  const { order } = useOrderContext();
  return useMemo(() => order?.options?.find((option) => option.name === name), [order]);
};

export default useGetOrderOption;
