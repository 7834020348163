import React from 'react';
import { LandingLayout } from '../../shared/components/custom';
import { Outlet } from 'react-router-dom';

const LandingPage = () => {
  return (
    <LandingLayout>
      <Outlet />
    </LandingLayout>
  );
};

export default LandingPage;
