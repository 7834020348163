import React from 'react';
import { Icon } from '../../../../../../shared/components/icons';
import {
  StyledEditOptionButton,
  StyledOptionCard,
  StyledOptionCardButton,
  StyledOptionCardHeaderWithEdit,
  StyledOptionCardIcon,
  StyledPostcardPreview,
  StyledPostcardTitle,
  StyledPostcardText,
  StyledOptionCardHeader,
  StyledOptionCardLinkButton,
} from '../styled';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import { Paths } from '../../../../../../router/routes';
import useRedirectToOrder from '../../../../hooks/useRedirectToOrder';
import { useOrderContext } from '../../../../hooks';
import { shareUrl } from '../../../../../../shared/utils/utils';
import { ButtonSizes } from '../../../../../../shared/theme';
import { Spacer } from '@chakra-ui/react';
import PaidOverlay from './PaidOverlay';

const OptionIcon = () => (
  <StyledOptionCardIcon>
    <Icon type={'PostCard'} />
  </StyledOptionCardIcon>
);

const PostCard = () => {
  const { t } = useScopedTranslation('order.editors.postcard');
  const { routeWithOrder } = useRedirectToOrder();
  const { postcard } = useOrderContext();

  const share = () => {
    shareUrl(postcard?.share_url ?? '', t('share_postcard'));
  };

  return (
    <StyledOptionCard>
      <PaidOverlay />
      {(postcard && (
        <>
          <StyledOptionCardHeaderWithEdit>
            <span>{t('postcard')}</span>
            <StyledEditOptionButton to={routeWithOrder(Paths.orderPostcardEditorSongSelect)}>
              {t('edit')}
            </StyledEditOptionButton>
          </StyledOptionCardHeaderWithEdit>
          <StyledPostcardPreview to={routeWithOrder(Paths.orderPostcard)}>
            <StyledPostcardTitle>{postcard.title}</StyledPostcardTitle>
            <StyledPostcardText>{postcard.text}</StyledPostcardText>
          </StyledPostcardPreview>
          <StyledOptionCardButton onClick={share} size={ButtonSizes.md}>
            <Icon type={'Share'} color={'primary'} />
            {t('share')}
          </StyledOptionCardButton>
        </>
      )) || (
        <>
          <StyledOptionCardHeader>{t('postcard')}</StyledOptionCardHeader>
          <Spacer />
          <OptionIcon />
          <Spacer />
          <StyledOptionCardLinkButton to={routeWithOrder(Paths.orderPostcardEditorSongSelect)}>
            <Icon type={'Plus'} color={'primary'} />
            <span>{t('create_postcard')}</span>
          </StyledOptionCardLinkButton>
        </>
      )}
    </StyledOptionCard>
  );
};

export default PostCard;
