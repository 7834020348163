import React from 'react';
import DesktopRecorder from './DesktopRecorder';
import MobileRecorder from './MobileRecorder';
import { useBreakpoints } from '../../../../../shared/hooks';

export type IRecorderPage = {
  isPreview: boolean;
  isRecording: boolean;
  previewComponent: React.ReactNode;
  children: React.ReactNode;
};

const RecorderWrapper = ({ children, ...props }: IRecorderPage) => {
  const { isLg } = useBreakpoints();

  return (
    (isLg && <DesktopRecorder {...props}>{children}</DesktopRecorder>) || (
      <MobileRecorder {...props}>{children}</MobileRecorder>
    )
  );
};

export default RecorderWrapper;
