import React, { useRef, useState } from 'react';
import { StyledPlayButton, StyledVideoContainer } from './styled';
import { Icon } from '../../icons';
import { ThemeColorIcon } from '../../../theme';

const VideoPlayer = ({
  src,
  color = 'secondary',
  ...props
}: {
  src: string;
  color?: ThemeColorIcon;
}) => {
  const [isPlaying, setIsPLaying] = useState(false);
  const video = useRef<HTMLVideoElement>(null);

  const onPlay = () => {
    setIsPLaying(true);
  };

  const onPause = () => {
    setIsPLaying(false);
  };

  const toggleVideo = () => {
    (video?.current?.paused && video?.current?.play()) || video?.current?.pause();
  };

  const setFullScreen = () => {
    video?.current?.requestFullscreen();
  };

  return (
    <StyledVideoContainer {...props} onClick={onPlay} actionhidden={isPlaying}>
      <video
        onDoubleClick={setFullScreen}
        onPlay={onPlay}
        onPause={onPause}
        ref={video}
        src={`${src}#t=2`}
        preload={'metadata'}
        controls={isPlaying}
      />
      <StyledPlayButton onClick={toggleVideo} fab>
        {(isPlaying && <Icon type={'Pause'} color={color} />) || (
          <Icon type={'Play'} color={color} />
        )}
      </StyledPlayButton>
    </StyledVideoContainer>
  );
};

export default VideoPlayer;
