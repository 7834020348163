import styled from '@emotion/styled';
import { LinkButton, ModalBody } from '../../../../../../shared/components/ui';

export const StyledMenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  justify-content: center;
`;

export const StyledMenuItem = styled.a`
  font-size: ${({ theme }) => theme.fontSize.xl3};
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  text-align: center;
  display: block;
  line-height: 2rem;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const StyledMenuDivider = styled.div`
  width: 12.25rem;
  height: 1px;
  background: ${({ theme }) => theme.palette.neutral30};
  margin: 2rem auto;
`;

export const StyledTryButton = styled(LinkButton)`
  width: 15rem;
  margin-inline: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const StyledModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
