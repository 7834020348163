import React from 'react';
import Artist1 from '../../../../../shared/theme/assets/img/artists/1.png';
import Artist2 from '../../../../../shared/theme/assets/img/artists/2.png';
import Artist3 from '../../../../../shared/theme/assets/img/artists/3.png';
import Artist4 from '../../../../../shared/theme/assets/img/artists/4.png';
import Artist5 from '../../../../../shared/theme/assets/img/artists/5.png';
import Artist6 from '../../../../../shared/theme/assets/img/artists/6.png';
import Artist7 from '../../../../../shared/theme/assets/img/artists/7.png';
import Artist8 from '../../../../../shared/theme/assets/img/artists/8.png';
import { StyledArtistsWrapper } from '../../../styled';

const ArtistsImages = () => {
  return (
    <StyledArtistsWrapper>
      <img src={Artist1} alt={'artist_1'} />
      <img src={Artist2} alt={'artist_2'} />
      <img src={Artist3} alt={'artist_3'} />
      <img src={Artist4} alt={'artist_4'} />
      <img src={Artist5} alt={'artist_5'} />
      <img src={Artist6} alt={'artist_6'} />
      <img src={Artist7} alt={'artist_7'} />
      <img src={Artist8} alt={'artist_8'} />
    </StyledArtistsWrapper>
  );
};

export default ArtistsImages;
