import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import {
  StyledArtistWrapper,
  StyledCountdownWrapper,
  StyledOrderInfoWrapper,
  StyledSocialButtonsWrapper,
  StyledSocialsSkeleton,
  StyledWrapper,
} from './styled';
import { CardArtistSkeleton } from '../../../../../shared/components/custom/CardArtist';

const OrderCreatedSkeleton = () => {
  return (
    <div>
      <StyledWrapper>
        <StyledOrderInfoWrapper>
          <Skeleton w={'70%'} height={'4rem'} />
          <Skeleton w={'90%'} height={'2rem'} mt={'1rem'} />
          <Skeleton w={'78%'} height={'4rem'} mt={'1rem'} />
          <StyledCountdownWrapper>
            <Skeleton w={'8rem'} height={'3rem'} />
            <Skeleton w={'80%'} height={'3rem'} />
          </StyledCountdownWrapper>
        </StyledOrderInfoWrapper>
        <StyledArtistWrapper>
          <CardArtistSkeleton />
        </StyledArtistWrapper>
      </StyledWrapper>
      <StyledSocialsSkeleton height={'2rem'} />
      <StyledSocialButtonsWrapper>
        <Skeleton w={'100%'} height={'4rem'} />
        <Skeleton w={'100%'} height={'4rem'} />
      </StyledSocialButtonsWrapper>
    </div>
  );
};

export default OrderCreatedSkeleton;
