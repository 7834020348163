import { useState } from 'react';
import { MailingApi } from '../../api';
import { showToast } from '../components/ui';
import { useScopedTranslation } from '../hooks';

export const useMailingHook = () => {
  const { t } = useScopedTranslation('footer');

  const [isLoading, setIsLoading] = useState(false);

  const subscribe = async (email: string) => {
    setIsLoading(true);
    await MailingApi.subscribe(email)
      .then(() => {
        showToast('success', t('subscribed'));
      })
      .catch(() => {});
    setIsLoading(false);
  };

  const unsubscribe = async (email: string) => {
    setIsLoading(true);
    await MailingApi.unsubscribe(email)
      .then(() => {})
      .catch(() => {});
    setIsLoading(false);
  };

  return { subscribe, unsubscribe, isLoading };
};
