import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form';
import { StyledDatepicker, StyledDatepickerButton, StyledPlaceholder } from './styled';
import 'react-datepicker/dist/react-datepicker.css';
import { DATE_FORMAT } from '../../../constants';
import { forwardRef } from '@chakra-ui/react';
import { Icon } from '../../icons';
import DatepickerHeader from './DatepickerHeader';
import { FormErrorMessage } from '../FormErrorMessage';
import { useBreakpoints } from '../../../hooks';

type IDatePickerName = `${string}` | `${string}.${string}` | `${string}.${number}`;

type CustomDatePickerProps = {
  control: Control;
  errors?: FieldErrorsImpl<Record<string, string>>;
  name: IDatePickerName;
  placeholder?: string;
};

const CustomInput = forwardRef(({ value, onClick, placeholder }, ref) => (
  <StyledDatepickerButton onClick={onClick}>
    {(!value && <StyledPlaceholder>{placeholder}</StyledPlaceholder>) || value}
    <Icon onClick={onClick} type={'SelectToggler'} />
  </StyledDatepickerButton>
));

const Datepicker = ({
  control,
  name,
  errors = {},
  placeholder,
  ...props
}: CustomDatePickerProps) => {
  const { isMd } = useBreakpoints();

  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <StyledDatepicker>
            <ReactDatePicker
              renderCustomHeader={(headerProps) => <DatepickerHeader {...headerProps} />}
              withPortal={!isMd}
              dateFormat={DATE_FORMAT}
              customInput={<CustomInput />}
              {...{
                ...props,
                placeholderText: placeholder,
                onChange: (date) => field.onChange(date),
                selected: field.value,
              }}
            />
          </StyledDatepicker>
        )}
      />
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </div>
  );
};

export default Datepicker;
