import { useState } from 'react';
import { FunnelApi } from '../../../api';
import { Personalization } from '../../../shared/types/personalization';

const useLoadYears = () => {
  const [years, setYears] = useState<Personalization[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getYears = async () => {
    setIsLoading(true);

    const resp = await FunnelApi.getYears()
      .then((response) => {
        setYears(response);
        return response;
      })
      .finally(() => setIsLoading(false));
    return resp;
  };

  return { years, getYears, isLoading };
};

export default useLoadYears;
