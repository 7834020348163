import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';
import { Language } from '../types/language';
import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { setDefaultOptions } from 'date-fns';

const resources: Record<string, { translation: object; dateLocale: Locale }> = {
  en: {
    translation: translationEN,
    dateLocale: en,
  },
  ru: {
    translation: translationRU,
    dateLocale: ru,
  },
};

const defaultLocale = Language.ENGLISH;

const setDatepickerLocale = () => {
  Object.keys(resources).map((locale: string) => {
    registerLocale(locale, resources[locale].dateLocale);
    return locale;
  });
  setDefaultLocale(defaultLocale);
};

export const setDefaultDateLocale = () => {
  setDefaultOptions({
    locale: resources[defaultLocale].dateLocale,
  });
  setDatepickerLocale();
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: false,
    lng: defaultLocale,
    fallbackLng: Language.ENGLISH,
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
