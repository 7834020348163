import styled from '@emotion/styled';
import { StyledContainer } from '../../styled';
import { Text } from '@chakra-ui/react';

export const StyledRepliesContainer = styled(StyledContainer)`
  padding-top: 9.75rem;
  padding-bottom: 9.75rem;
  position: relative;

  > img {
    position: absolute;

    &:first-of-type {
      right: 3rem;
      top: 7.5rem;
    }

    &:last-of-type {
      left: 0;
      bottom: 7.5rem;
    }
  }

  > div {
    z-index: 2;
    position: relative;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-top: 3rem;
    padding-bottom: 2rem;

    img {
      display: none;
    }
  }
`;

export const StyledRepliesHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.darker};
  font-size: ${({ theme }) => theme.fontSize.xl5};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  line-height: 3.75rem;
  margin-bottom: 1rem;
  white-space: pre-line;
  text-align: center;

  > span {
    color: ${({ theme }) => theme.palette.primary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    line-height: 2.25rem;
    text-align: start;
    white-space: unset;
    max-width: 20rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    max-width: unset;
  }
`;
