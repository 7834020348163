import styled from '@emotion/styled';
import { Flex } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { ContentContainer } from '../../ui';

export const StyledAppLayout = styled(Flex)<{ background?: string }>`
  flex-direction: column;
  transition: background 0.3s ease-in;

  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `};
`;

export const StyledContentContainer = styled(ContentContainer)`
  min-height: calc(100vh - 10.75rem);
  padding-top: 1.75rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    //padding-top: 0.75rem;
  }
`;
