import React from 'react';
import {
  StyledButtonWrapper,
  StyledContainer,
  StyledContent,
  StyledInWorkHeader,
  StyledInWorkSubHeader,
  StyledInWorkWrapper,
} from './styled';
import OptionImage from '../../../theme/assets/img/misc/video_success.svg';
import VoiceImage from '../../../theme/assets/img/misc/voice_success.svg';
import { OrderOptions } from '../../../constants/OrderOptions';
import { useHandleBackgroundColor } from '../../../hooks';
import { designSystem } from '../../../theme';
import { Spacer } from '@chakra-ui/react';

const OrderOptionIsProcessing = ({
  title,
  subheader,
  actionButton,
  type,
}: {
  title: string;
  subheader: string;
  actionButton: React.ReactNode;
  type: OrderOptions;
}) => {
  useHandleBackgroundColor(designSystem.palette.white);

  return (
    <StyledContainer>
      <StyledInWorkWrapper>
        <StyledContent>
          <img
            src={(type === OrderOptions.clientVoice && VoiceImage) || OptionImage}
            alt={'congratulations'}
          />
          <StyledInWorkHeader>{title}</StyledInWorkHeader>
          <StyledInWorkSubHeader>{subheader}</StyledInWorkSubHeader>
        </StyledContent>
        <Spacer />
        <StyledButtonWrapper>{actionButton}</StyledButtonWrapper>
      </StyledInWorkWrapper>
    </StyledContainer>
  );
};

export default OrderOptionIsProcessing;
