import { useMemo } from 'react';
import useOrderContext from './useOrderContext';
import { useParams } from 'react-router-dom';

const useGetOrderId = () => {
  const { orderId } = useParams();
  const { order } = useOrderContext();
  return useMemo(() => order?.uuid ?? orderId ?? '', [order]);
};

export default useGetOrderId;
