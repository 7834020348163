import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';
import { Carousel } from '../../../../../../shared/components/ui';

export const StyledArtistsBg = styled.div`
  background: ${({ theme }) => theme.palette.neutral5};
  padding-top: 6.25rem;
  padding-bottom: 6.75rem;

  > div > div {
    padding-bottom: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-top: 1rem;
    padding-bottom: 2.5rem;
  }
`;

export const StyledArtistsHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.darker};
  font-size: ${({ theme }) => theme.fontSize.xl5};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  line-height: 3.75rem;
  margin-bottom: 3.75rem;
  white-space: pre-line;

  span {
    color: ${({ theme }) => theme.palette.primary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    line-height: 2.25rem;
    white-space: unset;
    margin-bottom: 1.5rem;
    max-width: 15rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    max-width: unset;
  }
`;

export const StyledCarousel = styled(Carousel)`
  .slick-track {
    padding-bottom: 2rem;
  }

  .slick-slide {
    position: relative;

    &::after {
      transition: 0.3s;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(248, 251, 255, 0.4);
    }

    &.slick-current {
      &::after {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: -1.25rem;
    margin-right: -1.25rem;

    .slick-slide {
      padding-left: 1.25rem;
    }

    .slick-slide:last-of-type {
      padding-right: 1.25rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    .slick-slide {
      padding-right: 0.75rem;
    }
  }
`;

export const StyledArtistCard = styled.div`
  min-width: 44rem;
  min-height: 12.5rem;
  background: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.radii.md};
  display: grid;
  grid-template-columns: 12.5rem 1fr;
  grid-gap: 1rem;
  overflow: hidden;
  filter: drop-shadow(0px 8px 16px rgba(223, 227, 231, 0.48));
  margin-right: 2rem;

  img {
    width: 100%;
    height: 100%;
    max-height: 12.5rem;
    object-fit: cover;
    background: ${({ theme }) => theme.palette.neutral10};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
    width: 100%;
    grid-gap: 0;

    img {
      width: 100%;
      height: 12.5rem;
      object-fit: cover;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: unset;
  }
`;

export const StyledArtistInfo = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    color: ${({ theme }) => theme.colors.text.darker};
    font-size: ${({ theme }) => theme.fontSize.xl2};
    font-family: ${({ theme }) => theme.fontFamily.headingBold};
    line-height: 2rem;
    margin-bottom: 0.5rem;
  }

  p {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-bottom: 1.5rem;

    p {
      color: ${({ theme }) => theme.colors.text.darker};
      margin-bottom: 0.75rem;
    }

    > div:first-of-type {
      p {
        min-height: 4.5rem;
      }
    }

    > div:last-of-type > p:last-of-type {
      margin: 0 0 0 0.75rem;
      font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    }
  }
`;
