import React from 'react';
import {
  StyledBackButton,
  StyledHeaderWrapper,
  StyledLogo,
  StyledMenuButtonWrapper,
  StyledPriceBadge,
} from './styled';
import { Icon } from '../../icons';
import Logo from '../../../theme/assets/img/logo.svg';
import { Link } from 'react-router-dom';
import { Paths } from '../../../../router/routes';
import { useHandleCartButton } from '../../../hooks';

type IHeader = {
  backButton: boolean;
  onBackClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
};

const Header = ({ backButton, onBackClick }: IHeader) => {
  const { cartPositions, onCartClick, isShown } = useHandleCartButton();

  return (
    <StyledHeaderWrapper>
      <StyledMenuButtonWrapper>
        {backButton && (
          <StyledBackButton onClick={onBackClick} type={'button'}>
            <Icon type={'ArrowLeft'} color={'secondary'} />
          </StyledBackButton>
        )}
      </StyledMenuButtonWrapper>
      <StyledLogo>
        <Link to={Paths.funnel}>
          <img src={Logo} alt={'logo'} />
        </Link>
      </StyledLogo>
      <StyledMenuButtonWrapper>
        {isShown && (
          <StyledBackButton onClick={onCartClick} type={'button'}>
            <Icon type={'Cart2'} color={'secondary'} />
            {!!cartPositions?.length && (
              <StyledPriceBadge>{cartPositions?.length}</StyledPriceBadge>
            )}
          </StyledBackButton>
        )}
      </StyledMenuButtonWrapper>
    </StyledHeaderWrapper>
  );
};

export default Header;
