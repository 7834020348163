import React from 'react';
import {
  useHandleBackgroundColor,
  useHandleHeaderBackClick,
  useScopedTranslation,
} from '../../../../shared/hooks';
import { designSystem } from '../../../../shared/theme';
import {
  StyledCheckoutButtonWrapper,
  StyledDivider,
  StyledHint2Text,
  StyledHintDivider,
  StyledHintWrapper,
  StyledOptionsHeader,
  StyledOptionsSubheader,
  StyledOptionsWrapper,
} from './styled';
import { LinkButton } from '../../../../shared/components/ui';
import { Paths } from '../../../../router/routes';
import { useOrderContext } from '../../hooks';
import OrderOptionVoice from '../../../../shared/components/custom/OrderOptionCard/OrderOptionVoice';
import OrderOptionVideo from '../../../../shared/components/custom/OrderOptionCard/OrderOptionVideo';
import OrderOptionDuet from '../../../../shared/components/custom/OrderOptionCard/OrderOptionDuet';
import { Icon } from '../../../../shared/components/icons';
import { MotionContainer } from '../../../../shared/components/custom';
import useRedirectToOrder from '../../hooks/useRedirectToOrder';

const OrderOptions = () => {
  const { t, rootT } = useScopedTranslation('order.options_page');
  const { order } = useOrderContext();
  const { navigateToOrder, routeWithOrder } = useRedirectToOrder();
  useHandleBackgroundColor(designSystem.palette.neutral5);

  useHandleHeaderBackClick(
    true,
    () => {
      if (order) navigateToOrder();
    },
    [order],
  );

  return (
    <MotionContainer>
      <StyledOptionsHeader>{t('header')}</StyledOptionsHeader>
      <StyledOptionsSubheader>{t('subheader')}</StyledOptionsSubheader>

      <StyledOptionsWrapper>
        <OrderOptionVoice withAction bordered />
        <OrderOptionVideo withAction bordered />
        <OrderOptionDuet withAction bordered />
      </StyledOptionsWrapper>

      <StyledCheckoutButtonWrapper>
        <LinkButton to={routeWithOrder(Paths.orderCheckout)} color={'primary'}>
          {rootT('order.checkout')}
        </LinkButton>
      </StyledCheckoutButtonWrapper>

      <StyledDivider />

      <StyledHintWrapper>
        <Icon type={'Info'} />
        <span>{t('instruction')}</span>
      </StyledHintWrapper>

      <StyledHint2Text>{t('options_hint')}</StyledHint2Text>

      <StyledHintDivider />
    </MotionContainer>
  );
};

export default OrderOptions;
