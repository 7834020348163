import styled from '@emotion/styled';

export const StyledMailingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;

  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl3};
  color: ${({ theme }) => theme.colors.text.main};

  > div {
    display: flex;
    align-items: center;
    grid-gap: 1rem;

    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    font-size: ${({ theme }) => theme.fontSize.md};
    color: ${({ theme }) => theme.colors.text.placeholder};
  }
`;
