import React, { useMemo, useState } from 'react';
import {
  StyledSongCard,
  StyledSongText,
  StyledSongTextHeader,
  StyledSongTextWrapper,
  StyledSongVersionSelectWrapper,
} from '../styled';
import { useOrderContext } from '../../../../hooks';
import { useGetSongVersions, useScopedTranslation } from '../../../../../../shared/hooks';
import { Select } from '../../../../../../shared/components/ui';

const SongText = () => {
  const { t } = useScopedTranslation('order.song_text');
  const { order } = useOrderContext();
  const versions = useGetSongVersions(order);
  const [selectedVersion, setSelectedVersion] = useState<string>(versions[0]?.name ?? '');

  const mappedVersions = useMemo(
    () => versions.map(({ name }) => ({ value: name, label: name })),
    [versions],
  );

  const onVersionChange = ({ currentTarget }: { currentTarget: HTMLSelectElement }) => {
    setSelectedVersion(currentTarget.value);
  };

  const lyrics = useMemo(
    () => versions.find((version) => version.name === selectedVersion)?.lyrics,
    [selectedVersion, versions],
  );

  return (
    <StyledSongCard>
      <StyledSongTextWrapper>
        <StyledSongTextHeader>{t('select_song_version')}</StyledSongTextHeader>
        <StyledSongVersionSelectWrapper>
          <Select
            value={selectedVersion}
            options={mappedVersions}
            onChange={onVersionChange}
            name={'song_version'}
            translationScope={'order.versions'}
          />
        </StyledSongVersionSelectWrapper>
        <StyledSongText>{lyrics}</StyledSongText>
      </StyledSongTextWrapper>
    </StyledSongCard>
  );
};

export default SongText;
