import React from 'react';
import { StyledDesktopWrapperHeader, StyledVisualContainer } from '../styled';
import { VoiceVisualization } from '../../../../../shared/components/custom';
import { useScopedTranslation } from '../../../../../shared/hooks';

const VoiceVisual = ({ isWorking }: { isWorking: boolean }) => {
  const { t } = useScopedTranslation('order.editors.client_voice');

  return (
    <>
      <StyledDesktopWrapperHeader>{t('recording')}</StyledDesktopWrapperHeader>
      <StyledVisualContainer>
        <VoiceVisualization isWorking={isWorking} />
      </StyledVisualContainer>
    </>
  );
};

export default VoiceVisual;
