import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Option } from '../types/order';
import { FunnelApi } from '../../api';

const useLoadOrderOptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);

  const { orderId } = useParams();

  const loadOptions = async () => {
    setIsLoading(true);

    await FunnelApi.getOptions()
      .then((response) => {
        setOptions(response);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    loadOptions();
  }, [orderId]);

  return { options, isLoading };
};

export default useLoadOrderOptions;
