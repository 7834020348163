import React, { useMemo } from 'react';
import {
  StyledOfferCard,
  StyledOfferPriceCrossed,
  StyledOfferPrice,
  StyledOfferSubtitle,
  StyledOfferTitle,
  StyledOfferWrapper,
  StyledOfferButtonWrapper,
  StyledCountdown,
  StyledOrderExpirationWrapper,
  StyledDesktopArtistCard,
  StyledOfferCardDivider,
  StyledGiftRobot,
} from './styled';
import { Flex, Skeleton } from '@chakra-ui/react';
import PriceOverline from '../../../../../shared/theme/assets/img/order/price_overline.svg';
import GiftRobot from '../../../../../shared/theme/assets/img/misc/one_off_deal.svg';
import { Icon } from '../../../../../shared/components/icons';
import { format, parseISO } from 'date-fns';
import OrderArtistCard from './components/OrderArtistCard';
import { useNavigateWithParams, useScopedTranslation } from '../../../../../shared/hooks';
import { useGetOrderId } from '../../../hooks';
import { Paths } from '../../../../../router/routes';
import { LinkButton } from '../../../../../shared/components/ui';
import useProcessOrderOffer from '../../../hooks/useProcessOrderOffer';

const OrderOffer = () => {
  const { t } = useScopedTranslation('order');
  const { urlWithParams } = useNavigateWithParams();
  const orderId = useGetOrderId();
  const { expiresAt, isOfferActive, onOfferEnded, price } = useProcessOrderOffer();

  const expiresDay = useMemo(() => {
    if (!expiresAt || !isOfferActive) return null;
    const date = parseISO(expiresAt);
    return format(date, 'LLLL do');
  }, [expiresAt, isOfferActive]);

  return (
    <StyledOfferWrapper>
      <StyledDesktopArtistCard>
        <OrderArtistCard />
      </StyledDesktopArtistCard>
      <div>
        <StyledOfferCard>
          <StyledGiftRobot src={GiftRobot} alt={'gift'} />

          <div>
            <StyledOfferTitle>
              {t((isOfferActive && 'one_time_offer') || 'buy_full')}
            </StyledOfferTitle>
            <StyledOfferSubtitle>{t('buy_a_song')}</StyledOfferSubtitle>
          </div>
          <Flex gap={'0.5rem'} alignItems={'center'}>
            {(price.current && (
              <>
                <StyledOfferPrice>${price.current}</StyledOfferPrice>
                {isOfferActive && (
                  <StyledOfferPriceCrossed>
                    <span>${price.default}</span>
                    <img src={PriceOverline} alt={'price overline'} />
                  </StyledOfferPriceCrossed>
                )}
              </>
            )) || <Skeleton w={'7rem'} h={'2rem'} />}
          </Flex>
          <StyledOfferButtonWrapper>
            <LinkButton to={urlWithParams(Paths.orderOptions, { orderId })} color={'primary'}>
              {t('checkout')}
            </LinkButton>
            {isOfferActive && (
              <>
                <StyledOfferCardDivider />
                <StyledCountdown
                  date={expiresAt}
                  units={['hours', 'minutes', 'seconds']}
                  onTargetReach={onOfferEnded}
                />
              </>
            )}
          </StyledOfferButtonWrapper>
        </StyledOfferCard>

        {isOfferActive && (
          <StyledOrderExpirationWrapper>
            <Icon type={'Info'} />
            <span>
              {t('unpaid_orders_1')} <b>{expiresDay}</b>
              {t('unpaid_orders_2')}
            </span>
          </StyledOrderExpirationWrapper>
        )}
      </div>
    </StyledOfferWrapper>
  );
};

export default OrderOffer;
