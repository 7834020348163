import React from 'react';
import { useCalculateTotalPrice, useScopedTranslation } from '../../../hooks';
import {
  StyledPromocodeSuccess,
  StyledPrice,
  StyledPriceDiscount,
  StyledPriceLabel,
} from './styled';
import { OrderOptions } from '../../../constants/OrderOptions';

const CheckoutTotalPrice = ({
  options,
  discount = 0,
  withOrder = true,
}: {
  options: OrderOptions[];
  discount?: number;
  withOrder?: boolean;
}) => {
  const { t } = useScopedTranslation('order.checkout_page');

  const { totalPrice, totalPriceWithDiscount } = useCalculateTotalPrice({
    storedOptions: options,
    discount,
    withOrder,
  });

  return (
    <>
      <StyledPriceLabel>{t('total')}</StyledPriceLabel>
      {(!discount && <StyledPrice>${totalPrice}</StyledPrice>) || (
        <>
          <StyledPriceDiscount>
            <span>${totalPrice}</span> ${totalPriceWithDiscount}
          </StyledPriceDiscount>
          {!!discount && (
            <StyledPromocodeSuccess>{t('promocode_applied', { discount })}</StyledPromocodeSuccess>
          )}
        </>
      )}
    </>
  );
};

export default CheckoutTotalPrice;
