import React, { useMemo } from 'react';
import { Icon } from '../../../../shared/components/icons';
import { Card, Select } from '../../../../shared/components/ui';
import { useScopedTranslation } from '../../../../shared/hooks';
import { FieldErrorsImpl } from 'react-hook-form';
import useFunnelContext from '../../hooks/useFunnelContext';

type IRecipient = {
  register: Function;
  errors: FieldErrorsImpl;
  isDisabled?: boolean;
};

const Recipient = ({ register, errors, isDisabled = false }: IRecipient) => {
  const { t } = useScopedTranslation('funnel.general_information');

  const {
    values: { recipients },
  } = useFunnelContext();

  const options = useMemo(() => {
    return recipients.map(({ id, name }) => ({ value: id, label: name }));
  }, [recipients]);

  return (
    <Card title={t('recipient')} icon={<Icon type={'Gift'} color={'primary'} />}>
      <Select
        {...{
          register,
          errors,
          name: 'recipient',
          placeholder: t('select_recipient'),
          isDisabled,
          options,
          translationScope: 'funnel.general_information.segments',
        }}
      />
    </Card>
  );
};

export default Recipient;
