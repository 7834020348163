import React from 'react';
import {
  StyledButtonWrapper,
  StyledDeleteButton,
  StyledDesktopWrapperHeader,
  StyledPreviewContainer,
  StyledPreviewWrapper,
  StyledSubheader,
  StyledWaveIcon,
} from '../styled';
import { AudioPlayer, Button } from '../../../../../shared/components/ui';
import { Icon } from '../../../../../shared/components/icons';
import { ButtonSizes } from '../../../../../shared/theme';
import { useScopedTranslation } from '../../../../../shared/hooks';
import { ConfirmDialog } from '../../../../../shared/components/custom';

const VoicePreview = ({
  url,
  onDelete,
  onUpload,
  isLoading = false,
}: {
  url: string;
  onDelete: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onUpload: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading?: boolean;
}) => {
  const { t } = useScopedTranslation('order.editors.client_voice');

  return (
    <>
      <StyledWaveIcon type={'Wave'} />

      <StyledDesktopWrapperHeader>{t('your_voice_for_intro')}</StyledDesktopWrapperHeader>
      <StyledSubheader>{t('listen_recording')}</StyledSubheader>

      <StyledPreviewContainer>
        <StyledPreviewWrapper>
          <AudioPlayer src={url} withProgress color={'primaryLight'} />
          <ConfirmDialog
            title={t('are_you_sure')}
            confirmLabel={t('delete_voice')}
            cancelLabel={t('cancel')}
            onConfirm={onDelete}
            activator={
              <StyledDeleteButton size={ButtonSizes.sm} fab noShadow>
                <Icon type={'Trashcan'} />
              </StyledDeleteButton>
            }
          />
        </StyledPreviewWrapper>
      </StyledPreviewContainer>

      <StyledButtonWrapper mt={'2rem'}>
        <Button onClick={onUpload} isLoading={isLoading} type={'button'} color={'primary'}>
          {t('upload')}
        </Button>
      </StyledButtonWrapper>
    </>
  );
};

export default VoicePreview;
