import React from 'react';
import { Spinner } from '../../../../../../shared/components/ui';
import { StyledOptionCardButton } from '../styled';
import { useScopedTranslation } from '../../../../../../shared/hooks';

const PaymentIsProcessing = () => {
  const { t } = useScopedTranslation('order');

  return (
    <StyledOptionCardButton isDisabled>
      <Spinner size={'sm'} />
      <span>{t('payment_is_processing')}</span>
    </StyledOptionCardButton>
  );
};

export default PaymentIsProcessing;
