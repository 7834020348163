import { Order } from '../../../shared/types/order';
import { OrderStatuses } from '../../../shared/constants/OrderStatuses';
import { OrderApi } from '../../../api';
import { useEffect, useState } from 'react';

const useCheckStatus = (order: Order | null, status: OrderStatuses) => {
  const [isDesiredStatus, setIsDesiredStatus] = useState(false);

  const checkStatus = async () => {
    if (!order) return;

    await OrderApi.checkStatus(order.uuid, status)
      .then((response) => {
        setIsDesiredStatus(response);
        if (!response) {
          checkStatus();
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (order?.status !== status) checkStatus();
  }, [order, status]);

  return isDesiredStatus;
};

export default useCheckStatus;
