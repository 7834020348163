import React, { useEffect } from 'react';
import { StyledInputWithAction, StyledPromocodeError, StyledPromocodeWrapper } from './styled';
import { Button, Input } from '../../ui';
import { useHandlePromocode, useScopedTranslation } from '../../../hooks';

export type DiscountObject = { discount: number; promocode: string };

const CheckoutPromocodeInput = ({
  onDiscountUpdate,
}: {
  onDiscountUpdate: ({ discount, promocode }: DiscountObject) => void;
}) => {
  const { t } = useScopedTranslation('order.checkout_page');

  const { checkPromocode, promocodeChanged, discount, promocode, isChecking, promocodeError } =
    useHandlePromocode();

  const onEnterPromocode = (e: { preventDefault: () => void }) => {
    checkPromocode();
    e.preventDefault();
  };

  useEffect(() => {
    onDiscountUpdate({
      discount,
      promocode,
    });
  }, [discount, promocode]);

  return (
    <StyledPromocodeWrapper>
      <StyledInputWithAction onSubmit={onEnterPromocode}>
        <Input
          name={'promocode'}
          isLoading={isChecking}
          onChange={promocodeChanged}
          placeholder={t('promocode')}
        />
        <Button
          type={'submit'}
          color={'primaryLight'}
          noShadow
          isLoading={isChecking}
          isDisabled={!promocode}
        >
          Apply
        </Button>
      </StyledInputWithAction>
      {promocodeError && <StyledPromocodeError>{t('promocode_not_found')}</StyledPromocodeError>}
    </StyledPromocodeWrapper>
  );
};

export default CheckoutPromocodeInput;
