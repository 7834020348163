import { Box, Grid, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const StyledSongSelectorCard = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background: ${({ theme }) => theme.palette.white};
    padding: 2rem;
    border-radius: ${({ theme }) => theme.radii.sm};
  }
`;

export const StyledHeader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xl3};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 1.5rem;
  line-height: 2rem;
  max-width: 18rem;
`;

export const StyledVersionsWrapper = styled(Grid)`
  grid-gap: 0.5rem;
`;

export const StyledActionButton = styled.div`
  margin-top: 2.375rem;
`;

export const StyledVersionCard = styled(Box)<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  background: ${({ theme, isSelected }) =>
    (isSelected && theme.palette.primary) || theme.palette.neutral5};
  border-radius: ${({ theme }) => theme.radii.sm};
  cursor: pointer;
  transition: 0.15s ease-in-out;
  min-width: 20rem;

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    background: ${({ theme, isSelected }) =>
      (isSelected && theme.palette.primaryHover) || theme.palette.neutral10};
  }

  > div:first-of-type {
    button {
      background: ${({ theme, isSelected }) =>
        (isSelected && theme.palette.white) || theme.palette.primary};

      svg,
      path,
      rect {
        fill: ${({ theme, isSelected }) =>
          (isSelected && theme.palette.primary) || theme.palette.white};
      }
    }

    > p {
      font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
      font-size: ${({ theme }) => theme.fontSize.sm};
      color: ${({ theme, isSelected }) =>
        (isSelected && theme.palette.white) || theme.colors.text.darker};
    }
  }

  > button {
    padding: 0;
    width: 2rem;
    height: 2rem;
    background: ${({ theme, isSelected }) =>
      (isSelected && theme.palette.white) || theme.palette.neutral10};
    z-index: 33;

    &:hover {
      background: ${({ theme, isSelected }) =>
        (isSelected && theme.palette.neutral10) || theme.palette.neutral20};
    }

    svg,
    path {
      stroke: ${({ theme, isSelected }) =>
        (isSelected && theme.palette.primary) || theme.palette.neutral60};
    }
  }
`;
