import React from 'react';
import { designSystem } from '../theme';
import { PricesResponse } from '../types/pricesResponse';
import { OrderOptions } from '../constants/OrderOptions';

export type IAppContext = {
  background: string;
  setBackground: React.Dispatch<React.SetStateAction<string>>;
  backButton: boolean;
  setBackButton: React.Dispatch<React.SetStateAction<boolean>>;
  prices: PricesResponse | null;
  fluid: boolean;
  setFluid: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAdditionalOptions: OrderOptions[];
  setSelectedAdditionalOptions: React.Dispatch<React.SetStateAction<OrderOptions[]>>;
  cartButton: boolean;
  setCartButton: React.Dispatch<React.SetStateAction<boolean>>;
};

export const defaultState = {
  background: designSystem.palette.neutral5,
  setBackground: () => {},
  backButton: false,
  setBackButton: () => {},
  prices: null,
  fluid: false,
  setFluid: () => {},
  selectedAdditionalOptions: [],
  setSelectedAdditionalOptions: () => {},
  cartButton: false,
  setCartButton: () => {},
};

export const AppContext = React.createContext<IAppContext>(defaultState);

export const AppContextProvider = AppContext.Provider;
