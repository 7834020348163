import styled from '@emotion/styled';
import { Divider, Flex, Text } from '@chakra-ui/react';
import { Grid } from '@chakra-ui/react';

export const StyledOptionsHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl4};
  color: ${({ theme }) => theme.colors.text.darker};
  line-height: 3rem;
  text-align: center;
  margin-bottom: 0.75rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    line-height: 2rem;
    max-width: 16rem;
    margin-inline: auto;
  }
`;

export const StyledOptionsSubheader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xl};
  color: ${({ theme }) => theme.colors.text.main};
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.75rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    margin-inline: auto;
    line-height: 1.25rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 14rem;
  }
`;

export const StyledOptionsWrapper = styled(Grid)`
  margin-top: 2rem;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.875rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;

export const StyledCheckoutButtonWrapper = styled(Flex)`
  margin-top: 2.5rem;
  justify-content: center;

  a {
    width: 11.75rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 1rem;

    a {
      width: 100%;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-color: ${({ theme }) => theme.palette.neutral10};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const StyledHintWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  grid-gap: 0.75rem;

  > span {
    font-size: ${({ theme }) => theme.fontSize.xl};
    color: ${({ theme }) => theme.colors.text.main};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
    margin-inline: auto;

    > span {
      font-size: ${({ theme }) => theme.fontSize.sm};
      max-width: 15rem;
    }
  }
`;

export const StyledHint2Text = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xl};
  color: ${({ theme }) => theme.colors.text.placeholder};
  text-align: center;
  line-height: 2rem;
  max-width: 40rem;
  margin-inline: auto;
  margin-bottom: 2.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.xs};
    text-align: start;
    line-height: 1.25rem;
  }
`;

export const StyledHintDivider = styled(Divider)`
  margin-top: 1rem;
  margin-bottom: 0;
  border-color: ${({ theme }) => theme.palette.neutral10};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 1.5rem;
  }
`;
