import React, { useMemo } from 'react';
import { Icon } from '../../../../shared/components/icons';
import { Card, Select } from '../../../../shared/components/ui';
import { useScopedTranslation } from '../../../../shared/hooks';
import { FieldErrorsImpl } from 'react-hook-form';
import useFunnelContext from '../../hooks/useFunnelContext';

type IOccasion = {
  register: Function;
  errors: FieldErrorsImpl;
};

const Occasion = ({ register, errors }: IOccasion) => {
  const { t } = useScopedTranslation('funnel.general_information');

  const {
    values: { occasions },
  } = useFunnelContext();

  const options = useMemo(() => {
    return occasions.map((occasion) => ({ value: occasion.id, label: occasion.name }));
  }, [occasions]);

  return (
    <Card title={t('occasion')} icon={<Icon type={'Balloon'} color={'primary'} />}>
      <Select
        {...{
          register,
          errors,
          isLoading: true,
          options,
          isDisabled: !occasions.length,
          placeholder: t((!occasions.length && 'select_recipient_first') || 'select_occasion'),
          name: 'occasion',
          translationScope: 'funnel.general_information.occasions',
        }}
      />
    </Card>
  );
};

export default Occasion;
