import { useContext, useEffect } from 'react';
import { AppContext } from '../context';
import { Events } from '../constants/Events';

export const useHandleHeaderBackClick = (
  isButtonShown: boolean = true,
  callback: Function = () => {},
  deps: Array<unknown> = [],
) => {
  const { backButton, setBackButton } = useContext(AppContext);

  useEffect(() => {
    setBackButton(isButtonShown);
  }, []);

  const buttonClicked: EventListenerOrEventListenerObject = () => {
    callback();
  };

  useEffect(() => {
    window.addEventListener(Events.backClick, buttonClicked);

    return () => {
      window.removeEventListener(Events.backClick, buttonClicked);
    };
  }, [...deps, backButton]);

  return { setBackButton };
};
