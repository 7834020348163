import React, { useMemo } from 'react';
import { StyledSelect } from './styled';
import { SelectProps } from '@chakra-ui/react';
import { FieldErrorsImpl } from 'react-hook-form';
import { FormErrorMessage } from '../FormErrorMessage';
import { useScopedTranslation } from '../../../hooks';
import { Icon } from '../../icons';

type ISelect = {
  name: string;
  register?: Function;
  errors?: FieldErrorsImpl;
  placeholder?: string;
  options: Array<{ label: string; value: string | number }>;
  translationScope?: string;
};

const Select = ({
  name,
  placeholder,
  options,
  register = () => {},
  errors = {},
  translationScope = '',
  ...props
}: ISelect & SelectProps) => {
  const { t } = useScopedTranslation(translationScope);
  const errorMessage = useMemo(() => errors[name]?.message as string, [errors]);

  return (
    <div>
      <StyledSelect {...register(name)} {...{ ...props, icon: <Icon type={'SelectToggler'} /> }}>
        {placeholder && <option value="">{placeholder}</option>}
        {options.map(({ label, value }) => (
          <option value={value} key={`option-${value}`}>
            {t(label)}
          </option>
        ))}
      </StyledSelect>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </div>
  );
};

export default Select;
