import { useEffect } from 'react';

export const useSmoothScroll = () => {
  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
        const href = anchor.getAttribute('href');
        if (href) {
          e.preventDefault();
          document.querySelector(href)?.scrollIntoView({
            behavior: 'smooth',
          });
        }
      });
    });

    return () => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.removeEventListener('click', () => {});
      });
    };
  }, []);
};
