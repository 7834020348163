import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';

export const StyledPromocodeSuccess = styled(Text)`
  color: ${({ theme }) => theme.palette.success};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
`;

export const StyledPriceLabel = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  color: ${({ theme }) => theme.colors.text.placeholder};
  line-height: 1.25rem;
`;

export const StyledPrice = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 2rem;
`;

export const StyledPriceDiscount = styled(StyledPrice)`
  > span {
    color: ${({ theme }) => theme.colors.text.placeholder};
    text-decoration: line-through;
  }
  color: ${({ theme }) => theme.colors.text.main};
`;
