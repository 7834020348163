/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgSlides = (props) => (
  <svg
    {...props}
    width="126"
    height="54"
    viewBox="0 0 126 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M125.996 39.5088C125.244 40.9372 124.159 42.04 122.085 42.1359C119.644 42.2495 117.731 40.8312 117.626 38.8274C117.592 38.1914 117.611 37.5554 117.611 36.9169C117.611 29.6108 117.601 22.3047 117.62 15.0011C117.623 13.2118 119.003 11.8314 120.979 11.526C123.038 11.2055 124.88 12.0812 125.753 13.7847C125.814 13.9033 125.91 14.0068 125.993 14.1179C125.996 22.5823 125.996 31.0468 125.996 39.5088Z"
      {...props}
      stroke="white"
      strokeWidth="2"
      mask="url(#path-1-inside-1_1640_8593)"
    />
    <path
      d="M63.0019 53.6279C52.9984 53.6279 42.9918 53.6304 32.9883 53.6279C29.5736 53.6279 28.0297 52.3686 28.0297 49.5774C28.0266 34.3999 28.0266 19.2249 28.0297 4.04738C28.0297 1.26122 29.5767 0.00189277 32.9944 0.00189277C53.0045 -0.000630924 73.0115 -0.000630924 93.0216 0.00189277C96.4363 0.00189277 97.9802 1.26122 97.9802 4.05243C97.9833 19.2299 97.9833 34.4049 97.9802 49.5824C97.9802 52.3686 96.4332 53.6279 93.0155 53.6279C83.012 53.6304 73.0085 53.6279 63.0019 53.6279ZM63.0666 6.86635C54.5793 6.86635 46.089 6.88149 37.6017 6.84616C36.6155 6.84111 36.3936 7.07329 36.3967 7.85059C36.4245 20.5094 36.4245 33.1708 36.3967 45.8297C36.3936 46.5691 36.6001 46.7837 37.537 46.7837C54.5146 46.7584 71.4891 46.7584 88.4667 46.7837C89.4036 46.7862 89.6132 46.5742 89.6101 45.8322C89.5824 33.1734 89.5824 20.512 89.6101 7.85311C89.6132 7.08086 89.3944 6.84363 88.4082 6.84868C79.961 6.87897 71.5138 6.86635 63.0666 6.86635Z"
      {...props}
      stroke="white"
      strokeWidth="2"
      mask="url(#path-2-inside-2_1640_8593)"
    />
    <path
      d="M14.0196 26.7664C14.0196 20.9569 14.0011 15.1499 14.0289 9.34032C14.0412 6.92767 16.4234 5.32764 19.1508 5.82986C20.8736 6.14785 22.1741 7.33398 22.3744 8.78511C22.4114 9.0501 22.4052 9.32266 22.4052 9.59017C22.4052 21.073 22.4083 32.5558 22.4052 44.0386C22.4052 45.9289 21.3605 47.2462 19.5145 47.7308C16.8087 48.4425 14.1028 46.8753 14.0412 44.4929C13.9796 42.1105 14.0227 39.7256 14.0196 37.3407C14.0166 33.8177 14.0196 30.292 14.0196 26.7664Z"
      {...props}
      stroke="white"
      strokeWidth="2"
      mask="url(#path-3-inside-3_1640_8593)"
    />
    <path
      d="M111.978 26.9157C111.978 32.7252 111.978 38.5322 111.975 44.3418C111.975 45.7349 111.257 46.815 109.756 47.4813C108.345 48.1046 106.903 48.0567 105.55 47.3551C104.24 46.6762 103.599 45.6591 103.593 44.3923C103.584 42.2093 103.587 40.0263 103.587 37.8458C103.587 28.4122 103.584 18.9761 103.59 9.54253C103.59 7.59928 104.786 6.23396 106.783 5.84279C109.464 5.31786 111.926 6.86993 111.957 9.19173C112.006 12.8511 111.972 16.5105 111.975 20.1698C111.978 22.4159 111.978 24.6645 111.978 26.9157Z"
      {...props}
      stroke="white"
      strokeWidth="2"
      mask="url(#path-4-inside-4_1640_8593)"
    />
    <path
      d="M8.38597 26.8203C8.38597 30.7775 8.41987 34.7371 8.37364 38.6943C8.34282 41.218 5.40895 42.8508 2.64458 41.9095C0.977327 41.3416 0.0312157 40.264 0.0250521 38.7877C-0.00576584 30.8052 -0.0119294 22.8228 0.0281339 14.8403C0.0373793 12.9046 1.90803 11.4838 4.20705 11.4863C6.56463 11.4889 8.35823 12.9349 8.37364 14.9488C8.41062 18.9035 8.38597 22.8606 8.38597 26.8203Z"
      {...props}
      stroke="white"
      strokeWidth="2"
      mask="url(#path-5-inside-5_1640_8593)"
    />
  </svg>
);

export default SvgSlides;
