import React from 'react';
import { Artist } from '../../../shared/types/artist';
import { ChildrenVariants } from '../components/PersonalFacts/constants';
import { Personalization } from '../../../shared/types/personalization';
import { Segment } from '../../../shared/types/segment';

export type IFunnelParameters = {
  recipient: number | null;
  occasion: number | null;
  date: Date | null;
  eyesColor?: string;
  eyesColorDisabled: boolean;
  together?: number;
  togetherDisabled: boolean;
  children?: ChildrenVariants;
  childType?: string;
  childrenDisabled: boolean;
  month?: string;
  monthDisabled: boolean;
  genre?: string;
  artistId?: number | null;
  receiver: string;
  gifter: string;
  email: string;
};

export type IFunnelValues = {
  recipients: Segment[];
  occasions: Personalization[];
  eyeColors: Personalization[];
  children: Personalization[];
  artists: Artist[];
};

export type IFunnelContext = {
  funnelTotalSteps: number;
  funnelStep: number;
  setFunnelStep: React.Dispatch<React.SetStateAction<number>>;
  parameters: IFunnelParameters;
  setParameters: React.Dispatch<React.SetStateAction<IFunnelParameters>>;
  createOrder: Function;
  artistSelect: boolean;
  artistFound: Artist | null;
  setArtistSelect: React.Dispatch<React.SetStateAction<boolean>>;
  setArtistFound: React.Dispatch<React.SetStateAction<Artist | null>>;
  setIsLookingForArtist: React.Dispatch<React.SetStateAction<boolean>>;
  setValues: React.Dispatch<React.SetStateAction<IFunnelValues>>;
  values: IFunnelValues;
  isCreatingOrder: boolean;
  isLookingForArtist: boolean;
};

export const defaultState = {
  funnelTotalSteps: 4,
  funnelStep: 0,
  setFunnelStep: () => {},
  parameters: {
    recipient: null,
    occasion: null,
    date: null,
    eyesColorDisabled: false,
    togetherDisabled: false,
    childrenDisabled: false,
    monthDisabled: false,
    month: 'January',
    children: ChildrenVariants.noChildren,
    artistId: null,
    receiver: '',
    gifter: '',
    email: '',
  },
  values: {
    recipients: [],
    occasions: [],
    eyeColors: [],
    artists: [],
    children: [],
  },
  setValues: () => {},
  setParameters: () => {},
  createOrder: () => {},
  isCreatingOrder: false,
  isLookingForArtist: false,
  setIsLookingForArtist: () => {},
  artistSelect: false,
  setArtistSelect: () => {},
  artistFound: null,
  setArtistFound: () => {},
};

export const FunnelContext = React.createContext<IFunnelContext>(defaultState);

export const FunnelContextProvider = FunnelContext.Provider;
