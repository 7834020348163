import React from 'react';
import { useOrderContext } from '../../../../modules/order/hooks';
import { SongVersions } from '../../../constants/SongVersions';
import {
  StyledActionButton,
  StyledHeader,
  StyledSongSelectorCard,
  StyledVersionsWrapper,
} from './styled';
import VersionCard from './VersionCard';
import { useGetSongVersions } from '../../../hooks';

const SongVersionSelect = ({
  title,
  selectedVersion,
  onSelect,
  actionButton = null,
}: {
  title?: string;
  selectedVersion: SongVersions;
  onSelect: Function;
  actionButton?: React.ReactNode | null;
}) => {
  const { order } = useOrderContext();
  const tracks = useGetSongVersions(order);

  return (
    <StyledSongSelectorCard>
      {title && <StyledHeader>{title}</StyledHeader>}
      <StyledVersionsWrapper>
        {tracks.map(
          (track) =>
            track.url && (
              <VersionCard
                key={track.name}
                trackUrl={track.url}
                isSelected={selectedVersion === track.name}
                onSelect={onSelect}
                name={track.name}
              />
            ),
        )}
      </StyledVersionsWrapper>
      {actionButton && <StyledActionButton>{actionButton}</StyledActionButton>}
    </StyledSongSelectorCard>
  );
};

export default SongVersionSelect;
