import styled from '@emotion/styled';
import { Grid, Text } from '@chakra-ui/react';
import { Card, Carousel } from '../../ui';

export const StyledReviewsWrapper = styled(Grid)`
  grid-gap: 1.875rem;
  grid-template-columns: repeat(4, 1fr);
  padding-top: 1.5rem;

  > div:nth-of-type(2),
  > div:nth-of-type(4) {
    video {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      height: 11rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
  }

  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
  }
`;

export const StyledReviewsCarousel = styled(Carousel)`
  margin-top: 1rem;
  margin-inline: -1.25rem;
  margin-bottom: 3rem;

  .slick-slide > div > div {
    min-height: 35rem;
    text-align: start;
    margin-inline: 1rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const StyledReviewCard = styled(Card)`
  height: 100%;
  padding: 0;
  grid-gap: 0;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0;
  }
`;

export const StyledReviewContentContainer = styled.div`
  padding: 1.75rem 1.25rem 1.25rem;
`;

export const StyledSmallReviewText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.darker};
  font-style: italic;
`;

export const StyledLargeReviewText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xl2};
  color: ${({ theme }) => theme.colors.text.darker};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  line-height: 1.75rem;
`;

export const StyledReviewAuthor = styled(Text)`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.text.secondary};
`;
