import React from 'react';
import { OrderOptions } from '../../../../constants/OrderOptions';
import { Icon } from '../../../icons';
import { useToggleOrderOption } from '../../../../hooks';
import { StyledOptionActionButton } from '../../../../../modules/order/views/Order/OrderReady/styled';
import { LocalStorageKeys } from '../../../../constants/LocalStorageKeys';

const OrderOptionAction = ({
  optionName,
  storageKey = LocalStorageKeys.orderOptions,
  onToggle = () => {},
}: {
  optionName: OrderOptions;
  storageKey: LocalStorageKeys;
  onToggle: Function;
}) => {
  const { toggleOption, isSelected } = useToggleOrderOption(optionName, storageKey);

  const toggle = () => {
    toggleOption();
    onToggle();
  };

  return (
    <StyledOptionActionButton
      noShadow
      onClick={toggle}
      type={'button'}
      icon={(isSelected && <Icon type={'Check'} color={'primary'} />) || null}
      color={(isSelected && 'primaryLight') || 'secondary'}
    >
      {(isSelected && 'Added') || 'Add to order'}
    </StyledOptionActionButton>
  );
};

export default OrderOptionAction;
