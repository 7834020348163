/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgGift = (props) => (
  <svg
    {...props}
    width="33"
    height="35"
    viewBox="0 0 33 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4088 19.4687C17.6246 18.8885 17.0544 18.0649 16.7721 17.1407C17.8421 17.8945 18.7421 18.8895 19.398 20.0539C19.0607 19.9014 18.738 19.7044 18.4088 19.4687Z"
      fill="#6097FB"
      stroke="white"
      strokeWidth="0.7"
    />
    <path
      d="M9.53559 15.5687C10.0272 15.4476 10.4872 15.3906 10.9724 15.3726C10.9594 15.8712 10.9996 16.3446 11.0405 16.8257C11.0428 16.8531 11.0451 16.8805 11.0475 16.9079L11.0474 16.9079L11.048 16.9137C11.087 17.2984 11.1535 17.632 11.2256 17.9558C10.4066 17.9684 9.59078 18.1408 8.78022 18.4696L8.78022 18.4696L8.77696 18.471C5.41986 19.8724 3.75777 23.745 5.18669 27.071C6.55841 30.3896 10.4903 32.0142 13.8532 30.6104C16.1472 29.6639 17.5962 27.6776 17.9091 25.4403C18.7308 25.7078 19.6118 25.905 20.5037 25.9822C20.0267 28.9587 17.9887 31.6609 14.9346 32.9439C10.1951 34.893 4.78331 32.6852 2.80697 28.0223L2.80609 28.0203C1.0406 23.9277 2.59498 19.3145 6.13829 16.9016L6.37672 16.7393L6.2629 16.4742L5.0747 13.7073L4.93986 13.3933L4.62273 13.5206L3.16448 14.1059L3.16444 14.1058L3.15632 14.1093C2.47157 14.4045 1.75604 14.1166 1.45437 13.4373C1.15955 12.7734 1.49649 12.025 2.12696 11.7766L2.12696 11.7766L2.12901 11.7758L3.58725 11.1905L3.91164 11.0603L3.78186 10.7357L3.08032 8.98121C3.08022 8.98096 3.08013 8.98072 3.08003 8.98048C2.77016 8.19436 3.12554 7.34117 3.90616 7.04209L3.90618 7.04215L3.91251 7.03959C4.66376 6.73482 5.58437 7.09115 5.88579 7.85476L5.88563 7.85482L5.88988 7.86468L6.64601 9.62061L6.78282 9.93833L7.10213 9.80524L8.50636 9.21993L8.50645 9.22014L8.51791 9.21487C9.14391 8.92706 9.91019 9.21021 10.2122 9.89028C10.507 10.5542 10.1701 11.3026 9.5396 11.551L9.53957 11.5509L9.53327 11.5536L8.12903 12.1389L7.8025 12.275L7.94209 12.6001L9.13029 15.367L9.2474 15.6397L9.53559 15.5687Z"
      fill="#6097FB"
      stroke="white"
      strokeWidth="0.7"
    />
    <path
      d="M31.3757 1.55156L31.3875 1.56092L31.4002 1.56922C31.6214 1.7145 31.7423 1.93266 31.7857 2.22943C31.7858 2.23011 31.7859 2.23079 31.786 2.23148L32.648 8.76021C32.6851 9.38113 32.2709 9.88096 31.6774 9.91994L31.6644 9.9208L31.6516 9.92261C31.0879 10.0019 30.5634 9.60474 30.481 9.05233L30.1576 6.34335L30.1491 6.34436C30.1393 6.28831 30.1213 6.22246 30.0954 6.17406C29.9978 6.08336 29.796 6.03075 29.7194 6.04299C29.6852 6.05437 29.6355 6.07844 29.6192 6.08859C29.6096 6.09521 29.5941 6.1069 29.588 6.11185C29.5693 6.12747 29.5557 6.14235 29.5548 6.14334C29.5429 6.15608 29.5296 6.17225 29.5223 6.18102C29.5026 6.20505 29.4716 6.24352 29.4322 6.29293C29.3528 6.39262 29.2334 6.54358 29.087 6.72944C28.8106 7.08024 28.4358 7.55752 28.0458 8.0542L27.9752 8.14413C27.1406 9.20706 26.2698 10.3157 26.1402 10.4688L25.9434 10.7016L26.1469 10.9285C28.8959 13.9946 29.1058 18.7003 26.4562 22.0786C23.4444 25.9156 17.8292 26.5853 13.9274 23.6126C10.0335 20.6458 9.35731 15.122 12.3704 11.2835L12.3705 11.2833C15.0181 7.90763 19.6973 6.98144 23.4443 8.82723L23.7095 8.95785L23.8826 8.71829L26.7837 4.70491L27.1899 4.14293L26.4965 4.1499L24.2436 4.17252L24.2208 4.17274L24.1983 4.17591C23.6333 4.25544 23.1076 3.85613 23.0272 3.30165C22.9486 2.75926 23.3423 2.23821 23.9144 2.15585L30.6076 1.35817L30.6076 1.35825L30.6149 1.35721C30.9007 1.31699 31.1824 1.39925 31.3757 1.55156ZM15.5012 21.7214C18.2988 23.8589 22.3508 23.3541 24.5221 20.5956C26.6961 17.8338 26.1799 13.8335 23.3794 11.6938C20.5818 9.55631 16.5298 10.0612 14.3585 12.8196C12.1845 15.5815 12.7007 19.5817 15.5012 21.7214Z"
      fill="#6097FB"
      stroke="white"
      strokeWidth="0.7"
    />
  </svg>
);

export default SvgGift;
