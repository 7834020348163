import { Avatar, Box, Divider, Flex, Grid, Tab, TabList, Tabs, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { AudioPlayer, Button, Card } from '../../../../../shared/components/ui';
import { Countdown } from '../../../../../shared/components/custom';

export const StyledWrapper = styled(Grid)`
  grid-template-columns: 40% 1fr;
  grid-gap: 1.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    margin-top: 1rem;
  }
`;

export const StyledHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl4};
  color: ${({ theme }) => theme.colors.text.darker};
  margin-bottom: 1rem;
  line-height: 3rem;
  max-width: 80%;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    line-height: 2.25rem;
    margin-bottom: 0.75rem;
    color: ${({ theme }) => theme.colors.text.main};
    max-width: 70%;
    display: none;
  }
`;

export const StyledMobileHeader = styled(StyledHeader)`
  display: block !important;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none !important;
  }
`;

export const StyledOrderId = styled.span`
  color: ${({ theme }) => theme.palette.primary};
`;

export const StyledDemoPreview = styled(Box)`
  background-color: ${({ theme }) => theme.palette.neutral5};
  padding: 1rem;
  border-radius: ${({ theme }) => theme.radii.sm};
  margin-top: 1rem;
`;

export const StyledDemoDescription = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-style: italic;
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 1.5rem;
  margin-top: 1.25rem;
  white-space: pre-line;
`;

export const StyledHintWrapper = styled(Grid)`
  margin-top: 1.5rem;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.xs};
    line-height: 1.25rem;
  }
`;

export const StyledDemo = styled.div`
  order: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    order: 1;
  }
`;

export const StyledOrderImageWrapper = styled.div`
  order: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    order: 0;
  }
`;

export const StyledEmotionsHint = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.md};
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    text-align: start;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    text-align: start;
    white-space: pre-line;
  }
`;

export const StyledOrderImage = styled.img`
  margin-inline: auto;
  margin-top: 1.5rem;
  max-width: 34.5rem;
  width: 100%;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-color: ${({ theme }) => theme.palette.neutral10};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const StyledDesktopDivider = styled(StyledDivider)`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    opacity: 0;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const StyledOfferWrapper = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-gap: 3.25rem;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledArtistCard = styled(Card)`
  height: 100%;
  padding: 1rem;
  grid-template-rows: auto 1fr;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 1rem;
  }
`;

export const StyledDesktopArtistCard = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledMobileArtistCard = styled.div`
  > div {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  margin-bottom: 0.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 3.75rem;
  height: 3.75rem;
`;

export const StyledArtistHint = styled(Text)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
`;

export const StyledArtistName = styled(Text)`
  color: ${({ theme }) => theme.colors.text.darker};
  font-size: ${({ theme }) => theme.fontSize.xl2};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
`;

export const StyledArtistText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-style: italic;
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 1.5rem;
  max-width: 90%;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: 100%;
  }
`;

export const StyledOfferCard = styled(Card)`
  background-color: ${({ theme }) => theme.palette.primary8};
  padding: 1.5rem;
  position: relative;
  overflow: unset;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 1.5rem;
  }
`;

export const StyledGiftRobot = styled.img`
  position: absolute;
  right: 0.7rem;
  top: -2rem;
  width: 14.6rem;
  height: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    position: unset;
    right: 0;
    top: 0;
    margin-inline: auto;
    margin-top: 1rem;
  }
`;

export const StyledOfferTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.text.darker};
  font-size: ${({ theme }) => theme.fontSize.xl3};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  line-height: 2.25rem;
`;

export const StyledOfferSubtitle = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin-top: 0.3rem;
`;

export const StyledOfferPrice = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xl2};
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
`;

export const StyledOfferPriceCrossed = styled.div`
  position: relative;

  span {
    color: ${({ theme }) => theme.colors.text.placeholder};
    font-size: 17px;
    line-height: 1;
  }

  img {
    position: absolute;
    top: 0.6rem;
    z-index: 2;
    max-width: unset;
    width: 2.5rem;
  }
`;

export const StyledOfferButtonWrapper = styled(Grid)`
  grid-template-columns: 11.5rem 1fr;
  grid-gap: 1rem;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledOfferCardDivider = styled(StyledDivider)`
  display: none;
  border-color: ${({ theme }) => theme.palette.primary16};
  margin: 0 !important;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: block;
  }
`;

export const StyledCountdown = styled(Countdown)`
  font-size: ${({ theme }) => theme.fontSize.xl2};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    text-align: center;
  }
`;

export const StyledOrderExpirationWrapper = styled(StyledHintWrapper)`
  margin-top: 0.5rem;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 1rem;
  }
`;

export const StyledScreenHeader = styled(Text)<{ textAlign?: 'start' | 'center' | 'end' }>`
  font-size: ${({ theme }) => theme.fontSize.xl5};
  color: ${({ theme }) => theme.colors.text.darker};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  margin-bottom: 1rem;
  text-align: ${({ textAlign }) => textAlign ?? 'start'};
  line-height: 3.75rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    margin-bottom: 0.75rem;
    line-height: 2.25rem;
  }
`;

export const StyledScreenSubheader = styled(Text)<{ textAlign?: 'start' | 'center' | 'end' }>`
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 1rem;
  text-align: ${({ textAlign }) => textAlign ?? 'start'};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    margin-bottom: 0.75rem;
  }
`;

export const StyledOptionsWrapper = styled(Grid)`
  grid-gap: 0.75rem;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 1rem;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
    display: none;
  }
`;

export const StyledAudioPlayer = styled(AudioPlayer)`
  margin-bottom: 0.75rem;

  > p:last-of-type {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;

export const StyledCheckoutButtonWrapper = styled(Flex)`
  margin-top: 2.5rem;
  justify-content: center;

  a {
    width: 11.75rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 1.5rem;

    a {
      width: 100%;
    }
  }
`;

export const StyledOptionsTabs = styled(Tabs)`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: block;
  }
`;

export const StyledTabList = styled(TabList)`
  border-bottom: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;

export const StyledTab = styled(Tab)`
  border-bottom: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    background: unset;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.palette.neutral5};
    border-radius: 100%;
    width: 4.5rem;
    height: 4.5rem;
    padding: 0;
    box-shadow: none;

    > svg {
      width: 2.5rem;
      height: auto;
    }
  }

  svg,
  path {
    fill: ${({ theme }) => theme.palette.neutral70};
  }

  > span {
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.colors.text.placeholder};
    margin-top: 0.875rem;
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }

  &[aria-selected='true'] {
    > div {
      background: ${({ theme }) => theme.palette.white};
      border: 2px solid ${({ theme }) => theme.palette.primary};
    }

    svg,
    path {
      fill: ${({ theme }) => theme.palette.primary};
    }

    > span {
      color: ${({ theme }) => theme.colors.text.main};
    }
  }
`;

export const StyledProjectFounderWrapper = styled(Grid)`
  grid-gap: 1.875rem;
  grid-template-columns: 1fr 1fr;
  padding-top: 1.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    padding-top: 0.5rem;
    padding-bottom: 3rem;
  }
`;

export const StyledProjectFounderCard = styled(Card)`
  padding: 0 0 0.5rem;
  grid-gap: 0.75rem;

  img {
    width: 100%;
    height: 12.5rem;
    background: ${({ theme }) => theme.palette.neutral10};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0 0 0.5rem;
  }
`;

export const StyledFounderName = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xl2};
  color: ${({ theme }) => theme.colors.text.darker};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  line-height: 2rem;
  padding-inline: 1rem;
`;

export const StyledFounderText = styled.div`
  padding-inline: 1rem;

  > p {
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.colors.text.main};
    margin-bottom: 1.25rem;
  }
`;

export const StyledProjectFounderQuote = styled(Flex)`
  border-radius: ${({ theme }) => theme.radii.xl};
  border: 1px solid #e5e2eb;
  align-items: center;
  justify-content: center;

  span {
    font-size: ${({ theme }) => theme.fontSize.xl5};
    color: ${({ theme }) => theme.colors.text.main};
    font-family: ${({ theme }) => theme.fontFamily.headingBold};
    line-height: 3.75rem;
    text-align: center;
    max-width: 27rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    border: none;

    span {
      font-size: ${({ theme }) => theme.fontSize.xl3};
      line-height: 2.5rem;
      max-width: 15rem;
    }
  }
`;

export const StyledOptionActionButton = styled(Button)`
  margin-top: 1rem;
`;

export const StyledFAQWrapper = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
`;
