import { format, parseISO } from 'date-fns';
import { designSystem } from '../theme';
import { showToast } from '../components/ui';
import i18n from '../i18n/i18n';

export const toPrettyDate = (date: string | null | undefined) => {
  if (!date) return date;
  try {
    return format(parseISO(date), 'MMMM dd, yyyy');
  } catch (e) {
    return date;
  }
};

export const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const range = (min: number, max: number, step: number = 1) =>
  Array.from({ length: max - min + step }, (_, i: number) => min + i);

export const currentTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const copyToClipBoard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const shareUrl = (url: string, title: string) => {
  if (navigator.share) {
    navigator
      .share({ title, url })
      .then(() => {})
      .catch(console.error);
  } else {
    copyToClipBoard(url);
    showToast('success', i18n.t('general.copied'));
  }
};

export const withLeadingZero = (n: number) => {
  return ((n < 10 && '0') || '') + n;
};

export const downloadFromUrl = (url: string) => {
  window?.open(url, '_blank')?.focus();
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  // @ts-ignore
  b.lastModifiedDate = new Date();
  // @ts-ignore
  b.name = fileName;

  //Cast to a File() type
  return theBlob as File;
};
