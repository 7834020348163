import React, { useMemo } from 'react';
import { Grid } from '@chakra-ui/react';
import {
  StyledArtistCard,
  StyledArtistHint,
  StyledArtistName,
  StyledArtistText,
  StyledAvatar,
} from '../styled';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import { useOrderContext } from '../../../../hooks';

const OrderArtistCard = () => {
  const { t } = useScopedTranslation('order');
  const { order } = useOrderContext();
  const artist = useMemo(() => order?.artist.data, [order]);

  return (
    <StyledArtistCard bordered>
      <Grid gap={'0.75rem'} templateColumns={'auto 1fr'} alignItems={'center'}>
        <StyledAvatar name={artist?.name} src={artist?.photo_url} />
        <div>
          <StyledArtistHint>Your artist</StyledArtistHint>
          <StyledArtistName>{artist?.name}</StyledArtistName>
        </div>
      </Grid>
      <StyledArtistText>{t('artist_quote')}</StyledArtistText>
    </StyledArtistCard>
  );
};

export default OrderArtistCard;
