import React from 'react';
import { Reviews } from '../../../../../../shared/components/custom';
import { StyledRepliesContainer, StyledRepliesHeader } from './styled';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import Replies1 from '../../../../../../shared/theme/assets/img/landing/replies_1.svg';
import Replies2 from '../../../../../../shared/theme/assets/img/landing/replies_2.svg';

const Replies = () => {
  const { t } = useScopedTranslation('landing.replies');

  return (
    <StyledRepliesContainer id={'why'}>
      <img src={Replies1} alt={''} />
      <img src={Replies2} alt={''} />
      <StyledRepliesHeader>
        {t('lifetime')} <span>{t('lifetime_2')}</span>
      </StyledRepliesHeader>
      <Reviews />
    </StyledRepliesContainer>
  );
};

export default Replies;
