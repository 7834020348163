import { useMemo } from 'react';
import useOrderContext from './useOrderContext';
import { addDays, parseISO } from 'date-fns';

const useGetOrderDeadline = () => {
  const { order } = useOrderContext();
  return useMemo(() => {
    return (order?.deadline_at && parseISO(order?.deadline_at)) || addDays(new Date(), 3);
  }, [order]);
};

export default useGetOrderDeadline;
