import { useMemo } from 'react';
import { SongVersions } from '../constants/SongVersions';
import { Order } from '../types/order';

export const useGetSongVersions = (order: Order | null) => {
  return useMemo<{ name: string; url?: string; lyrics?: string | null }[]>(
    () =>
      [
        {
          name: SongVersions.clientVoice,
          url: order?.result?.client_voice?.url,
          lyrics: order?.result?.client_voice?.lyrics,
        },
        {
          name: SongVersions.track,
          url: order?.result?.track?.url,
          lyrics: order?.result?.track?.lyrics,
        },
        {
          name: SongVersions.noIntro,
          url: order?.result?.no_intro?.url,
          lyrics: order?.result?.no_intro?.lyrics,
        },
      ].filter((version) => !!version.url),
    [order],
  );
};
