import styled from '@emotion/styled';
import { Grid, Text } from '@chakra-ui/react';

export const StyledPageWrapper = styled.div`
  min-height: calc(100vh - 14rem);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background: ${({ theme }) => theme.palette.neutral5};
    padding: 2rem;
  }
`;

export const StyledPaymentInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-gap: 1rem;

  img {
    max-width: 18rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background: ${({ theme }) => theme.palette.white};
    border-radius: ${({ theme }) => theme.radii.sm};
    padding: 2rem;
    max-width: 26rem;
    margin-inline: auto;
    max-height: 40rem;

    img {
      max-width: 21rem;
      margin-top: -3rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    img {
      margin-top: -5rem;
    }
  }
`;

export const StyledTextWrapper = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  img {
    margin-bottom: 2rem;
  }
`;

export const StyledHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl3};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  margin-inline: auto;
  white-space: pre-line;
`;

export const StyledSubHeader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};
  line-height: 1.25rem;
  text-align: center;
  margin-inline: auto;
  white-space: pre-line;
`;

export const StyledOrderNumber = styled(StyledSubHeader)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
`;

export const StyledSocialsHeader = styled(StyledSubHeader)`
  margin-top: 2rem;
  white-space: pre-line;
`;

export const StyledSocialsButtonsWrapper = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.75rem;

  a {
    width: 10rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 100%;

    a {
      width: 100%;
    }
  }
`;
