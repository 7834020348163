import styled from '@emotion/styled';
import { Grid, Text } from '@chakra-ui/react';
import { StyledContainer } from '../../styled';

export const StyledPostcardWrapper = styled(StyledContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  padding-bottom: 0;
  padding-top: 0.2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    align-items: flex-end;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledPhotoWrapper = styled.div`
  order: 0;
  position: relative;

  img {
    position: absolute;
    bottom: 0;
    right: 2rem;
    max-width: unset;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    img {
      position: unset;
      max-width: 25rem;
      width: 100%;
    }

    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, #fff 0%, #ffffff00 20%);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    order: 2;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    img {
      max-width: 100%;
    }
  }
`;

export const StyledTextWrapper = styled.div`
  order: 1;
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 3rem;
    margin-bottom: 0;
  }
`;

export const StyledArtistsHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.darker};
  font-size: ${({ theme }) => theme.fontSize.xl5};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  line-height: 3.75rem;
  margin-bottom: 1rem;
  white-space: pre-line;

  span {
    color: ${({ theme }) => theme.palette.primary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    line-height: 2.25rem;
    white-space: unset;
    margin-bottom: 1rem;
    max-width: 15rem;
  }
`;

export const StyledArtistsSubheader = styled(Text)`
  color: ${({ theme }) => theme.palette.neutral80};
  font-size: ${({ theme }) => theme.fontSize.xl};
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: 1.25rem;
    margin-bottom: 1.25rem;
    white-space: pre-line;
  }
`;

export const StyledArtistsWrapper = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.875rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;

export const StyledList = styled.div`
  display: grid;
  grid-gap: 0.75rem;

  > div {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    color: ${({ theme }) => theme.palette.neutral80};
    font-size: ${({ theme }) => theme.fontSize.md};

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: 1px solid ${({ theme }) => theme.palette.neutral50};
      width: 3rem;
      height: 3rem;
    }
  }
`;
