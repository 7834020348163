import { useState } from 'react';
import { FunnelApi } from '../../../api';
import { Personalization } from '../../../shared/types/personalization';

const useLoadEyeColors = () => {
  const [eyeColors, setEyeColors] = useState<Array<Personalization>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getEyeColors = async () => {
    setIsLoading(true);

    const resp = await FunnelApi.getEyeColors()
      .then((response) => {
        setEyeColors(response);
        return response;
      })
      .finally(() => setIsLoading(false));
    return resp;
  };

  return { eyeColors, getEyeColors, isLoading };
};

export default useLoadEyeColors;
