import styled from '@emotion/styled';
import { Flex, Text } from '@chakra-ui/react';

export const StyledHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl3};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 2rem;
  margin-bottom: 0.5rem;
  white-space: pre-line;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl4};
    color: ${({ theme }) => theme.colors.text.darker};
    line-height: 3rem;
  }
`;

export const StyledSubHeader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 1.25rem;
  white-space: pre-line;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.secondary};
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const StyledDropzoneContainer = styled.div`
  margin-top: 2rem;
  min-height: calc(100vh - 22.5rem);
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 1rem;
  }
`;

export const StyledSongSelectWrapper = styled.div`
  min-height: calc(100vh - 24.5rem);

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background: ${({ theme }) => theme.palette.neutral5};
    border-radius: ${({ theme }) => theme.radii.sm};
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledButtonWrapper = styled(Flex)`
  width: 100%;
  button,
  a {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    justify-content: center;

    button,
    a {
      width: unset;
      min-width: 11rem;
    }
  }
`;
