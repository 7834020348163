import React from 'react';
import {
  FunnelWrapper,
  StyledCardArtist,
  StyledCustomizationActionsWrapper,
  StyledCustomizationForm,
  StyledFoundArtistWrapper,
  StyledHeader,
  StyledSubheader,
} from '../../styled';
import {
  useHandleHeaderBackClick,
  useNavigateWithParams,
  useScopedTranslation,
} from '../../../../shared/hooks';
import { MotionContainer } from '../../../../shared/components/custom';
import useFunnelContext from '../../hooks/useFunnelContext';
import { Order } from '../../../../shared/types/order';
import { Paths } from '../../../../router/routes';
import { Button } from '../../../../shared/components/ui';

const FoundArtist = () => {
  const { t, rootT } = useScopedTranslation('funnel.customization');
  const { artistFound, setArtistFound, setArtistSelect, createOrder, parameters, setParameters } =
    useFunnelContext();
  const { navigate } = useNavigateWithParams();

  useHandleHeaderBackClick(true, () => {
    setArtistFound(null);
  });

  const onOrderCreated = (order: Order) => {
    navigate(Paths.order, { orderId: order?.uuid });
  };

  const continueWithArtist = () => {
    if (artistFound) {
      const params = { ...parameters, artistId: artistFound.id };
      setParameters(params);
      createOrder({ ...params }, onOrderCreated);
    }
  };

  const chooseArtist = () => {
    setArtistSelect(true);
    setArtistFound(null);
  };

  return (
    <MotionContainer>
      <FunnelWrapper>
        <StyledCustomizationForm>
          <StyledFoundArtistWrapper>
            <StyledHeader>{t('found_artist')}</StyledHeader>
            <StyledSubheader>{t('best_artist_2')}</StyledSubheader>
            {artistFound && (
              <StyledCardArtist {...artistFound} exampleLabel={rootT('artist.sample')} />
            )}
          </StyledFoundArtistWrapper>
          <StyledCustomizationActionsWrapper>
            <Button onClick={continueWithArtist} type={'button'} color={'primary'}>
              {t('go_with_artist')}
            </Button>
            <Button onClick={chooseArtist} noShadow type={'button'} color={'secondary'}>
              {t('choose_myself')}
            </Button>
          </StyledCustomizationActionsWrapper>
        </StyledCustomizationForm>
      </FunnelWrapper>
    </MotionContainer>
  );
};

export default FoundArtist;
