import { API_URL } from '../constants';
import { httpErrorHandler } from './httpErrorHandler';

type IHttpParams = {
  url: string;
  body?: string | FormData | null;
  headers?: Record<string, string>;
  silent?: boolean;
};

const BASE_URL = API_URL;
const DEFAULT_HEADERS = {
  Accept: 'application/json',
};

const http = (
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  { url, body = null, headers = {}, silent = false }: IHttpParams,
) => {
  return new Promise<never>(async (resolve, reject) => {
    await fetch(`${BASE_URL}${url}`, {
      method,
      headers: { ...DEFAULT_HEADERS, ...headers },
      body,
    })
      .then((response) => (response.ok && response.json()) || Promise.reject(response))
      .then((response) => resolve(response))
      .catch(async (response) => {
        try {
          const error = await response.json();
          if (!silent) httpErrorHandler(error);
          reject(error);
        } catch (err) {
          if (!silent) httpErrorHandler({ message: '' });
          reject({ message: '' });
        }
      });
  });
};

export const httpGet = (
  url: string,
  params: Record<string, string | ''> = {},
  silent: boolean = false,
) => {
  const queryParams = new URLSearchParams(params);
  return http('GET', { url: (queryParams && `${url}?${queryParams}`) || url, silent });
};

export const httpPost = (url: string, data: Object, silent: boolean = false) =>
  http('POST', {
    url,
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
    silent,
  });

export const httpPostFormData = (url: string, body: FormData, silent: boolean = false) =>
  http('POST', { url, body, silent });

export const httpPut = (url: string, data: Object, silent: boolean = false) =>
  http('PUT', {
    url,
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
    silent,
  });

export const httpPatch = (url: string, data: Object, silent: boolean = false) =>
  http('PATCH', {
    url,
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
    silent,
  });

export const httpDelete = (
  url: string,
  params: Record<string, string | ''> = {},
  silent: boolean = false,
) => {
  const queryParams = new URLSearchParams(params);
  return http('DELETE', { url: (queryParams && `${url}?${queryParams}`) || url, silent });
};
