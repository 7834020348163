import React from 'react';
import RecorderInfo from './RecorderInfo';
import { MAX_CLIENT_VOICE_DURATION } from '../../../../../shared/constants';
import { StyledDesktopCard, StyledDesktopWrapper } from '../styled';
import VoiceVisual from './VoiceVisual';
import { IRecorderPage } from './RecorderWrapper';

const DesktopRecorder = ({ isPreview, previewComponent, isRecording, children }: IRecorderPage) => {
  return (
    <>
      <RecorderInfo limit={MAX_CLIENT_VOICE_DURATION / 1000} />
      <StyledDesktopWrapper>
        <StyledDesktopCard>
          {(isPreview && previewComponent) || (
            <>
              {isRecording && <VoiceVisual isWorking={isRecording} />}
              {children}
            </>
          )}
        </StyledDesktopCard>
      </StyledDesktopWrapper>
    </>
  );
};

export default DesktopRecorder;
