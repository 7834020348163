import React from 'react';
import { Grid } from '@chakra-ui/react';
import {
  StyledCustomersHint,
  StyledOptionCard,
  StyledOptionDescription,
  StyledOptionName,
  StyledOptionNameWrapper,
} from './styled';
import { Icon } from '../../icons';
import { VideoPlayer } from '../../ui';
import { useScopedTranslation } from '../../../hooks';
import { IOptionCard } from '../../../types/optionCard';
import OrderOptionAction from './components/OrderOptionAction';
import { OrderOptions } from '../../../constants/OrderOptions';
import { useGetOptionInfo } from '../../../../modules/order/hooks';
import { LocalStorageKeys } from '../../../constants/LocalStorageKeys';
import OrderOptionPrice from './components/OrderOptionPrice';

const OrderOptionVideo = ({
  bordered = false,
  withAction = false,
  storageKey = LocalStorageKeys.orderOptions,
  onToggle = () => {},
  discountedPrice = null,
  price = null,
}: IOptionCard) => {
  const { t } = useScopedTranslation('order');
  const optionInfo = useGetOptionInfo(OrderOptions.video);

  return (
    <StyledOptionCard bordered={bordered}>
      <Grid gap={'1rem'}>
        <VideoPlayer src={'https://media.w3.org/2010/05/sintel/trailer.mp4'} />
        <StyledOptionNameWrapper>
          <Icon type={'Slides'} color={'primary'} />
          <StyledOptionName>{t('slide_show_full')}</StyledOptionName>
        </StyledOptionNameWrapper>
        <StyledOptionDescription>
          <li>{t('slide_show_text_1')}</li>
          <li>{t('slide_show_text_2')}</li>
          <li>{t('slide_show_text_3')}</li>
        </StyledOptionDescription>
      </Grid>
      <Grid>
        <OrderOptionPrice discountedPrice={discountedPrice} price={price || optionInfo?.price} />
        {withAction && (
          <OrderOptionAction
            optionName={OrderOptions.video}
            storageKey={storageKey}
            onToggle={onToggle}
          />
        )}
        <StyledCustomersHint centered={(withAction && 1) || 0}>
          {t('customers_choose', { number: 8, outOf: 10 })}
        </StyledCustomersHint>
      </Grid>
    </StyledOptionCard>
  );
};

export default OrderOptionVideo;
