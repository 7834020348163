import React, { useEffect } from 'react';
import { useHandleHeaderBackClick, useScopedTranslation } from '../../../../shared/hooks';
import {
  StyledFunnelActionWrapper,
  StyledHeaderWithStepper,
  StyledSelectorsWrapper,
  StyledStepForm,
  StyledStepHeader,
  StyledStepHeading,
  StyledStepper,
  StyledStepSubheader,
} from '../../styled';
import { Button } from '../../../../shared/components/ui';
import EyesColor from './EyesColor';
import useFunnelContext from '../../hooks/useFunnelContext';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PersonalFactsSchema } from '../../validationSchema';
import Together from './Together';
import Children from './Children';
import MonthOfDating from './MonthOfDating';
import useLoadEyeColors from '../../requests/useLoadEyeColors';
import { FunnelSteps } from '../../../../shared/constants/FunnelSteps';
import useLoadKids from '../../requests/useLoadKids';

const PersonalFacts = () => {
  const { t, rootT } = useScopedTranslation('funnel.personal_facts');
  useHandleHeaderBackClick(true, () => setFunnelStep(FunnelSteps.GENERAL));

  const {
    parameters,
    setParameters,
    funnelStep,
    setFunnelStep,
    funnelTotalSteps,
    values,
    setValues,
  } = useFunnelContext();

  const { eyeColors: eyeColorValues, children } = values;

  const { getEyeColors } = useLoadEyeColors();
  const { getKids } = useLoadKids();

  const {
    register,
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PersonalFactsSchema),
  });

  const eyesColorDisabled = watch('eyesColorDisabled');
  const togetherDisabled = watch('togetherDisabled');
  const childrenDisabled = watch('childrenDisabled');
  const monthDisabled = watch('monthDisabled');

  const resetValues = async () => {
    const eyeColors = (!!eyeColorValues.length && eyeColorValues) || (await getEyeColors());
    const loadedKids = (!!children.length && children) || (await getKids());
    setValues({ ...values, eyeColors, children: loadedKids });
    reset({ ...parameters });
  };

  useEffect(() => {
    resetValues();
  }, []);

  const nextStep = (args: Object) => {
    setParameters({ ...parameters, ...args });
    setFunnelStep(funnelStep + 1);
  };

  return (
    <StyledStepForm onSubmit={handleSubmit(nextStep)}>
      <StyledStepHeading>
        <StyledHeaderWithStepper>
          <StyledStepHeader>{t('header')}</StyledStepHeader>
          <StyledStepper step={funnelStep + 1} totalSteps={funnelTotalSteps} />
        </StyledHeaderWithStepper>
        <StyledStepSubheader>{t('subheader')}</StyledStepSubheader>
      </StyledStepHeading>
      <StyledSelectorsWrapper>
        <EyesColor register={register} errors={errors} isDisabled={eyesColorDisabled} />
        <Together register={register} errors={errors} isDisabled={togetherDisabled} />
        <Children control={control} register={register} isDisabled={childrenDisabled} />
        <MonthOfDating control={control} register={register} isDisabled={monthDisabled} />
      </StyledSelectorsWrapper>
      <StyledFunnelActionWrapper>
        <Button type={'submit'} color={'primary'}>
          {rootT('funnel.continue')}
        </Button>
      </StyledFunnelActionWrapper>
    </StyledStepForm>
  );
};

export default PersonalFacts;
