import { useNavigate } from 'react-router-dom';
import { useHandleHeaderBackClick, useScopedTranslation } from '../../../hooks';
import { Paths } from '../../../../router/routes';
import {
  StyledHeader,
  StyledText,
  StyledButton,
  StyledExceptionContainer,
  StyledHeadersContainer,
  StyledPageWrapper,
} from './styled';
import { MotionContainer } from '../MotionContainer';
import ErrorIcon from '../../../theme/assets/img/misc/error.svg';

const UncaughtException = ({ resetError }: { resetError: Function }) => {
  const { t, rootT } = useScopedTranslation('general.errors.exception');
  const navigate = useNavigate();
  useHandleHeaderBackClick(false);

  const reset = () => {
    resetError();
    navigate(Paths.funnel);
  };

  return (
    <MotionContainer>
      <StyledPageWrapper>
        <StyledExceptionContainer>
          <StyledHeadersContainer>
            <img src={ErrorIcon} alt={'exception'} />
            <StyledHeader>
              {t('oh_no')}
              <br />
              {t('something_went_wrong')}
            </StyledHeader>
            <StyledText>{t('something_went_wrong_text')}</StyledText>
          </StyledHeadersContainer>
          <StyledButton onClick={reset} color={'primary'}>
            {rootT('general.errors.go_home')}
          </StyledButton>
        </StyledExceptionContainer>
      </StyledPageWrapper>
    </MotionContainer>
  );
};

export default UncaughtException;
