import React from 'react';
import {
  StyledArtistAvatar,
  StyledArtistCard,
  StyledArtistDescription,
  StyledArtistExampleWrapper,
  StyledArtistInfo,
  StyledArtistName,
  StyledHeaderWrapper,
  StyledArtistStat,
  StyledStatGrid,
} from './styled';
import { Icon } from '../../icons';
import { AudioPlayer } from '../../ui';
import { Artist } from '../../../types/artist';
import { useScopedTranslation } from '../../../hooks';

type ICardArtist = Artist & {
  exampleLabel?: string;
  children?: React.ReactNode;
  withExample?: boolean;
};

const CardArtist = ({
  name,
  rating,
  orders_count,
  description,
  photo_url,
  photo_url_thumb,
  example_url,
  children,
  exampleLabel = undefined,
  withExample = true,
  ...props
}: ICardArtist) => {
  const { t, rootT } = useScopedTranslation('artist');

  return (
    <StyledArtistCard {...{ ...props, bordered: true }}>
      <StyledHeaderWrapper>
        <StyledArtistAvatar src={photo_url_thumb ?? photo_url} name={name} />
        <StyledArtistInfo>
          <StyledArtistName>{name}</StyledArtistName>
          <StyledStatGrid>
            <StyledArtistStat>
              <Icon type={'Star'} color={'primary'} />
              {rootT('artist.rating', { rating })}
            </StyledArtistStat>
            <StyledArtistStat>
              <Icon type={'Music2'} color={'primary'} />
              {rootT('artist.orders', { count: orders_count })}
            </StyledArtistStat>
          </StyledStatGrid>
        </StyledArtistInfo>
      </StyledHeaderWrapper>
      {!!description && <StyledArtistDescription>{description}</StyledArtistDescription>}
      {withExample && (
        <StyledArtistExampleWrapper>
          <AudioPlayer src={example_url} label={exampleLabel ?? t('example')} />
        </StyledArtistExampleWrapper>
      )}
      {children}
    </StyledArtistCard>
  );
};

export default CardArtist;
