import React from 'react';
import { Spinner } from '../Spinner';
import {
  StyledCounter,
  StyledErrorImagePlaceholder,
  StyledFilCardImage,
  StyledFileCard,
  StyledImagePlaceholder,
  StyledRemoveIcon,
} from './styled';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SlidesPhoto } from '../../../types/slidesPhoto';
import { useImageThumbnail } from '../../../hooks';
import { Icon } from '../../icons';

const ImageFileCard = ({
  file,
  index = null,
  onRemove = () => {},
  isRemovable = false,
  onRetry = () => {},
  ...props
}: {
  file: SlidesPhoto;
  index?: number | null;
  onRemove?: (file: SlidesPhoto) => void;
  isRemovable?: boolean;
  onRetry?: Function;
}) => {
  const { src, thumb_src: thumbSrc } = file ?? {};
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: file.name,
  });

  const style = { transform: CSS.Transform.toString(transform), transition };

  const removeImage = () => {
    onRemove(file);
  };

  const { src: thumbnail } = useImageThumbnail(thumbSrc, src);

  const retry = () => {
    if (file.error) onRetry(file);
  };

  return (
    <StyledFileCard ref={setNodeRef} {...{ ...props, style }} {...attributes} {...listeners}>
      {(thumbnail && (
        <StyledFilCardImage src={thumbnail}>
          <div />
          <Spinner />
        </StyledFilCardImage>
      )) ||
        (file.error && (
          <StyledErrorImagePlaceholder onClick={retry}>
            <Icon type={'Info'} />
            <span>Retry</span>
          </StyledErrorImagePlaceholder>
        )) || (
          <StyledImagePlaceholder>
            <Spinner />
          </StyledImagePlaceholder>
        )}
      {index !== null && <StyledCounter>{index + 1}</StyledCounter>}
      {isRemovable && <StyledRemoveIcon onClick={removeImage} role={'button'} type={'Close'} />}
    </StyledFileCard>
  );
};

export default ImageFileCard;
