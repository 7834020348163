import React from 'react';
import { StyledReviewCard } from './styled';
import { VideoPlayer } from '../../ui';

const OrderReview1 = () => {
  return (
    <StyledReviewCard bordered>
      <VideoPlayer src={'https://media.w3.org/2010/05/sintel/trailer.mp4'} />
    </StyledReviewCard>
  );
};

export default OrderReview1;
