import React from 'react';
import { LandingHeader } from '../LandingHeader';
import { StyledApp, StyledContentContainer } from './styled';
import { LandingFooter } from '../LandingFooter';

type IAppLayout = {
  children: React.ReactNode | null;
};

const LandingLayout = ({ children }: IAppLayout) => {
  return (
    <StyledApp>
      <LandingHeader />
      <StyledContentContainer fluid>{children}</StyledContentContainer>
      <LandingFooter />
    </StyledApp>
  );
};

export default LandingLayout;
