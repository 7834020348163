import { OrderOptions } from '../../../shared/constants/OrderOptions';
import { useOrderContext } from './index';
import { useMemo } from 'react';

const useGetOptionInfo = (name: OrderOptions) => {
  const { options } = useOrderContext();
  return useMemo(() => options.find((option) => option.name === name), [options]);
};

export default useGetOptionInfo;
