import React, { useEffect } from 'react';
import {
  useHandleBackgroundColor,
  useHandleHeaderBackClick,
  useScopedTranslation,
} from '../../../../shared/hooks';
import useRedirectToOrder from '../../hooks/useRedirectToOrder';
import { useOrderContext } from '../../hooks';
import { designSystem } from '../../../../shared/theme';
import { MotionContainer } from '../../../../shared/components/custom';
import {
  StyledButtonWrapper,
  StyledDropzoneContainer,
  StyledHeader,
  StyledSubHeader,
} from './styled';
import { Dropzone } from '../../../../shared/components/ui/Dropzone';
import { Button } from '../../../../shared/components/ui';
import useHandleSlideshow from '../../requests/useHandleSlideshow';
import { Paths } from '../../../../router/routes';

const OrderPhotosDropzone = () => {
  const { t } = useScopedTranslation('order.editors.video');
  const { navigateToOrder, navigateWithOrder } = useRedirectToOrder();
  const { order } = useOrderContext();

  useHandleBackgroundColor(designSystem.palette.white);
  useHandleHeaderBackClick(
    true,
    () => {
      if (order) navigateToOrder();
    },
    [order],
  );

  const { photos, setPhotos, getPhotos, syncPhotos, removePhoto, isLoading, retryPhoto } =
    useHandleSlideshow();

  useEffect(() => {
    getPhotos();
  }, []);

  const onContinue = async () => {
    navigateWithOrder(Paths.orderVideoEditorSongSelect);
  };

  return (
    <MotionContainer>
      <StyledHeader>{t('upload_photos')}</StyledHeader>
      <StyledSubHeader>{t('when_photos_uploaded')}</StyledSubHeader>

      <StyledDropzoneContainer>
        <Dropzone
          files={photos}
          setFiles={setPhotos}
          isLoading={isLoading}
          onRemove={removePhoto}
          onSort={syncPhotos}
          onDrop={syncPhotos}
          onRetry={retryPhoto}
        />
      </StyledDropzoneContainer>

      {!!photos?.length && (
        <StyledButtonWrapper mt={'2rem'}>
          <Button onClick={onContinue} isLoading={isLoading} type={'button'} color={'primary'}>
            {t('continue')}
          </Button>
        </StyledButtonWrapper>
      )}
    </MotionContainer>
  );
};

export default OrderPhotosDropzone;
