import React, { useState } from 'react';
import { copyToClipBoard } from '../../../utils/utils';
import { useScopedTranslation } from '../../../hooks';
import { StyledTooltip } from './styled';

const CopyButton = ({ text, children, ...props }: { text: string; children: React.ReactNode }) => {
  const { t } = useScopedTranslation('general');
  const [isOpen, setIsOpen] = useState(false);

  const copy = () => {
    setIsOpen(true);

    copyToClipBoard(text);

    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  return (
    <StyledTooltip {...props} hasArrow label={t('copied')} placement="top" isOpen={isOpen}>
      <div onClick={copy} role={'button'}>
        {children}
      </div>
    </StyledTooltip>
  );
};

export default CopyButton;
