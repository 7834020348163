import React, { useMemo } from 'react';
import { useScopedTranslation } from '../../../../shared/hooks';
import { Card, Select, Switch } from '../../../../shared/components/ui';
import { Icon } from '../../../../shared/components/icons';
import { FieldErrorsImpl } from 'react-hook-form';
import { Grid } from '@chakra-ui/react';
import useFunnelContext from '../../hooks/useFunnelContext';

type IEyesColor = {
  register: Function;
  errors: FieldErrorsImpl;
  isDisabled?: boolean;
};

const EyesColor = ({ register, errors, isDisabled = false }: IEyesColor) => {
  const { t, rootT } = useScopedTranslation('funnel.personal_facts');

  const {
    values: { eyeColors: options },
  } = useFunnelContext();

  const selectOptions = useMemo(() => {
    return options.map(({ name }) => ({ value: name, label: name }));
  }, [options]);

  return (
    <Card title={t('eye_color')} icon={<Icon type={'Eye'} color={'primary'} />}>
      <Grid gap={'1rem'}>
        <Select
          {...{
            register,
            errors,
            name: 'eyesColor',
            isDisabled,
            options: selectOptions,
            placeholder: t('select_eye_color'),
            translationScope: 'funnel.personal_facts.eye_colors',
          }}
        />
        <Switch {...{ register, name: 'eyesColorDisabled' }}>{rootT('funnel.dont_mention')}</Switch>
      </Grid>
    </Card>
  );
};

export default EyesColor;
