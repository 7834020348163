import React, { useEffect } from 'react';
import { useOrderContext } from '../../hooks';
import { Outlet } from 'react-router-dom';

const OrderPostcardEditor = () => {
  const { getPostcard } = useOrderContext();

  useEffect(() => {
    getPostcard();
  }, []);

  return <Outlet />;
};

export default OrderPostcardEditor;
