import React, { useMemo } from 'react';
import { Icon } from '../../../../shared/components/icons';
import { Select, Switch } from '../../../../shared/components/ui';
import { useScopedTranslation } from '../../../../shared/hooks';
import { ButtonGroup } from '../../../../shared/components/custom';
import { ButtonGroupTypes } from '../../../../shared/components/custom/ButtonGroup/types';
import { Control, Controller } from 'react-hook-form';
import useFunnelContext from '../../hooks/useFunnelContext';
import { StyledChildrenCard, StyledChildrenSelect } from '../../styled';
import { ChildrenVariants } from './constants';

type IChildren = {
  control: Control;
  register: Function;
  isDisabled?: boolean;
};

const Children = ({ control, register, isDisabled = false }: IChildren) => {
  const { t, rootT } = useScopedTranslation('funnel.personal_facts');
  const kids = [
    {
      value: ChildrenVariants.noChildren,
      label: t('children_select.no'),
    },
    {
      value: ChildrenVariants.hasChildren,
      label: t('children_select.yes'),
    },
  ];

  const {
    parameters: { children },
    values: { children: options },
  } = useFunnelContext();

  const defaultValue = children ?? kids[0]?.value;

  const selectOptions = useMemo(() => {
    return options.map(({ name }) => ({ value: name, label: name }));
  }, [options]);

  return (
    <StyledChildrenCard row title={t('children')} icon={<Icon type={'People'} color={'primary'} />}>
      <Controller
        control={control}
        name="children"
        render={({ field }) => (
          <>
            <ButtonGroup
              type={ButtonGroupTypes.sidescroll}
              defaultValue={defaultValue}
              onChange={field.onChange}
              options={kids}
            />
            {field.value === ChildrenVariants.hasChildren && (
              <StyledChildrenSelect>
                <Select
                  {...{
                    register,
                    name: 'childType',
                    isDisabled,
                    options: selectOptions,
                    translationScope: 'funnel.personal_facts.kids',
                  }}
                />
                <Switch {...{ register, name: 'childrenDisabled' }}>
                  {rootT('funnel.dont_mention')}
                </Switch>
              </StyledChildrenSelect>
            )}
          </>
        )}
      />
    </StyledChildrenCard>
  );
};

export default Children;
