import React, { useMemo } from 'react';
import { useScopedTranslation } from '../../../../../shared/hooks';
import { useOrderContext } from '../../../hooks';
import {
  StyledClientVoiceTab,
  StyledHeader,
  StyledOrderId,
  StyledOrderPaidWrapper,
  StyledSongContentContainer,
} from './styled';
import { Spinner, Tabs } from '../../../../../shared/components/ui';
import FinishedSong from './components/FinishedSong';
import { OrderDoneTabs } from '../../../../../shared/constants/OrderDoneTabs';
import { Icon } from '../../../../../shared/components/icons';
import { OrderOptions, OrderOptionStatus } from '../../../../../shared/constants/OrderOptions';
import NoIntroSong from './components/NoIntroSong';
import SongText from './components/SongText';
import ClientVoiceSong from './components/ClientVoiceSong';
import useIsPaid from '../../../hooks/useIsPaid';

const ClientVoiceTab = () => {
  const { t } = useScopedTranslation('order');

  return (
    <StyledClientVoiceTab>
      <Icon type={'Dot'} color={'primary'} />
      {t(`tabs.${OrderDoneTabs.clientVoiceIntro}`)}
    </StyledClientVoiceTab>
  );
};

const OrderSongDownload = () => {
  const { t } = useScopedTranslation('order');
  const { order } = useOrderContext();
  const isPaid = useIsPaid();

  const hasClientVoiceReady = useMemo(
    () =>
      order?.options?.find(
        ({ name, status }) =>
          name === OrderOptions.clientVoice && status === OrderOptionStatus.finished,
      ),
    [order],
  );

  const tabs = useMemo(() => {
    const tabsArray = [
      t(`tabs.${OrderDoneTabs.artistIntro}`),
      t(`tabs.${OrderDoneTabs.text}`),
      t(`tabs.${OrderDoneTabs.noIntro}`),
    ];

    return (
      (hasClientVoiceReady && [
        <ClientVoiceTab key={OrderDoneTabs.clientVoiceIntro} />,
        ...tabsArray,
      ]) ||
      tabsArray
    );
  }, [order]);

  const panels = useMemo(() => {
    const panelsArray = [
      <FinishedSong key={OrderDoneTabs.artistIntro} />,
      <SongText key={OrderDoneTabs.text} />,
      <NoIntroSong key={OrderDoneTabs.noIntro} />,
    ];

    return (
      (hasClientVoiceReady && [
        <ClientVoiceSong key={OrderDoneTabs.clientVoiceIntro} />,
        ...panelsArray,
      ]) ||
      panelsArray
    );
  }, [hasClientVoiceReady]);

  return (
    <StyledSongContentContainer>
      <StyledHeader>
        {t('order_ready_1')} <StyledOrderId>#{order?.id}</StyledOrderId>{' '}
        {t((isPaid && 'order_almost_ready') || 'order_ready_2')}
      </StyledHeader>

      {(isPaid && (
        <StyledOrderPaidWrapper>
          <div>
            <h3>{t('order_paid_title')}</h3>
            <p>{t('order_paid_hint')}</p>
          </div>
          <Spinner />
        </StyledOrderPaidWrapper>
      )) || <Tabs tabs={tabs}>{panels}</Tabs>}
    </StyledSongContentContainer>
  );
};

export default OrderSongDownload;
