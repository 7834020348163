import React from 'react';
import useRedirectToOrder from '../../hooks/useRedirectToOrder';
import { useOrderContext } from '../../hooks';
import {
  useBreakpoints,
  useHandleBackgroundColor,
  useHandleHeaderBackClick,
  useNavigateWithParams,
  useScopedTranslation,
} from '../../../../shared/hooks';
import { designSystem } from '../../../../shared/theme';
import { Paths } from '../../../../router/routes';
import { Button, LinkButton } from '../../../../shared/components/ui';
import {
  StyledDivider,
  StyledEditorWrapper,
  StyledSquareFormWrapper,
  StyledPostcardText,
  StyledPostcardTitle,
} from './styled';
import { shareUrl } from '../../../../shared/utils/utils';
import PostcardFormImage from './components/PostcardFormImage';
import PostcardTrack from './components/PostcardTrack';
import PostcardFormSkeleton from './components/PostcardFormSkeleton';
import { Icon } from '../../../../shared/components/icons';
import { MotionContainer } from '../../../../shared/components/custom';

const OrderPostcardPreview = () => {
  const { t } = useScopedTranslation('order.editors.postcard');
  const { urlWithParams } = useNavigateWithParams();
  const { navigateWithOrder } = useRedirectToOrder();
  const { order, postcard, isLoadingPostcard } = useOrderContext();
  const { isLg } = useBreakpoints();
  useHandleBackgroundColor((isLg && designSystem.palette.neutral5) || designSystem.palette.white);

  useHandleHeaderBackClick(
    true,
    () => {
      if (order) navigateWithOrder(Paths.orderPostcardEditorForm);
    },
    [order],
  );

  const share = () => {
    shareUrl(postcard?.share_url ?? '', t('share_postcard'));
  };

  return (
    <MotionContainer>
      <StyledEditorWrapper>
        <PostcardFormImage cover={postcard?.image_url ?? ''} />

        <StyledSquareFormWrapper>
          <PostcardTrack track={postcard?.track} />

          <StyledDivider />

          {(!postcard && isLoadingPostcard && <PostcardFormSkeleton />) || (
            <>
              <StyledPostcardTitle>{postcard?.title}</StyledPostcardTitle>
              <StyledPostcardText>{postcard?.text}</StyledPostcardText>
            </>
          )}
        </StyledSquareFormWrapper>

        <StyledDivider />

        <Button
          my={'1rem'}
          noShadow
          block
          isLoading={isLoadingPostcard}
          onClick={share}
          type={'button'}
        >
          <Icon type={'Share'} color={'primary'} />
          {t('share')}
        </Button>
        <LinkButton
          block
          noShadow
          to={urlWithParams(Paths.order, { orderId: order?.uuid ?? '' })}
          color={'primary'}
        >
          {t('back_to_order')}
        </LinkButton>
      </StyledEditorWrapper>
    </MotionContainer>
  );
};

export default OrderPostcardPreview;
