import { useContext, useEffect, useState } from 'react';
import { defaultState, IFunnelParameters, IFunnelValues } from '../context/FunnelContext';
import useCreateOrder from '../requests/useCreateOrder';
import { FunnelSteps } from '../../../shared/constants/FunnelSteps';
import { AppContext } from '../../../shared/context';
import { Artist } from '../../../shared/types/artist';

const useFunnel = () => {
  const [funnelTotalSteps] = useState(defaultState.funnelTotalSteps);
  const [funnelStep, setFunnelStep] = useState(defaultState.funnelStep);
  const [artistSelect, setArtistSelect] = useState(defaultState.artistSelect);
  const [artistFound, setArtistFound] = useState<Artist | null>(defaultState.artistFound);
  const [isLookingForArtist, setIsLookingForArtist] = useState(defaultState.isLookingForArtist);
  const [parameters, setParameters] = useState<IFunnelParameters>({ ...defaultState.parameters });
  const [values, setValues] = useState<IFunnelValues>({ ...defaultState.values });
  const { createOrder, isLoading } = useCreateOrder();

  const { setBackButton } = useContext(AppContext);

  useEffect(() => {
    setBackButton(funnelStep !== FunnelSteps.GENERAL);
  }, [funnelStep, artistSelect]);

  return {
    funnelTotalSteps,
    funnelStep,
    setFunnelStep,
    artistSelect,
    setArtistSelect,
    parameters,
    setParameters,
    createOrder,
    values,
    setValues,
    isCreatingOrder: isLoading,
    artistFound,
    setArtistFound,
    isLookingForArtist,
    setIsLookingForArtist,
  };
};

export default useFunnel;
