import { httpGet } from '../../shared/utils/http';
import { FUNNEL_ROUTE } from '../contstants';
import { Promocode } from '../../shared/types/promocode';
import { Artist } from '../../shared/types/artist';
import { Option } from '../../shared/types/order';
import { PricesResponse } from '../../shared/types/pricesResponse';
import { Segment } from '../../shared/types/segment';
import { Personalization } from '../../shared/types/personalization';

export type FunnelApiType = {
  getArtists: ({
    segment_id,
    occasion_id,
  }: {
    segment_id: string;
    occasion_id: string;
  }) => Promise<Artist[]>;
  getAllArtists: () => Promise<Artist[]>;
  getEyeColors: () => Promise<Personalization[]>;
  getGenders: () => Promise<Personalization[]>;
  getYears: () => Promise<Personalization[]>;
  getGenres: () => Promise<string[]>;
  getKids: () => Promise<Personalization[]>;
  getOptions: () => Promise<Option[]>;
  getSegments: () => Promise<Segment[]>;
  getOccasions: (segment: number) => Promise<Personalization[]>;
  getPrices: () => Promise<PricesResponse>;
  getPromocode: (
    promocode: string,
    params?: Record<string, string>,
    silent?: boolean,
  ) => Promise<Promocode>;
};

export const FunnelApi: FunnelApiType = {
  getArtists: (params) => {
    return httpGet(`${FUNNEL_ROUTE}/artists`, params);
  },
  getAllArtists: () => {
    return httpGet(`${FUNNEL_ROUTE}/artists/all`);
  },
  getEyeColors: () => {
    return httpGet(`${FUNNEL_ROUTE}/eye-colors`);
  },
  getGenders: () => {
    return httpGet(`${FUNNEL_ROUTE}/genders`);
  },
  getYears: () => {
    return httpGet(`${FUNNEL_ROUTE}/years`);
  },
  getGenres: () => {
    return httpGet(`${FUNNEL_ROUTE}/genres`);
  },
  getKids: () => {
    return httpGet(`${FUNNEL_ROUTE}/children`);
  },
  getSegments: () => {
    return httpGet(`${FUNNEL_ROUTE}/segments`);
  },
  getOccasions: (segment: number) => {
    return httpGet(`${FUNNEL_ROUTE}/segments/${segment}/occasions`);
  },
  getOptions: () => {
    return httpGet(`${FUNNEL_ROUTE}/options`);
  },
  getPrices: () => {
    return httpGet(`${FUNNEL_ROUTE}/prices`);
  },
  getPromocode: (promocode: string, params = {}, silent = false) => {
    return httpGet(`${FUNNEL_ROUTE}/promocodes/${promocode}`, params, silent);
  },
};
