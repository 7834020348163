import React from 'react';
import { StyledInputWrapper, StyledMailingText, StyledNewsletterWrapper } from '../styled';
import { Button, Input } from '../../../ui';
import { ButtonSizes } from '../../../../theme';
import { Icon } from '../../../icons';
import { useMailingHook } from '../../../../requests/useMailingHook';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useScopedTranslation } from '../../../../hooks';

const SubscribeToNewsletter = () => {
  const { t } = useScopedTranslation('footer');

  const { isLoading, subscribe } = useMailingHook();

  const { register, handleSubmit, reset } = useForm<{ email: string }>({
    defaultValues: { email: '' },
  });

  const onSubmit: SubmitHandler<{ email: string }> = async (args) => {
    if (args.email)
      await subscribe(args.email).then(() => {
        reset({});
      });
  };

  return (
    <StyledNewsletterWrapper>
      <StyledMailingText>{t('join')}</StyledMailingText>
      <StyledInputWrapper onSubmit={handleSubmit(onSubmit)}>
        <Input register={register} name={'email'} placeholder={t('email')} />
        <Button type={'submit'} isLoading={isLoading} noShadow size={ButtonSizes.sm}>
          <Icon type={'ChevronLeft'} />
        </Button>
      </StyledInputWrapper>
    </StyledNewsletterWrapper>
  );
};

export default SubscribeToNewsletter;
