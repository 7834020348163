import styled from '@emotion/styled';
import { Flex, Grid, Text } from '@chakra-ui/react';
import { Icon } from '../../../../shared/components/icons';
import { AudioPlayer, Button } from '../../../../shared/components/ui';

export const StyledRecorderWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const StyledWaveIcon = styled(Icon)`
  width: 4.5rem;
  height: auto;
  margin-top: 1.3rem;
  margin-bottom: 2.3rem;

  svg,
  path {
    fill: #bcbfc1;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl3};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 2rem;
  max-width: 19rem;
  margin-bottom: 0.75rem;
  white-space: pre-line;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl4};
    color: ${({ theme }) => theme.colors.text.darker};
    line-height: 3rem;
    max-width: 27rem;
  }
`;

export const StyledDesktopWrapperHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl3};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 2rem;
  margin-bottom: 0.75rem;
  white-space: pre-line;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    font-size: ${({ theme }) => theme.fontSize.xl};
  }
`;

export const StyledSubheader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};
  line-height: 1.25rem;
  white-space: pre-line;
`;

export const StyledRecommendationsWrapper = styled(Grid)`
  margin-top: 1rem;
  grid-gap: 1rem;
  margin-bottom: 1.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 2rem;
  }
`;

export const StyledRecommendation = styled(Grid)`
  grid-template-columns: 2rem 1fr;
  align-items: center;
  grid-gap: 1.25rem;

  > span:first-of-type {
    font-family: ${({ theme }) => theme.fontFamily.headingBold};
    font-size: ${({ theme }) => theme.fontSize.xl3};
    color: ${({ theme }) => theme.palette.primary};
    line-height: 2rem;
  }

  > span:last-of-type {
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.colors.text.main};
    line-height: 1.25rem;
    white-space: pre-line;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 2.3rem 1fr;
    align-items: start;

    > span:first-of-type {
      font-size: 2rem;
    }

    > span:last-of-type {
      font-size: ${({ theme }) => theme.fontSize.md};
      line-height: 1.5rem;
    }
  }
`;

export const StyledExampleText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};
  line-height: 1.25rem;
  margin-bottom: 0.75rem;
  white-space: pre-line;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.md};
    line-height: 1.5rem;
    white-space: unset;
  }
`;

export const StyledAudioPlayer = styled(AudioPlayer)`
  p {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;

export const StyledVisualContainer = styled.div`
  min-height: calc(100vh - 28rem);
  margin-bottom: 2rem;
  margin-inline: -1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-height: unset;
  }
`;

export const StyledPreviewWrapper = styled(Grid)`
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 0.75rem;
  margin-top: 1.125rem;
`;

export const StyledDeleteButton = styled(Button)`
  width: 2.5rem;
  height: 2.5rem;

  svg {
    width: 1.3rem;
    height: auto;

    path {
      fill: #7f8596;
    }
  }
`;

export const StyledButtonWrapper = styled(Flex)`
  width: 100%;
  button,
  a {
    width: 100%;
  }
`;

export const StyledPreviewContainer = styled.div`
  min-height: calc(100vh - 27rem);

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-height: unset;
  }
`;

export const StyledDesktopWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background: ${({ theme }) => theme.palette.neutral5};
    border-radius: ${({ theme }) => theme.radii.sm};
    padding: 2rem;
  }
`;

export const StyledDesktopCard = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background: ${({ theme }) => theme.palette.white};
    border-radius: ${({ theme }) => theme.radii.sm};
    padding: 2rem;
    max-width: 25rem;
    margin-inline: auto;
  }
`;
