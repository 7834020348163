import styled from '@emotion/styled';
import { AudioPlayer, Card } from '../../ui';
import { Flex, Grid, Text } from '@chakra-ui/react';

export const StyledOptionCard = styled(Card)`
  padding: 1rem 1.25rem;
  height: 100%;

  > div:first-of-type {
    img {
      width: 100%;
      border-radius: ${({ theme }) => theme.radii.sm};
      aspect-ratio: 16 / 9;
      object-fit: cover;
    }

    div:first-of-type {
      width: 100%;
      border-radius: ${({ theme }) => theme.radii.sm};

      > video {
        border-radius: ${({ theme }) => theme.radii.sm};
        aspect-ratio: 16 / 9;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const StyledOptionNameWrapper = styled(Grid)`
  grid-template-columns: auto 1fr;
  grid-gap: 1.25rem;
  align-items: center;

  svg {
    max-width: 2.5rem;
    height: auto;
  }
`;

export const StyledOptionName = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xl2};
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
`;

export const StyledOptionDescription = styled.ul`
  list-style-type: '- ';
  padding-left: 0.6rem;
  max-width: 85%;

  li {
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.colors.text.main};
    line-height: 1.25rem;
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const StyledOptionDescriptionText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 1.25rem;
`;

export const StyledAudioPlayer = styled(AudioPlayer)`
  margin-bottom: 0.75rem;

  > p:last-of-type {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;

export const StyledPriceWrapper = styled(Flex)`
  grid-gap: 0.75rem;
  align-items: baseline;
`;

export const StyledOptionPrice = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xl2};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  color: ${({ theme }) => theme.colors.text.darker};
`;

export const StyledPriceCrossed = styled.div`
  position: relative;

  span {
    color: ${({ theme }) => theme.colors.text.placeholder};
    font-size: ${({ theme }) => theme.fontSize.md};
    font-family: ${({ theme }) => theme.fontFamily.headingBold};
  }

  img {
    position: absolute;
    top: 10px;
    left: -3px;
    z-index: 2;
    max-width: 2rem;
  }
`;

export const StyledCustomersHint = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.main};
  margin-top: 1rem;

  text-align: ${({ centered }) => (centered && 'center') || 'start'};
`;
