import React from 'react';
import { Icon } from '../../icons';
import { StyledArrowButton } from './styled';

const CarouselArrow = ({ prev = false, ...props }: { prev?: boolean }) => {
  return (
    <StyledArrowButton prev={prev} {...props}>
      <Icon type={'ChevronLeft'} />
    </StyledArrowButton>
  );
};

export default CarouselArrow;
