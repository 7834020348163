import React from 'react';
import { Icon } from '../../../../shared/components/icons';
import { Card, Input } from '../../../../shared/components/ui';
import { useScopedTranslation } from '../../../../shared/hooks';
import { FieldErrorsImpl } from 'react-hook-form';
import { StyledEmailHint } from '../../styled';

type IRecipient = {
  register: Function;
  errors: FieldErrorsImpl;
};

const Receiver = ({ register, errors }: IRecipient) => {
  const { t, rootT } = useScopedTranslation('funnel.contact_information');

  return (
    <Card
      title={t('email')}
      subtitle={t('email_hint')}
      icon={<Icon type={'Mail'} color={'primary'} />}
    >
      <StyledEmailHint>{t('email_hint_2')}</StyledEmailHint>
      <Input
        {...{
          register,
          errors,
          name: 'email',
          placeholder: rootT('funnel.enter_email'),
          type: 'email',
        }}
      />
    </Card>
  );
};

export default Receiver;
