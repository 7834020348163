import React from 'react';
import { Icon } from '../../icons';
import { StyledCloseButton } from './styled';

const ModalCloseButton = () => {
  return (
    <StyledCloseButton>
      <Icon type={'Close'} />
    </StyledCloseButton>
  );
};

export default ModalCloseButton;
