import React from 'react';
import {
  StyledReviewAuthor,
  StyledReviewCard,
  StyledReviewContentContainer,
  StyledSmallReviewText,
} from './styled';
import { VideoPlayer } from '../../ui';
import { useScopedTranslation } from '../../../hooks';

const OrderReview2 = () => {
  const { t } = useScopedTranslation('order');

  return (
    <StyledReviewCard bordered>
      <VideoPlayer src={'https://media.w3.org/2010/05/sintel/trailer.mp4'} />
      <StyledReviewContentContainer>
        <StyledSmallReviewText>{t('review_2_text')}</StyledSmallReviewText>
        <StyledReviewAuthor>{t('review_2_author')}</StyledReviewAuthor>
      </StyledReviewContentContainer>
    </StyledReviewCard>
  );
};

export default OrderReview2;
