import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import RootRouter from './router/RootRouter';
import './shared/i18n/i18n';
import * as Sentry from '@sentry/react';

// Theme
import { GlobalThemeProvider } from './shared/theme';
import { setDefaultDateLocale } from './shared/i18n/i18n';
import { useGlobalAudioHandler } from './shared/hooks';
import ToastContainer from './shared/components/ui/Toast/Toast';
import { UncaughtException } from './shared/components/custom';

function App() {
  setDefaultDateLocale();
  useGlobalAudioHandler();

  return (
    <GlobalThemeProvider>
      <BrowserRouter>
        <Sentry.ErrorBoundary
          fallback={({ resetError }) => <UncaughtException resetError={resetError} />}
        >
          <RootRouter />
        </Sentry.ErrorBoundary>
      </BrowserRouter>
      <ToastContainer />
    </GlobalThemeProvider>
  );
}

export default App;
