import React from 'react';
import {
  StyledCheckoutButtonWrapper,
  StyledOptionsTabs,
  StyledOptionsWrapper,
  StyledScreenHeader,
  StyledTab,
  StyledTabList,
} from './styled';
import OrderOptionVoice from '../../../../../shared/components/custom/OrderOptionCard/OrderOptionVoice';
import OrderOptionVideo from '../../../../../shared/components/custom/OrderOptionCard/OrderOptionVideo';
import OrderOptionDuet from '../../../../../shared/components/custom/OrderOptionCard/OrderOptionDuet';
import { LinkButton } from '../../../../../shared/components/ui';
import { TabPanel, TabPanels } from '@chakra-ui/react';
import { Icon } from '../../../../../shared/components/icons';
import { useNavigateWithParams, useScopedTranslation } from '../../../../../shared/hooks';
import { Paths } from '../../../../../router/routes';
import { useGetOrderId } from '../../../hooks';

const OrderOptions = () => {
  const orderId = useGetOrderId();
  const { urlWithParams } = useNavigateWithParams();
  const { t } = useScopedTranslation('order');

  return (
    <>
      <StyledScreenHeader>
        {t('enhance_song_1')} <br />
        {t('enhance_song_2')}
      </StyledScreenHeader>

      <StyledOptionsTabs>
        <StyledTabList>
          <StyledTab>
            <div>
              <Icon type={'Wave'} color={'primary'} />
            </div>
            <span>{t('your_voice')}</span>
          </StyledTab>
          <StyledTab>
            <div>
              <Icon type={'Slides'} color={'primary'} />
            </div>
            <span>{t('slide_show')}</span>
          </StyledTab>
          <StyledTab>
            <div>
              <Icon type={'Mic'} color={'primary'} />
            </div>
            <span>{t('back_vocal')}</span>
          </StyledTab>
        </StyledTabList>

        <TabPanels>
          <TabPanel p={0}>
            <OrderOptionVoice bordered />
          </TabPanel>
          <TabPanel p={0}>
            <OrderOptionVideo bordered />
          </TabPanel>
          <TabPanel p={0}>
            <OrderOptionDuet bordered />
          </TabPanel>
        </TabPanels>
      </StyledOptionsTabs>

      <StyledOptionsWrapper>
        <OrderOptionVoice bordered />
        <OrderOptionVideo bordered />
        <OrderOptionDuet bordered />
      </StyledOptionsWrapper>

      <StyledCheckoutButtonWrapper>
        <LinkButton to={urlWithParams(Paths.orderOptions, { orderId })} color={'primary'}>
          {t('checkout')}
        </LinkButton>
      </StyledCheckoutButtonWrapper>
    </>
  );
};

export default OrderOptions;
