import { useEffect, useState } from 'react';
import { appLocalStorage } from '../utils/appLocalStorage';
import { LocalStorageKeys } from '../constants/LocalStorageKeys';
import { OrderOptions } from '../constants/OrderOptions';

export const useProcessStoredOptions = (storageKey: LocalStorageKeys) => {
  const [storedOptions, setStoredOptions] = useState<OrderOptions[]>([]);

  const resetStoredOptions = () => {
    setStoredOptions(appLocalStorage.getItem(storageKey) ?? []);
  };

  useEffect(() => {
    resetStoredOptions();
  }, []);

  return { storedOptions, resetStoredOptions };
};
