import React from 'react';
import { StyledSocialButtonsWrapper, StyledSocialsHeader } from './styled';
import { ButtonSocial } from '../../../../../shared/components/custom';
import { SocialNetworks, SocialNetworkUrls } from '../../../../../shared/constants/SocialNetworks';
import { useScopedTranslation } from '../../../../../shared/hooks';
import { OrderInfo } from './OrderInfo';

const OrderCreated = () => {
  const { t } = useScopedTranslation('order');

  return (
    <div>
      <OrderInfo />
      <StyledSocialsHeader>{t('socials')}</StyledSocialsHeader>
      <StyledSocialButtonsWrapper>
        <ButtonSocial socialNetwork={SocialNetworks.instagram} href={SocialNetworkUrls.instagram} />
        <ButtonSocial socialNetwork={SocialNetworks.facebook} href={SocialNetworkUrls.facebook} />
      </StyledSocialButtonsWrapper>
    </div>
  );
};

export default OrderCreated;
