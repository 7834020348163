import React from 'react';
import { Icon } from '../../icons';

const FacebookContent = () => {
  return (
    <>
      <Icon type={'Facebook'} />
      <span>Facebook</span>
    </>
  );
};

export default FacebookContent;
