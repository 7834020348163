import React from 'react';
import {
  StyledFunnelActionWrapper,
  StyledHeaderWithStepper,
  StyledSelectorsWrapper,
  StyledStepForm,
  StyledStepHeader,
  StyledStepHeading,
  StyledStepper,
  StyledStepSubheader,
} from '../../styled';
import { Button } from '../../../../shared/components/ui';
import { useScopedTranslation } from '../../../../shared/hooks';
import Recipient from './Recipient';
import Occasion from './Occasion';
import Date from './Date';
import useFunnelContext from '../../hooks/useFunnelContext';
import useProcessValues from './hooks/useProcessValues';

const GeneralInformation = () => {
  const { parameters, setParameters, funnelStep, setFunnelStep, funnelTotalSteps } =
    useFunnelContext();
  const { t, rootT } = useScopedTranslation('funnel.general_information');

  const { handleSubmit, register, control, errors } = useProcessValues();

  const nextStep = (args: Object) => {
    setParameters({ ...parameters, ...args });
    setFunnelStep(funnelStep + 1);
  };

  return (
    <StyledStepForm onSubmit={handleSubmit(nextStep)}>
      <StyledStepHeading>
        <StyledHeaderWithStepper>
          <StyledStepHeader>{t('header')}</StyledStepHeader>
          <StyledStepper step={funnelStep + 1} totalSteps={funnelTotalSteps} />
        </StyledHeaderWithStepper>
        <StyledStepSubheader>{t('subheader')}</StyledStepSubheader>
      </StyledStepHeading>
      <StyledSelectorsWrapper>
        <Recipient errors={errors} register={register} />
        <Occasion errors={errors} register={register} />
        <Date control={control} errors={errors} />
      </StyledSelectorsWrapper>
      <StyledFunnelActionWrapper>
        <Button type={'submit'} color={'primary'}>
          {rootT('funnel.continue')}
        </Button>
      </StyledFunnelActionWrapper>
    </StyledStepForm>
  );
};

export default GeneralInformation;
