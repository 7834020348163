import React, { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  StyledHeader,
  StyledOrderNumber,
  StyledPageWrapper,
  StyledPaymentInfoWrapper,
  StyledSocialsButtonsWrapper,
  StyledSocialsHeader,
  StyledSubHeader,
  StyledTextWrapper,
} from './styled';
import { ButtonSocial } from '../../shared/components/custom';
import { SocialNetworks, SocialNetworkUrls } from '../../shared/constants/SocialNetworks';
import useSuccessfulPayment from './requests/useSuccessfulPayment';
import {
  useHandleBackgroundColor,
  useNavigateWithParams,
  useScopedTranslation,
} from '../../shared/hooks';
import { Paths } from '../../router/routes';
import PaymentIcon from '../../shared/theme/assets/img/misc/payment_success.svg';
import { designSystem } from '../../shared/theme';

const SuccessfulPayment = () => {
  const { t } = useScopedTranslation('payments.success');
  const { urlWithParams } = useNavigateWithParams();
  const [searchParams] = useSearchParams();

  useHandleBackgroundColor(designSystem.palette.white);
  const order = useMemo(
    () => ({
      id: searchParams.get('orderNumber') ?? '',
      uuid: searchParams.get('orderId') ?? '',
      txnId: searchParams.get('t') ?? '',
    }),
    [searchParams],
  );

  useSuccessfulPayment(order.uuid, order.txnId);

  return (
    <StyledPageWrapper>
      <StyledPaymentInfoWrapper>
        <StyledTextWrapper>
          <img src={PaymentIcon} alt={'thank you'} />
          <StyledHeader>{t('thank_you')}</StyledHeader>
          <Link to={urlWithParams(Paths.order, { orderId: order?.uuid })}>
            <StyledOrderNumber>{t('your_order', { orderId: order?.id })}</StyledOrderNumber>
          </Link>
          <StyledSubHeader>{t('email')}</StyledSubHeader>
          <StyledSocialsHeader>{t('socials')}</StyledSocialsHeader>
        </StyledTextWrapper>
        <StyledSocialsButtonsWrapper>
          <ButtonSocial
            href={SocialNetworkUrls.instagram}
            socialNetwork={SocialNetworks.instagram}
          />
          <ButtonSocial href={SocialNetworkUrls.facebook} socialNetwork={SocialNetworks.facebook} />
        </StyledSocialsButtonsWrapper>
      </StyledPaymentInfoWrapper>
    </StyledPageWrapper>
  );
};

export default SuccessfulPayment;
