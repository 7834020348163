import React from 'react';
import { To } from 'react-router-dom';
import { ButtonSizes, ThemeColorButton } from '../../../theme';
import { StyledLinkButton } from './styled';

export type CustomButtonProps = {
  color?: ThemeColorButton;
  children?: React.ReactNode;
  size?: ButtonSizes;
  fab?: boolean;
  noShadow?: boolean;
  block?: boolean;
  href?: string | null;
  to?: To;
};

const LinkButton = ({
  color = 'secondary',
  children,
  size = ButtonSizes.md,
  fab = false,
  noShadow = false,
  block = false,
  href = null,
  to = '',
  ...props
}: CustomButtonProps) => {
  return (
    <StyledLinkButton
      {...{
        to,
        href,
        colortheme: color,
        size,
        fab: (fab && 1) || 0,
        block: (block && 1) || 0,
        noshadow: (noShadow && 1) || 0,
        ...props,
      }}
    >
      {children}
    </StyledLinkButton>
  );
};

export default LinkButton;
