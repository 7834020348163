import { useNavigate } from 'react-router-dom';
import {
  useHandleBackgroundColor,
  useHandleHeaderBackClick,
  useScopedTranslation,
} from '../../../hooks';
import { designSystem } from '../../../theme';
import { Paths, RootPaths } from '../../../../router/routes';
import {
  StyledHeader,
  StyledText,
  StyledButton,
  StyledNotFoundWrapper,
  StyledPageWrapper,
} from './styled';
import { MotionContainer } from '../MotionContainer';
import NotFoundIcon from '../../../theme/assets/img/misc/404.svg';
import React from 'react';
import { LandingLayout } from '../LandingLayout';

const NotFound = () => {
  const { t, rootT } = useScopedTranslation('general.errors.not_found');
  const navigate = useNavigate();
  useHandleBackgroundColor(designSystem.palette.white);

  useHandleHeaderBackClick(
    true,
    () => {
      navigate(Paths.funnel);
    },
    [],
  );

  return (
    <LandingLayout>
      <MotionContainer>
        <StyledPageWrapper>
          <StyledNotFoundWrapper>
            <img src={NotFoundIcon} alt={'404'} />
            <StyledHeader>{t('oops')}</StyledHeader>
            <StyledText>{t('not_found_text')}</StyledText>
            <StyledButton to={RootPaths.landing} color={'primary'}>
              {rootT('general.errors.go_home')}
            </StyledButton>
          </StyledNotFoundWrapper>
        </StyledPageWrapper>
      </MotionContainer>
    </LandingLayout>
  );
};

export default NotFound;
