import { useEffect, useState } from 'react';
import { Promocode } from '../types/promocode';
import { FunnelApi } from '../../api';

export const useHandlePromocode = () => {
  const [isChecking, setIsChecking] = useState(false);
  const [promocode, setPromocode] = useState<string>('');
  const [promocodeError, setPromocodeError] = useState<boolean>(false);
  const [promocodeInfo, setPromocodeInfo] = useState<Promocode | null>(null);

  const checkPromocode = () => {
    setPromocodeInfo(null);
    setPromocodeError(false);
    setIsChecking(true);
    FunnelApi.getPromocode(promocode)
      .then((response: Promocode) => {
        setPromocodeInfo(response);
      })
      .catch(() => {
        setPromocodeError(true);
      })
      .finally(() => {
        setIsChecking(false);
      });
  };

  useEffect(() => {
    if (!promocode) {
      setPromocodeInfo(null);
      setPromocodeError(false);
    }
  }, [promocode]);

  const promocodeChanged = ({ target: { value } }: { target: HTMLInputElement }) => {
    setPromocode(value.trim());
  };

  return {
    promocode,
    promocodeChanged,
    checkPromocode,
    promocodeError,
    discount: promocodeInfo?.value || promocodeInfo?.discount || 0,
    isChecking,
  };
};
