import styled from '@emotion/styled';
import { Button, ContentContainer, LinkButton } from '../../ui';
import { Text } from '@chakra-ui/react';

export const StyledHeaderBg = styled.div`
  background: ${({ theme }) => theme.palette.neutral5}e3;
  top: 0;
  z-index: 33;
  backdrop-filter: blur(2px);
  position: sticky;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    background: ${({ theme }) => theme.palette.white}e3;
  }
`;

export const StyledHeaderWrapper = styled(ContentContainer)`
  padding: 0.5rem 2.875rem;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-gap: 1.25rem;
  min-height: 4.5rem;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.neutral10};
    padding: 0.75rem 1.25rem;
    grid-template-columns: auto 1fr auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr auto;
  }
`;

export const StyledNavText = styled(Text)`
  color: ${({ theme }) => theme.palette.neutral60};
  font-size: ${({ theme }) => theme.fontSize.sm};
  white-space: pre-line;
  line-height: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const StyledNavigationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledNavigationItem = styled.a`
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: 15px;
  transition: 0.2s;

  &:hover {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const StyledLinkButton = styled(LinkButton)`
  height: 2.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledBurgerButton = styled(Button)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;
