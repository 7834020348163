import { useState } from 'react';
import { FunnelApi } from '../../../api';
import { Personalization } from '../../../shared/types/personalization';

const useLoadKids = () => {
  const [kids, setKids] = useState<Personalization[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getKids = async () => {
    setIsLoading(true);

    const resp = await FunnelApi.getKids()
      .then((response) => {
        setKids(response);
        return response;
      })
      .finally(() => setIsLoading(false));
    return resp;
  };

  return { kids, getKids, isLoading };
};

export default useLoadKids;
