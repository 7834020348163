import React from 'react';
import {
  StyledAudioPlayer,
  StyledCustomersHint,
  StyledOptionCard,
  StyledOptionDescription,
  StyledOptionName,
  StyledOptionNameWrapper,
} from './styled';
import OptionImage from '../../../theme/assets/img/order/option_voice.jpg';
import OptionImage294 from '../../../theme/assets/img/order/option_voice_294.jpg';
import OptionImage475 from '../../../theme/assets/img/order/option_voice_475.jpg';
import { Icon } from '../../icons';
import { Grid } from '@chakra-ui/react';
import { useScopedTranslation } from '../../../hooks';
import { IOptionCard } from '../../../types/optionCard';
import OrderOptionAction from './components/OrderOptionAction';
import { OrderOptions } from '../../../constants/OrderOptions';
import { useGetOptionInfo } from '../../../../modules/order/hooks';
import { LocalStorageKeys } from '../../../constants/LocalStorageKeys';
import OrderOptionPrice from './components/OrderOptionPrice';

const OrderOptionVoice = ({
  bordered = false,
  withAction = false,
  storageKey = LocalStorageKeys.orderOptions,
  onToggle = () => {},
  price = null,
  discountedPrice = null,
}: IOptionCard) => {
  const { t } = useScopedTranslation('order');
  const optionInfo = useGetOptionInfo(OrderOptions.clientVoice);
  const voicePreview = '';

  return (
    <StyledOptionCard bordered={bordered}>
      <Grid gap={'1rem'}>
        <img
          src={OptionImage}
          srcSet={`${OptionImage294} 294w, ${OptionImage475} 475w, ${OptionImage} 1200w`}
          sizes="(max-width: 475px) 475px, (max-width: 1024px) 1200px, (min-width: 1025px) 294px"
        />
        <StyledOptionNameWrapper>
          <Icon type={'Wave'} color={'primary'} />
          <StyledOptionName>{t('your_voice_full')}</StyledOptionName>
        </StyledOptionNameWrapper>
        <StyledOptionDescription>
          <li>{t('your_voice_text_1')}</li>
          <li>{t('your_voice_text_2')}</li>
          <li>{t('your_voice_text_3')}</li>
        </StyledOptionDescription>
      </Grid>
      <Grid>
        <StyledAudioPlayer src={voicePreview} label={'Example'} />
        <OrderOptionPrice discountedPrice={discountedPrice} price={price || optionInfo?.price} />
        {withAction && (
          <OrderOptionAction
            optionName={OrderOptions.clientVoice}
            storageKey={storageKey}
            onToggle={onToggle}
          />
        )}
        <StyledCustomersHint centered={(withAction && 1) || 0}>
          {t('customers_choose', { number: 6, outOf: 10 })}
        </StyledCustomersHint>
      </Grid>
    </StyledOptionCard>
  );
};

export default OrderOptionVoice;
