import { useContext, useEffect, useMemo, useState } from 'react';
import { isAfter, parseISO } from 'date-fns';
import { useOrderContext } from './index';
import { AppContext } from '../../../shared/context';

const useProcessOrderOffer = () => {
  const { prices } = useContext(AppContext);
  const { order } = useOrderContext();

  const orderPrice = useMemo(() => order?.order_price, [order]);
  const expiresAt = useMemo(() => orderPrice?.expires_at ?? '', [orderPrice]);
  const [isOfferActive, setIsOfferActive] = useState(false);

  useEffect(() => {
    setIsOfferActive((expiresAt && isAfter(parseISO(expiresAt), new Date())) || false);
  }, [expiresAt]);

  const onOfferEnded = () => {
    setIsOfferActive(false);
  };

  const price = useMemo<{ default: number | null; current: number | null }>(() => {
    return {
      default: prices?.order?.full ?? null,
      current: ((isOfferActive && orderPrice?.price) || prices?.order?.full) ?? null,
    };
  }, [prices, orderPrice, isOfferActive]);

  return { isOfferActive, expiresAt, onOfferEnded, price };
};

export default useProcessOrderOffer;
