import React from 'react';
import {
  StyledHeader,
  StyledOfferWrapper,
  StyledPhotoWrapper,
  StyledSubheader,
  StyledTextWrapper,
  StyledTryActionText,
  StyledTryActionWrapper,
  StyledTryCard,
  StyledTryHeader,
  StyledTryText,
} from './styled';
import { LinkButton } from '../../../../../../shared/components/ui';
import OfferImage from '../../../../../../shared/theme/assets/img/landing/offer.png';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import { Paths } from '../../../../../../router/routes';
import { StyledContainer } from '../../styled';

const Offer = () => {
  const { t } = useScopedTranslation('landing.offer');

  return (
    <StyledContainer>
      <StyledOfferWrapper>
        <StyledPhotoWrapper>
          <img src={OfferImage} alt={'offer'} />
        </StyledPhotoWrapper>
        <StyledTextWrapper>
          <StyledSubheader>{t('give_gift')}</StyledSubheader>
          <StyledHeader>
            <span>{t('wow_moment')}</span> {t('with_song')}
          </StyledHeader>

          <StyledTryCard>
            <StyledTryHeader>{t('try_for_free')}</StyledTryHeader>
            <StyledTryText>
              {t('give_facts')} <b>{t('in_24_hours')}</b>
            </StyledTryText>
            <StyledTryActionWrapper>
              <LinkButton to={Paths.funnel} color={'primary'} noShadow>
                {t('try_it_free')}
              </LinkButton>
              <StyledTryActionText>
                {t('result')} <b>{t('for_only')}</b>
              </StyledTryActionText>
            </StyledTryActionWrapper>
          </StyledTryCard>
        </StyledTextWrapper>
      </StyledOfferWrapper>
    </StyledContainer>
  );
};

export default Offer;
