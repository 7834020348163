import styled from '@emotion/styled';
import { Button } from '../Button';
import { Grid, Text } from '@chakra-ui/react';
import { Select } from '../Select';
import { Icon } from '../../icons';

export const StyledDatepicker = styled.div`
  .react-datepicker {
    font-family: ${({ theme }) => theme.fontFamily.body};
    background: ${({ theme }) => theme.palette.white};
    border: none;
    border-radius: ${({ theme }) => theme.radii.md};
    box-shadow: ${({ theme }) => theme.shadow.datepicker};

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__month {
    }

    .react-datepicker__header {
      background: ${({ theme }) => theme.palette.white};
      border: none;
      padding-bottom: 0;
      border-radius: ${({ theme }) => theme.radii.md};

      .react-datepicker__day-name {
        font-size: ${({ theme }) => theme.fontSize.sm};
        color: ${({ theme }) => theme.colors.text.placeholder};
        width: 1.75rem;
        line-height: 1.7rem;
      }
    }

    .react-datepicker__day {
      font-size: ${({ theme }) => theme.fontSize.md};
      color: ${({ theme }) => theme.colors.text.main};
      font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
      font-weight: 500;
      width: 1.75rem;
      height: 1.75rem;
      line-height: 1.7rem;

      &:hover {
        border-radius: 100%;
      }

      &.react-datepicker__day--keyboard-selected {
        border-radius: 100%;
        background-color: ${({ theme }) => theme.palette.primary16};
        color: ${({ theme }) => theme.colors.text.main};
      }

      &.react-datepicker__day--selected {
        border-radius: 100%;
        background-color: ${({ theme }) => theme.palette.primary};
        color: ${({ theme }) => theme.palette.white};
      }
    }

    .react-datepicker__day--outside-month {
      opacity: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    .react-datepicker {
      .react-datepicker__header .react-datepicker__day-name {
        width: 2.2rem;
        line-height: 2rem;
      }
      .react-datepicker__day {
        width: 2.2rem;
        height: 2.2rem;
        line-height: 2rem;
      }
    }
  }
`;

export const StyledDatepickerButton = styled(Button)`
  padding: 1rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: ${({ theme }) => theme.radii.sm};
  height: 3.25rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.main};
  box-shadow: none;
  background: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:focus,
  &[data-focus] {
    border-color: ${({ theme }) => theme.palette.primary};
    box-shadow: none;
  }

  &:hover {
    background: transparent;
  }
`;

export const StyledPlaceholder = styled(Text)`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledDatePickerHeader = styled.div`
  padding-inline: 0.5rem;
`;

export const StyledHeader = styled(Grid)`
  align-items: center;
  grid-template-columns: auto 1fr auto;
  margin-bottom: 0.5rem;
  grid-gap: 0.5rem;
`;

export const StyledYearMonthSelect = styled(Grid)`
  align-items: center;
  grid-template-columns: 1fr minmax(auto, 5rem);
  grid-gap: 0.5rem;
`;

export const StyledSwitchMonthButton = styled(Button)`
  height: 2rem;
  width: 2rem;
  padding: 0;
  background: ${({ theme }) => theme.palette.neutral10};
`;

export const StyledChevronLeft = styled(Icon)`
  svg,
  path {
    fill: transparent;
  }
`;

export const StyledChevronRight = styled(Icon)`
  transform: rotate(180deg);
  svg,
  path {
    fill: transparent;
  }
`;

export const StyledMonthYear = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.colors.text.main};
`;

export const StyledSelect = styled(Select)`
  padding: 0 0.5rem;
  height: 2.25rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  background: ${({ theme }) => theme.palette.white};
  cursor: pointer;

  + .chakra-select__icon-wrapper {
    margin-right: 0rem;
  }
`;
