import { useContext, useEffect, useMemo, useState } from 'react';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import { addDays, isBefore } from 'date-fns';
import { useGetOrderDeadline } from '../../../../hooks';
import { AppContext } from '../../../../../../shared/context';
import { OrderSpeedUp } from '../../../../../../shared/constants/OrderSpeedUp';

const useProcessSpeedUpValues = () => {
  const { prices } = useContext(AppContext);
  const [selectedVariant, setSelectedVariant] = useState(1);
  const { t } = useScopedTranslation('order.speed_up');
  const orderDeadline = useGetOrderDeadline();

  const speedUpValues = useMemo<Array<{ label: string; price: number; value: number }>>(
    () => [
      {
        value: OrderSpeedUp.oneDay,
        label: t('1_day'),
        price: 23,
      },
      {
        value: OrderSpeedUp.twoDays,
        label: t('2_days'),
        price: 15,
      },
    ],
    [prices],
  );

  const isOrderCanBeSpedUp = (speedUpDays: number) =>
    isBefore(addDays(new Date(), speedUpDays), orderDeadline);

  const variants = useMemo(
    () =>
      speedUpValues.map(({ value, label }) => ({
        value,
        label,
        isDisabled: !isOrderCanBeSpedUp(value),
      })),
    [orderDeadline, speedUpValues],
  );

  useEffect(() => setSelectedVariant(variants[0]?.value), [variants]);

  const selectedPrice = useMemo(
    () => speedUpValues.find((price) => selectedVariant === price.value)?.price,
    [prices, selectedVariant],
  );

  const isAbleToSpeedUp = useMemo(
    () => !!variants?.filter((variant) => !variant.isDisabled)?.length,
    [variants],
  );

  return { variants, selectedPrice, selectedVariant, setSelectedVariant, isAbleToSpeedUp };
};

export default useProcessSpeedUpValues;
