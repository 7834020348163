import { Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Button } from '../../ui';

export const StyledPageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.white};
`;

export const StyledExceptionContainer = styled.div`
  padding: 1.25rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1;

  img {
    margin-bottom: 2rem;
    margin-left: -3rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 100vh;
  }
`;

export const StyledHeadersContainer = styled.div`
  padding-bottom: 1.25rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex: 1 1;
  }
`;

export const StyledHeader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xl3};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  margin-inline: auto;
`;

export const StyledText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};
  line-height: 1.25rem;
  text-align: center;
  margin-inline: auto;
  max-width: 18rem;
  margin-bottom: 2.5rem;
`;

export const StyledButton = styled(Button)`
  margin-inline: auto;
  width: 9.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 100%;
  }
`;
