export enum RootPaths {
  landing = '',
}

export enum Paths {
  funnel = `/app`,
  order = `/app/orders/:orderId`,
  orderOptions = `/app/orders/:orderId/options`,
  orderCheckout = `/app/orders/:orderId/checkout`,
  addOptionsCheckout = `/app/orders/:orderId/add-options`,
  orderVideoEditor = `/app/orders/:orderId/video`,
  orderVideoEditorSongSelect = `/app/orders/:orderId/video/select-song`,
  orderPostcard = `/app/orders/:orderId/postcard`,
  orderPostcardEditorSongSelect = `/app/orders/:orderId/postcard/select-song`,
  orderPostcardEditorForm = `/app/orders/:orderId/postcard/edit`,
  orderClientVoiceEditor = `/app/orders/:orderId/voice`,
  orderClientVoiceEditorRecorder = `/app/orders/:orderId/voice/record`,
  paymentSuccessful = `/app/payments/success`,
}
