import React from 'react';
import {
  StyledBurgerButton,
  StyledHeaderBg,
  StyledHeaderWrapper,
  StyledLinkButton,
  StyledNavigationItem,
  StyledNavigationWrapper,
  StyledNavText,
} from './styled';
import LandingLogo from '../../../theme/assets/img/logo.svg';
import { ButtonSizes } from '../../../theme';
import { Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Paths, RootPaths } from '../../../../router/routes';
import { useScopedTranslation, useSmoothScroll } from '../../../hooks';
import { Icon } from '../../icons';
import MobileMenuModal from '../../../../modules/landing/pages/Landing/components/Menu/MobileMenuModal';

const LandingHeader = () => {
  const { t } = useScopedTranslation('landing.header');
  useSmoothScroll();

  return (
    <StyledHeaderBg>
      <StyledHeaderWrapper>
        <Link to={RootPaths.landing}>
          <img src={LandingLogo} alt={'logo'} />
        </Link>

        <StyledNavText>{t('gifts')}</StyledNavText>

        <StyledNavigationWrapper>
          <StyledNavigationItem href="#how">{t('how')}</StyledNavigationItem>
          <StyledNavigationItem href="#why">{t('why')}</StyledNavigationItem>
          <StyledNavigationItem href="#options">{t('extra')}</StyledNavigationItem>
          <StyledNavigationItem href="#artists">{t('artists')}</StyledNavigationItem>
          <StyledNavigationItem href="#faq">{t('faq')}</StyledNavigationItem>
        </StyledNavigationWrapper>

        <Flex justifyContent={'flex-end'}>
          <StyledLinkButton to={Paths.funnel} size={ButtonSizes.sm} noShadow color={'primary'}>
            {t('try_it_free')}
          </StyledLinkButton>
          <MobileMenuModal
            activator={
              <StyledBurgerButton size={ButtonSizes.sm} noShadow>
                <Icon type={'Menu'} />
              </StyledBurgerButton>
            }
          />
        </Flex>
      </StyledHeaderWrapper>
    </StyledHeaderBg>
  );
};

export default LandingHeader;
