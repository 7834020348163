import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ButtonGroupTypes } from './types';
import { scrollbar } from '../../../theme';

export const StyledButtonGroup = styled.div<{ type: ButtonGroupTypes }>`
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;

  ${({ type }) =>
    (type === ButtonGroupTypes.sidescroll &&
      css`
        max-width: 100%;
        overflow-x: auto;
        ${scrollbar}
      `) ||
    css`
      flex-wrap: wrap;

      > button {
        min-width: 4rem;
      }
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
  ${({ type }) =>
    type !== ButtonGroupTypes.sidescroll &&
    css`
      > button {
        flex: 1 1;
      }
    `}
  }
}

@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
  ${({ type }) =>
    type === ButtonGroupTypes.sidescroll &&
    css`
      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    `}
}
`;
