import styled from '@emotion/styled';
import { Modal } from '../../ui/Modal';

export const StyledButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  width: 100%;
`;

export const StyledModal = styled(Modal)``;
