import React from 'react';
import Offer from './components/Offer/Offer';
import HowItWorks from './components/HowItWorks/HowItWorks';
import Replies from './components/Replies/Replies';
import Options from './components/Options/Options';
import Occasions from './components/Occasions/Occasions';
import Artists from './components/Artists/Artists';
import Postcard from './components/Postcard/Postcard';
import FAQ from '../../../../shared/components/custom/FAQ/FAQ';
import BottomOffer from './components/BottomOffer/BottomOffer';

const Landing = () => {
  return (
    <>
      <Offer />
      <HowItWorks />
      <Replies />
      <Options />
      <Occasions />
      <Artists />
      <Postcard />
      <FAQ />
      <BottomOffer />
    </>
  );
};

export default Landing;
