import React, { useEffect, useMemo } from 'react';
import { useScopedTranslation } from '../../../../shared/hooks';
import { Card, Select, Switch } from '../../../../shared/components/ui';
import { Icon } from '../../../../shared/components/icons';
import { FieldErrorsImpl } from 'react-hook-form';
import { Grid } from '@chakra-ui/react';
import useLoadYears from '../../requests/useLoadYears';
import { getYear } from 'date-fns';

type ITogether = {
  register: Function;
  errors: FieldErrorsImpl;
  isDisabled?: boolean;
};

const Together = ({ register, errors, isDisabled = false }: ITogether) => {
  const { t, rootT } = useScopedTranslation('funnel.personal_facts');

  const { years, getYears } = useLoadYears();

  useEffect(() => {
    getYears();
  }, []);

  const options = useMemo(() => {
    const now = getYear(new Date());
    return years
      .filter((year) => parseInt(year.name, 10) <= now)
      .map((year) => ({ value: year.name, label: year.name }));
  }, [years]);

  return (
    <Card title={t('together')} icon={<Icon type={'Time'} color={'primary'} />}>
      <Grid gap={'1rem'}>
        <Select
          {...{
            register,
            errors,
            name: 'together',
            isDisabled,
            options,
            placeholder: t('select_together'),
          }}
        />
        <Switch {...{ register, name: 'togetherDisabled' }}>{rootT('funnel.dont_mention')}</Switch>
      </Grid>
    </Card>
  );
};

export default Together;
