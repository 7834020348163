import React from 'react';
import {
  StyledLargeReviewText,
  StyledReviewAuthor,
  StyledReviewCard,
  StyledReviewContentContainer,
} from './styled';
import { VideoPlayer } from '../../ui';
import { useScopedTranslation } from '../../../hooks';

const OrderReview4 = () => {
  const { t } = useScopedTranslation('order');

  return (
    <StyledReviewCard bordered>
      <VideoPlayer src={'https://media.w3.org/2010/05/sintel/trailer.mp4'} />
      <StyledReviewContentContainer>
        <StyledLargeReviewText>{t('review_4_text')}</StyledLargeReviewText>
        <StyledReviewAuthor>{t('review_4_author')}</StyledReviewAuthor>
      </StyledReviewContentContainer>
    </StyledReviewCard>
  );
};

export default OrderReview4;
