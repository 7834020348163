import { useState } from 'react';
import { useNavigateWithParams } from '../../../shared/hooks';
import { Paths } from '../../../router/routes';
import { OrderApi } from '../../../api';

const useOrderSpeedUp = () => {
  const { fullPathWithParams, fullPathWithSearchParams } = useNavigateWithParams();
  const [isLoading, setIsLoading] = useState(false);

  const speedUpOrder = async ({
    orderId,
    orderNumber,
    isFast,
  }: {
    orderId: string;
    orderNumber: string;
    isFast: number;
  }) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);

      await OrderApi.createSpeedUpPayment(orderId, {
        speed_up: isFast,
        success_url: fullPathWithSearchParams(Paths.paymentSuccessful, { orderId, orderNumber }),
        cancel_url: fullPathWithParams(Paths.order, { orderId }),
      })
        .then((response) => {
          const { payment_url: url } = response;
          window.location.href = url;
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  return { speedUpOrder, isLoading };
};

export default useOrderSpeedUp;
