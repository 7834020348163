/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgPause = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3175_9776)">
      <path
        d="M35.9625 6.38887C35.8932 5.11774 34.8823 4.10693 33.6111 4.03755C31.0463 3.89743 26.0273 4.03374 21.228 6.51605C18.7815 7.78155 16.194 9.92712 14.129 12.4026C14.1036 12.4331 14.0788 12.4638 14.0536 12.4944L9.36733 12.8564C8.59595 12.916 7.90889 13.3203 7.48227 13.9656L4.28514 18.8013C3.96158 19.2907 3.91014 19.9031 4.14745 20.4396C4.38483 20.9761 4.87258 21.3499 5.45227 21.4397L9.40045 22.0512C9.38652 22.128 9.37252 22.2047 9.3597 22.2814C9.22827 23.0677 9.48877 23.8761 10.0566 24.4439L15.5561 29.9434C16.027 30.4142 16.6631 30.6738 17.315 30.6738C17.4493 30.6738 17.5843 30.6627 17.7186 30.6403C17.7954 30.6275 17.8721 30.6135 17.9488 30.5996L18.5603 34.5478C18.6501 35.1275 19.024 35.6153 19.5604 35.8526C19.7836 35.9514 20.0198 36.0001 20.2549 36.0001C20.5851 36.0001 20.913 35.9039 21.1987 35.715L26.0345 32.5178C26.6798 32.0911 27.084 31.4041 27.1435 30.6327L27.5055 25.9466C27.5361 25.9213 27.5668 25.8966 27.5973 25.8711C30.0729 23.8062 32.2185 21.2187 33.484 18.7721C35.9664 13.9726 36.1025 8.95356 35.9625 6.38887ZM25.0004 30.9537L20.3747 34.012L19.7787 30.1642C21.7337 29.5894 23.6676 28.6687 25.5101 27.4354L25.2743 30.4882C25.2596 30.6787 25.1597 30.8484 25.0004 30.9537ZM16.882 28.6176L11.3824 23.1181C11.2421 22.9777 11.1772 22.7805 11.209 22.5906C11.3725 21.6129 11.6266 20.6822 11.9411 19.8054L20.1928 28.0572C19.1398 28.4344 18.1966 28.6594 17.4095 28.791C17.2192 28.8226 17.0223 28.7579 16.882 28.6176ZM9.51177 14.7258L12.5645 14.49C11.3311 16.3324 10.4105 18.2664 9.8357 20.2214L5.98795 19.6254L9.04633 14.9997C9.1517 14.8403 9.32139 14.7405 9.51177 14.7258ZM26.3965 24.4312C24.8819 25.6945 23.4117 26.6082 22.0566 27.2694L12.7303 17.9431C13.5964 16.1741 14.6535 14.7011 15.5688 13.6037C17.4798 11.3127 19.8565 9.33643 22.0895 8.18143C26.4941 5.90312 31.1348 5.78043 33.5091 5.90968C33.8233 5.9268 34.0733 6.17674 34.0904 6.49105C34.2198 8.8653 34.0969 13.5059 31.8186 17.9106C30.6636 20.1436 28.6873 22.5202 26.3965 24.4312Z"
        fill="#6097FB"
      />
      <path
        d="M25.9198 18.7653C27.1199 18.7652 28.3205 18.3083 29.2342 17.3946C30.1196 16.5093 30.6071 15.3322 30.6071 14.0802C30.6071 12.8282 30.1195 11.6511 29.2342 10.7657C27.4066 8.93805 24.4329 8.93818 22.6054 10.7657C21.72 11.6511 21.2324 12.8282 21.2324 14.0802C21.2324 15.3322 21.72 16.5093 22.6054 17.3946C23.5192 18.3085 24.7192 18.7654 25.9198 18.7653ZM23.931 12.0915C24.4793 11.5432 25.1995 11.2691 25.9197 11.2691C26.6399 11.2691 27.3601 11.5432 27.9084 12.0915C28.4395 12.6227 28.732 13.3289 28.732 14.0801C28.732 14.8313 28.4395 15.5376 27.9084 16.0688C26.8119 17.1654 25.0275 17.1653 23.931 16.0688C23.3998 15.5376 23.1072 14.8314 23.1072 14.0802C23.1072 13.329 23.3999 12.6227 23.931 12.0915Z"
        fill="#6097FB"
      />
      <path
        d="M4.95602 30.3711C5.19596 30.3711 5.4359 30.2796 5.6189 30.0965L8.67971 27.0357C9.04584 26.6696 9.04584 26.076 8.67971 25.7099C8.31365 25.3438 7.72002 25.3438 7.3539 25.7099L4.29315 28.7707C3.92702 29.1368 3.92702 29.7304 4.29315 30.0965C4.47615 30.2796 4.71609 30.3711 4.95602 30.3711Z"
        fill="#6097FB"
      />
      <path
        d="M11.485 28.5148C11.119 28.1487 10.5253 28.1487 10.1592 28.5148L4.27459 34.3995C3.90847 34.7656 3.90847 35.3592 4.27459 35.7253C4.45766 35.9084 4.69753 35.9999 4.93747 35.9999C5.17741 35.9999 5.41734 35.9084 5.60034 35.7253L11.485 29.8406C11.8512 29.4745 11.8512 28.881 11.485 28.5148Z"
        fill="#6097FB"
      />
      <path
        d="M12.9643 31.32L9.9035 34.3808C9.53738 34.7469 9.53738 35.3405 9.9035 35.7066C10.0866 35.8897 10.3265 35.9813 10.5664 35.9813C10.8062 35.9813 11.0462 35.8898 11.2292 35.7066L14.2901 32.6458C14.6562 32.2797 14.6562 31.6861 14.2901 31.32C13.924 30.9539 13.3304 30.9539 12.9643 31.32Z"
        fill="#6097FB"
      />
    </g>
    <defs>
      <clipPath id="clip0_3175_9776">
        <rect width="32" height="32" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgPause;
