import * as yup from 'yup';

export const GeneralInformationSchema = yup.object({
  recipient: yup.string().required().nullable(),
  occasion: yup.string().required().nullable(),
  date: yup.date().required().nullable(),
});

export const PersonalFactsSchema = yup.object({
  eyesColor: yup.string(),
  eyesColorDisabled: yup.boolean(),
  together: yup.string(),
  togetherDisabled: yup.boolean(),
  children: yup.number(),
  childType: yup.string(),
  childrenDisabled: yup.boolean(),
  month: yup.string(),
  monthDisabled: yup.boolean(),
});

export const ContactInformationSchema = yup.object({
  receiver: yup.string().label('Recipient').required().trim(),
  gifter: yup.string().required().trim(),
  email: yup.string().email().required().trim(),
});
