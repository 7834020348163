import { SocialNetworks } from '../../../constants/SocialNetworks';
import InstagramContent from './InstagramContent';
import { ReactNode } from 'react';
import FacebookContent from './FacebookContent';

export const mapSocialButtonContent = (socialNetwork: SocialNetworks): ReactNode =>
  ({
    [SocialNetworks.instagram]: InstagramContent(),
    [SocialNetworks.facebook]: FacebookContent(),
  }[socialNetwork] ?? '');
