import styled from '@emotion/styled';
import { Divider, Grid, Skeleton, Text } from '@chakra-ui/react';
import { Countdown } from '../../../../../shared/components/custom';
import { Icon } from '../../../../../shared/components/icons';

export const StyledWrapper = styled(Grid)`
  grid-gap: 1.5rem;
  align-items: flex-start;
  margin-bottom: 3.25rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 1rem;
  }
`;

export const StyledOrderInfoWrapper = styled(Grid)``;

export const StyledHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl4};
  color: ${({ theme }) => theme.colors.text.darker};
  margin-bottom: 1rem;
  line-height: 3rem;
  max-width: 25rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    line-height: 2.25rem;
    margin-bottom: 0.75rem;
    color: ${({ theme }) => theme.colors.text.main};
    max-width: 17rem;
  }
`;

export const StyledSubheader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  line-height: 2rem;
  margin-bottom: 0.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.md};
    line-height: 1.75rem;
  }
`;

export const StyledOrderId = styled.span`
  color: ${({ theme }) => theme.palette.primary};
`;

export const StyledHint = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.md};
  max-width: 70%;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: 80%;
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;

export const StyledCountdownWrapper = styled(Grid)`
  margin-top: 1.5rem;
  grid-gap: 1.5rem;
  align-items: center;
  grid-template-columns: auto 1fr;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    text-align: center;

    button {
      width: 100%;
    }
  }
`;

export const StyledSpeedupModalWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    order: 1;
  }
`;

export const StyledCounterWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    order: 0;
  }
`;

export const StyledCountdownLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

export const StyledCountdown = styled(Countdown)`
  font-size: ${({ theme }) => theme.fontSize.xl2};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl};
  }
`;

export const StyledDivider = styled(Divider)`
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-color: ${({ theme }) => theme.palette.neutral10};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const StyledSocialsHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.placeholder};
  font-size: ${({ theme }) => theme.fontSize.xl};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`;

export const StyledSocialButtonsWrapper = styled(Grid)`
  margin-top: 1.5rem;
  grid-template-columns: 10rem 10rem;
  grid-gap: 1rem;

  > a {
    min-width: 10rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;

    > a {
      min-width: 100%;
    }
  }
`;

export const StyledArtistWrapper = styled(Grid)`
  > div {
    margin-top: 1rem;
  }
`;

export const StyledArtistHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.xl2};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`;

export const StyledFormText = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.colors.text.main};
  padding-top: 1rem;
`;

export const StyledIcon = styled(Icon)`
  margin: 1.25rem 1.25rem 0.25rem;
`;

export const StyledButtonGroup = styled.div`
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledMobileArtistCard = styled.div`
  margin-top: 0.75rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledDesktopArtistCard = styled.div`
  max-width: 45rem;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }

  > img {
    position: absolute;
    right: -9.5rem;
    top: 1rem;
  }

  > img:last-of-type {
    top: -1rem;
    right: -8.5rem;
  }
`;

export const StyledSocialsSkeleton = styled(Skeleton)`
  width: 23rem;
  margin-top: 8rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 100%;
    margin-top: 1rem;
  }
`;
