import { useState } from 'react';
import { Artist } from '../types/artist';
import { FunnelApi } from '../../api';

const useLoadArtists = () => {
  const [artists, setArtists] = useState<Artist[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getArtists = async ({
    segmentId,
    occasionId,
  }: {
    segmentId?: number | null;
    occasionId?: number | null;
  }) => {
    setIsLoading(true);

    const responseArtists = await FunnelApi.getArtists({
      segment_id: segmentId?.toString() ?? '',
      occasion_id: occasionId?.toString() ?? '',
    })
      .then((response) => {
        setArtists(response);
        return response;
      })
      .finally(() => setIsLoading(false));

    return responseArtists;
  };

  const getAllArtists = async () => {
    setIsLoading(true);

    const responseArtists = await FunnelApi.getAllArtists()
      .then((response) => {
        setArtists(response);
        return response;
      })
      .finally(() => setIsLoading(false));

    return responseArtists;
  };

  return { artists, getArtists, getAllArtists, isLoading };
};

export default useLoadArtists;
