import { Outlet } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { OrderOptions, OrderOptionStatus } from '../../../../shared/constants/OrderOptions';
import { useOrderContext } from '../../hooks';
import useRedirectToOrder from '../../hooks/useRedirectToOrder';
import OrderVideoInWork from './OrderVideoInWork';
import useGetOrderOption from '../../hooks/useGetOrderOption';
import useOrderOptionAvailable from '../../hooks/useOrderOptionAvailable';

const OrderVideoEditor = () => {
  const { order } = useOrderContext();
  const { navigateToOrder } = useRedirectToOrder();
  const currentOption = useGetOrderOption(OrderOptions.video);
  const isAvailable = useOrderOptionAvailable(currentOption);

  const isOptionInWork = useMemo(
    () => currentOption?.status === OrderOptionStatus.processing ?? true,
    [order],
  );

  useEffect(() => {
    if (order && !isAvailable) navigateToOrder();
  }, [order, isAvailable]);

  return (isOptionInWork && <OrderVideoInWork />) || <Outlet />;
};

export default OrderVideoEditor;
