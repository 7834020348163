import { Button } from '../Button';
import styled from '@emotion/styled';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { Icon } from '../../icons';

export const StyledDropzoneButton = styled(Button)`
  width: 100%;
`;

export const StyledDropzoneContainer = styled.div<{ isAccepted: boolean; isRejected: boolean }>`
  padding: 1rem;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%237F85967A' stroke-width='2' stroke-dasharray='14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: ${({ theme }) => theme.radii.md};
  flex: 1 1;
  display: flex;
  flex-direction: column;

  cursor: ${({ isRejected }) => (isRejected && 'not-allowed') || 'inherit'};

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background: ${({ theme }) => theme.palette.neutral5};
    border-radius: ${({ theme }) => theme.radii.sm};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
`;

export const StyledDropzoneInsideWrapper = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: block;
    background: ${({ theme }) => theme.palette.white};
    border-radius: ${({ theme }) => theme.radii.sm};
    flex: unset;
    padding: 2rem;
  }
`;

export const StyledDropzoneHeader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.colors.text.main};
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  text-align: center;

  > span {
    color: ${({ theme }) => theme.palette.primary};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 0;
    text-align: start;
    margin-bottom: 0.5rem;
  }
`;

export const StyledFullHeightContainer = styled(Flex)`
  flex: 1 1;
  flex-direction: column;
`;

export const StyledDropzoneSubHeader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};
  margin-bottom: 1rem;
  text-align: center;
  margin-inline: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    text-align: start;
    margin-inline: unset;
    margin-bottom: 0.5rem;
  }
`;

export const StyledDropzoneIcon = styled(Flex)<{ isAccepted: boolean; isRejected: boolean }>`
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  flex: 1 1;

  svg {
    width: 7.875rem;
    height: auto;
    margin-inline: auto;

    path {
      transition: 0.3s ease-in-out;
      fill: ${({ isAccepted, isRejected, theme }) =>
        (isAccepted && theme.palette.success) || (isRejected && theme.palette.error) || '#bcbfc1'};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-height: 8.75rem;
  }
`;

export const StyledFilesGrid = styled(Grid)`
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: repeat(8, 1fr);
    max-width: 45rem;
  }
`;

export const StyledFileCard = styled(Box)`
  position: relative;
  aspect-ratio: 1 / 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 4.75rem;
  }
`;

export const StyledFilCardImage = styled.div<{ src: string }>`
  width: 100%;
  height: 100%;
  position: relative;
  background: ${({ theme }) => theme.palette.neutral20};
  border-radius: ${({ theme }) => theme.radii.sm};
  display: flex;
  align-items: center;
  justify-content: center;

  > div:first-of-type {
    background: url('${({ src }) => src}') no-repeat right top;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.radii.sm};
    z-index: 2;
    position: relative;
  }

  > div:last-of-type {
    position: absolute;
    z-index: 1;
  }
`;

export const StyledImagePlaceholder = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.neutral20};
  border-radius: ${({ theme }) => theme.radii.sm};
  aspect-ratio: 1 / 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 4.75rem;
  }
`;

export const StyledErrorImagePlaceholder = styled(StyledImagePlaceholder)`
  color: ${({ theme }) => theme.palette.error};
  font-size: ${({ theme }) => theme.fontSize.xs};
  flex-direction: column;
  text-align: center;
  line-height: 1.2;

  span {
    transition: 0.1s;
  }

  svg {
    width: 1.5rem;
    height: auto;

    path,
    circle {
      stroke: ${({ theme }) => theme.palette.error};
    }
  }

  &:hover span {
    transform: scale(1.1);
  }
`;

export const StyledCounter = styled.div`
  position: absolute;
  top: -0.3rem;
  left: -0.3rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.white};
  font-size: ${({ theme }) => theme.fontSize.xs};
  border-radius: 100%;
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  z-index: 3;
`;

export const StyledRemoveIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.24));
  cursor: pointer;
  transition: 0.1s;
  padding: 0.4rem;
  width: 1.55rem;
  height: 1.55rem;
  z-index: 3;

  &:hover {
    transform: scale(1.2);
  }
`;
