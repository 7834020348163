import React from 'react';
import { StyledFAQBg } from './styled';
import { StyledContainer } from '../../../../modules/landing/pages/Landing/styled';
import FAQImage from '../../../theme/assets/img/landing/faq.png';
import FAQTabs from './components/FAQTabs';

const FAQ = () => {
  return (
    <StyledFAQBg id={'faq'}>
      <img src={FAQImage} alt={''} />
      <StyledContainer>
        <FAQTabs />
      </StyledContainer>
    </StyledFAQBg>
  );
};

export default FAQ;
