import React, { useMemo } from 'react';
import {
  StyledCarousel,
  StyledPreloaderArtistImageWrapper,
  StyledPreloaderText,
  StyledPreloaderWrapper,
  StyledSelectedArtistPreloader,
} from './styled';
import useFunnelContext from '../../hooks/useFunnelContext';
import { useHandleBackgroundColor, useScopedTranslation } from '../../../../shared/hooks';
import { FunnelWrapper } from '../../styled';
import { Avatar } from '@chakra-ui/react';

const Preloader = () => {
  const { t } = useScopedTranslation('order');
  useHandleBackgroundColor();

  const {
    parameters: { artistId },
    values: { artists },
  } = useFunnelContext();

  const selectedArtist = useMemo(() => {
    return artists.find((artist) => artist.id === artistId);
  }, [artistId, artists]);

  const moreArtists = [...artists, ...artists, ...artists];

  return (
    <FunnelWrapper>
      <StyledPreloaderWrapper>
        {(!!moreArtists.length && !selectedArtist && (
          <StyledCarousel
            showIndicators={false}
            swipeable={false}
            autoPlay={true}
            centerMode={true}
            interval={1000}
            variableWidth={true}
          >
            {moreArtists?.map((artist) => (
              <div key={artist?.id}>
                <StyledPreloaderArtistImageWrapper>
                  <Avatar src={artist?.photo_url_thumb ?? artist?.photo_url} name={artist?.name} />
                </StyledPreloaderArtistImageWrapper>
              </div>
            ))}
          </StyledCarousel>
        )) ||
          (selectedArtist && (
            <StyledSelectedArtistPreloader>
              <Avatar
                src={selectedArtist?.photo_url_thumb ?? selectedArtist?.photo_url}
                name={selectedArtist?.name}
              />
            </StyledSelectedArtistPreloader>
          ))}
        <StyledPreloaderText>
          {t((selectedArtist && 'processing_order') || 'looking_for_artist')}
        </StyledPreloaderText>
      </StyledPreloaderWrapper>
    </FunnelWrapper>
  );
};

export default Preloader;
