import React, { useMemo } from 'react';
import VoiceOptionCard from './components/VoiceOptionCard';
import { StyledCardPlaceholder, StyledOptionCardsWrapper, StyledSectionHeader } from './styled';
import { OrderOption } from '../../../../../shared/types/orderOption';
import { OrderOptions } from '../../../../../shared/constants/OrderOptions';
import SlideOptionCard from './components/SlideOptionCard';
import PostCard from './components/PostCard';
import DuetOptionCard from './components/DuetOptionCard';
import { useScopedTranslation } from '../../../../../shared/hooks';
import useGetAvailableOrderOptions from '../../../hooks/useGetAvailableOrderOptions';

const mappedOptionCard = (option: OrderOption) => {
  const optionName = option.name as OrderOptions;
  const elements: Record<OrderOptions, React.ReactNode> = {
    [OrderOptions.clientVoice]: <VoiceOptionCard key={option.id} option={option} />,
    [OrderOptions.duet]: <DuetOptionCard key={option.id} option={option} />,
    [OrderOptions.video]: <SlideOptionCard key={option.id} option={option} />,
  };
  return elements[optionName];
};

const OrderUploadOptions = () => {
  const { t } = useScopedTranslation('order');
  const options = useGetAvailableOrderOptions();
  const placeholderNum = useMemo(() => 3 - options.length, [options]);

  return (
    <>
      {!!options?.length && <StyledSectionHeader>{t('upload_content')}</StyledSectionHeader>}
      <StyledOptionCardsWrapper>
        {options?.map((option: OrderOption) => mappedOptionCard(option))}
        <PostCard />
        {Array.from(Array(placeholderNum), (e, i) => (
          <StyledCardPlaceholder key={`skel-${i}`} />
        ))}
      </StyledOptionCardsWrapper>
    </>
  );
};

export default OrderUploadOptions;
