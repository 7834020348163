import { useState } from 'react';
import { FunnelApi } from '../../../api';
import { Personalization } from '../../../shared/types/personalization';

const useLoadOccasions = () => {
  const [occasions, setOccasions] = useState<Personalization[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getOccasions = async (segment: number) => {
    setIsLoading(true);

    const resp = await FunnelApi.getOccasions(segment)
      .then((response) => {
        setOccasions(response);
        return response;
      })
      .finally(() => setIsLoading(false));

    return resp;
  };

  return { occasions, getOccasions, isLoading };
};

export default useLoadOccasions;
