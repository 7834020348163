import { useMemo } from 'react';
import { formatDuration, intervalToDuration, isAfter } from 'date-fns';
import { useScopedTranslation } from './useScopedTranslation';

export const useTimeLeft = (deadlineDate: Date, startDate: Date | null = null) => {
  const { t } = useScopedTranslation('general');

  return useMemo(() => {
    const start = startDate || new Date();
    const fallback = t('a_few_moments');

    if (isAfter(start, deadlineDate)) return fallback;

    const duration = intervalToDuration({
      start,
      end: deadlineDate,
    });

    const daysLeft = formatDuration(duration, { format: ['days'] });
    if (daysLeft) return daysLeft;

    const hoursLeft = formatDuration(duration, { format: ['hours'] });
    if (hoursLeft) return hoursLeft;

    const minutesLeft = formatDuration(duration, { format: ['minutes'] });
    if (minutesLeft) return minutesLeft;

    return fallback;
  }, [startDate, deadlineDate]);
};
