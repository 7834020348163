import { useMemo, useState } from 'react';
import { differenceInSeconds, parseISO } from 'date-fns';
import { OrderOption } from '../../../../../../shared/types/orderOption';
import { OrderOptionStatus } from '../../../../../../shared/constants/OrderOptions';
import { useTimeLeft } from '../../../../../../shared/hooks';

const useProcessOptionDeadline = (option: OrderOption) => {
  const [defaultDate] = useState(new Date());
  const { deadline_at: deadlineAt, start_at: startedAt, status } = option;

  const isInProgress = useMemo(
    () => [OrderOptionStatus.processing, OrderOptionStatus.generate].includes(status),
    [status],
  );
  const isFinished = useMemo(() => status === OrderOptionStatus.finished, [status]);
  const isWaitingForPayment = useMemo(
    () => status === OrderOptionStatus.waitingForPayment,
    [status],
  );

  const startedAtDate = useMemo(
    () => (startedAt && parseISO(startedAt)) || defaultDate,
    [startedAt, defaultDate],
  );
  const deadlineAtDate = useMemo(
    () => (deadlineAt && parseISO(deadlineAt)) || defaultDate,
    [deadlineAt, defaultDate],
  );

  const totalTimeUnix = useMemo(
    () => differenceInSeconds(deadlineAtDate, startedAtDate),
    [startedAtDate, deadlineAtDate],
  );

  const completion = useMemo(() => {
    const timePassedUnix = differenceInSeconds(new Date(), startedAtDate);
    return (timePassedUnix / totalTimeUnix) * 100;
  }, [totalTimeUnix]);

  const totalTimeDays = useTimeLeft(deadlineAtDate);

  return { completion, isInProgress, isFinished, isWaitingForPayment, totalTimeDays };
};

export default useProcessOptionDeadline;
