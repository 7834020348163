import { OrderOptions } from '../constants/OrderOptions';
import { useEffect, useState } from 'react';
import { appLocalStorage } from '../utils/appLocalStorage';
import { LocalStorageKeys } from '../constants/LocalStorageKeys';

export const useToggleOrderOption = (
  option: OrderOptions,
  storageKey: LocalStorageKeys = LocalStorageKeys.orderOptions,
) => {
  const [isSelected, setIsSelected] = useState(false);

  const localOptions = () => appLocalStorage.getItem(storageKey) ?? [];

  useEffect(() => {
    setIsSelected(localOptions().includes(option));
  }, []);

  const toggleOption = () => {
    const options = (!Array.isArray(localOptions()) && []) || localOptions();
    appLocalStorage.setItem(
      storageKey,
      (options.includes(option) && options.filter((opt: OrderOptions) => opt !== option)) || [
        ...options,
        option,
      ],
    );
    setIsSelected(!isSelected);
  };

  return { isSelected, toggleOption };
};
