import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  showToast,
} from '../../../../../shared/components/ui';
import { Skeleton, useDisclosure } from '@chakra-ui/react';
import { StyledButtonGroup, StyledFormText, StyledIcon } from './styled';
import useOrderSpeedUp from '../../../requests/useOrderSpeedUp';
import { TOAST_SUCCESS } from '../../../../../shared/constants';
import { useScopedTranslation } from '../../../../../shared/hooks';
import { useOrderContext } from '../../../hooks';
import useProcessSpeedUpValues from './hooks/useProcessSpeedUpValues';
import { ButtonGroup } from '../../../../../shared/components/custom';

const OrderSpeedupModal = ({ activator }: { activator: React.ReactNode }) => {
  const { order } = useOrderContext();
  const { t } = useScopedTranslation('order.speed_up');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { speedUpOrder, isLoading } = useOrderSpeedUp();
  const { variants, selectedPrice, selectedVariant, setSelectedVariant } =
    useProcessSpeedUpValues();

  const speedUp = () => {
    if (order) {
      const { id: orderNumber, uuid: orderId } = order;
      speedUpOrder({ orderId, orderNumber: orderNumber.toString(), isFast: selectedVariant }).then(
        () => {
          showToast(TOAST_SUCCESS, 'Order was successfully sped up');
          onClose();
        },
      );
    }
  };

  return (
    <>
      {activator && (
        <div className={'activator'} onClick={onOpen}>
          {activator}
        </div>
      )}

      <Modal {...{ isOpen, onOpen, onClose, size: 'xs' }}>
        <>
          <StyledIcon type={'Rocket'} />
          <ModalHeader>{t('title')}</ModalHeader>
          <ModalBody>
            <StyledButtonGroup>
              <ButtonGroup
                options={variants}
                defaultValue={selectedVariant}
                onChange={setSelectedVariant}
              />
            </StyledButtonGroup>
            {(selectedPrice && <StyledFormText>${selectedPrice}</StyledFormText>) || (
              <Skeleton w={'3rem'} h={'2rem'} mt={'1rem'} />
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={speedUp}
              isLoading={isLoading || !selectedPrice}
              noShadow
              block
              type={'button'}
              color={'primary'}
            >
              {t('action')}
            </Button>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};

export default OrderSpeedupModal;
