import { useContext, useEffect } from 'react';
import { AppContext } from '../context';
import { designSystem } from '../theme';

export const useHandleBackgroundColor = (color: string = designSystem.palette.neutral5) => {
  const { setBackground } = useContext(AppContext);

  useEffect(() => {
    setBackground(color);
  }, [color]);

  return setBackground;
};
