import React from 'react';
import {
  StyledArtistCard,
  StyledArtistDescription,
  StyledArtistExampleWrapper,
  StyledArtistInfo,
  StyledHeaderWrapper,
  StyledArtistStat,
  StyledStatGrid,
} from './styled';
import { Icon } from '../../icons';
import { Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';

const CardArtistSkeleton = ({ children, ...props }: { children?: React.ReactNode }) => {
  return (
    <StyledArtistCard {...{ ...props, bordered: true }}>
      <StyledHeaderWrapper>
        <SkeletonCircle w={'4.5rem'} height={'4.5rem'} />
        <StyledArtistInfo>
          <Skeleton w={'100%'} height={'2rem'} mb={'0.5rem'} />
          <StyledStatGrid>
            <StyledArtistStat>
              <Icon type={'Star'} color={'primary'} />
              <Skeleton w={'4rem'} height={'1rem'} />
            </StyledArtistStat>
            <StyledArtistStat>
              <Icon type={'Music2'} color={'primary'} />
              <Skeleton w={'4rem'} height={'1rem'} />
            </StyledArtistStat>
          </StyledStatGrid>
        </StyledArtistInfo>
      </StyledHeaderWrapper>
      <StyledArtistDescription>
        <Skeleton w={'100%'} height={'5rem'} />
      </StyledArtistDescription>
      <StyledArtistExampleWrapper>
        <Flex gap={'1rem'}>
          <SkeletonCircle w={'2.5rem'} height={'2rem'} />
          <Skeleton w={'100%'} height={'2rem'} />
        </Flex>
      </StyledArtistExampleWrapper>
      {children}
    </StyledArtistCard>
  );
};

export default CardArtistSkeleton;
