import styled from '@emotion/styled';
import { Box } from '@chakra-ui/react';

export const StyledContentContainer = styled(Box)`
  width: 100%;
  position: relative;
  margin-inline: auto;
  padding: ${({ fluid }) => (!fluid && '1.25rem 1.25rem') || 0};
  max-width: ${({ theme, fluid }) => (fluid && '100%') || theme.breakpoints.xl};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: ${({ fluid }) => (!fluid && '1.25rem 1.25rem') || 0};
  }
`;
