import React from 'react';
import { Icon } from '../../../../shared/components/icons';
import { Card, Switch } from '../../../../shared/components/ui';
import { useGetMonths, useScopedTranslation } from '../../../../shared/hooks';
import { ButtonGroup } from '../../../../shared/components/custom';
import { ButtonGroupTypes } from '../../../../shared/components/custom/ButtonGroup/types';
import { Control, Controller } from 'react-hook-form';
import { Grid } from '@chakra-ui/react';
import useFunnelContext from '../../hooks/useFunnelContext';

type IMonthOfDating = {
  control: Control;
  register: Function;
  isDisabled?: boolean;
};

const MonthOfDating = ({ control, register, isDisabled = false }: IMonthOfDating) => {
  const { t, rootT } = useScopedTranslation('funnel.personal_facts');

  const {
    parameters: { month },
  } = useFunnelContext();

  const months = useGetMonths('LLL');
  const defaultValue = month ?? months[0]?.value;

  return (
    <Card title={t('month')} icon={<Icon type={'Calendar'} color={'primary'} />}>
      <Grid gap={'1rem'}>
        <Controller
          control={control}
          name="month"
          render={({ field }) => (
            <ButtonGroup
              isDisabled={isDisabled}
              type={ButtonGroupTypes.stack}
              defaultValue={defaultValue}
              onChange={field.onChange}
              options={months}
            />
          )}
        />
        <Switch {...{ register, name: 'monthDisabled' }}>{rootT('funnel.dont_mention')}</Switch>
      </Grid>
    </Card>
  );
};

export default MonthOfDating;
