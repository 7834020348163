import styled from '@emotion/styled';
import { Box, Text } from '@chakra-ui/react';

export const StyledPromocodeCard = styled(Box)`
  filter: drop-shadow(0px 12.5236px 25.0471px rgba(134, 102, 185, 0.24));
  background: ${({ theme }) => theme.palette.primary};
  border-radius: ${({ theme }) => theme.radii.sm};
  position: relative;
  margin-top: 12rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 10rem;
  }
`;

export const StyledPromocodeCardImageContainer = styled.div`
  position: absolute;
  top: -6.4rem;
  width: calc(100% + 2rem);
  left: -1rem;

  > img {
    margin-inline: auto;
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    top: -7.3rem;
    max-width: 30rem;
    right: 3rem;
    left: unset;
  }
`;

export const StyledPromocodeHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl2};
  color: ${({ theme }) => theme.palette.white};
  line-height: 2rem;
  text-align: center;
  margin-inline: auto;
  padding-top: 7.125rem;
  max-width: 17rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    text-align: start;
    margin: unset;
    padding-inline: 2rem;
    padding-top: 2rem;
    white-space: pre-line;
    max-width: unset;
  }
`;

export const StyledPromocodeCodeWrapper = styled.div`
  > div {
    display: flex;
    width: fit-content;
    align-items: center;
    grid-gap: 0.75rem;
    padding-inline: 2rem;
  }

  padding-bottom: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    > div {
      margin-inline: auto;
    }
  }

  svg g {
    transition: 0.2s ease-in-out;
  }

  &:hover {
    svg g {
      opacity: 1;
    }
  }
`;

export const StyledPromocodeCode = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.palette.white};
  letter-spacing: 0.12rem;
  text-transform: uppercase;
  line-height: 1.75rem;
`;

export const StyledPromocodeDividerContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;

  > div:first-of-type {
    left: -0.625rem;
  }

  > div:last-of-type {
    right: -0.625rem;
  }
`;

export const StyledPromocodeDivider = styled.div`
  background-image: linear-gradient(to right, #3b5d9c 33%, rgba(255, 255, 255, 0) 0%);
  //background-image: linear-gradient(to right, #9c3b3b 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 18px 1px;
  background-repeat: repeat-x;
  height: 1px;
`;

export const StyledPromocodeDividerCircle = styled.div`
  background: #eaf4ff;
  border-radius: 100%;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: -0.625rem;
`;

export const StyledPromocodeValidity = styled(Text)`
  margin-top: 1rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.placeholder};
  line-height: 1.25rem;
  margin-bottom: 3rem;
`;
