import { useEffect } from 'react';
import useLoadSegments from '../../../requests/useLoadSegments';
import useLoadOccasions from '../../../requests/useLoadOccasions';
import useFunnelContext from '../../../hooks/useFunnelContext';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GeneralInformationSchema } from '../../../validationSchema';

const useProcessValues = () => {
  const { parameters, values, setValues } = useFunnelContext();
  const { recipients: recipientsValues, occasions: occasionsValues } = values;

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(GeneralInformationSchema),
  });

  const recipient = watch('recipient');

  const { getSegments } = useLoadSegments();
  const { getOccasions } = useLoadOccasions();

  const resetValues = async () => {
    const recipients = (!!recipientsValues.length && recipientsValues) || (await getSegments());
    setValues({ ...values, recipients });
    reset({ ...parameters });
  };

  useEffect(() => {
    resetValues();
  }, []);

  const resetOccasion = async () => {
    const segment = recipient ?? parameters.recipient;
    if (segment) {
      const isTheSame =
        !!occasionsValues.length &&
        ((!recipient && !!parameters.recipient) || recipient === parameters.recipient);
      const occasions = (isTheSame && occasionsValues) || (await getOccasions(segment));

      setValues({ ...values, occasions });
      if (!isTheSame) reset({ ...getValues(), occasion: null });
    } else {
      setValues({ ...values, occasions: [] });
    }
  };

  useEffect(() => {
    resetOccasion();
  }, [recipient]);

  return { register, control, handleSubmit, errors };
};

export default useProcessValues;
