import React from 'react';
import {
  StyledAccordionIconBg,
  StyledAccordionItem,
  StyledFAQHeader,
  StyledFAQWrapper,
} from '../styled';
import { items } from '../data';
import { Accordion, AccordionButton, AccordionIcon, AccordionPanel, Box } from '@chakra-ui/react';
import { useScopedTranslation } from '../../../../hooks';

const FaqTabs = () => {
  const { t } = useScopedTranslation('landing.faq');

  return (
    <>
      <StyledFAQHeader>{t('header')}</StyledFAQHeader>
      <StyledFAQWrapper>
        {items.map((tab, key) => (
          <div key={`tab-${key}`}>
            <Accordion allowToggle>
              {tab.map((item) => (
                <StyledAccordionItem key={item.title}>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {item.title}
                      </Box>
                      <StyledAccordionIconBg>
                        <AccordionIcon />
                      </StyledAccordionIconBg>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>{item.content}</AccordionPanel>
                </StyledAccordionItem>
              ))}
            </Accordion>
          </div>
        ))}
      </StyledFAQWrapper>
    </>
  );
};

export default FaqTabs;
