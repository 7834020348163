import styled from '@emotion/styled';
import { Grid, Text } from '@chakra-ui/react';
import { Button } from '../../../../shared/components/ui';

export const StyledOption = styled(Grid)`
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-gap: 0.75rem;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(229, 226, 235, 0.6);

  > svg:last-of-type {
    width: 1.5rem;
    height: auto;
    opacity: 0.6;
    fill: ${({ theme }) => theme.palette.neutral70};
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledOptionIconContainer = styled.div`
  border-radius: 100%;
  border: 2px solid ${({ theme }) => theme.palette.primary};
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 2rem;
    height: auto;
  }
`;

export const StyledOptionName = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
`;

export const StyledOptionPrice = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.main};
`;

export const StyledRemoveButton = styled(Button)`
  background: transparent;
  padding: 0;
  width: 2rem;
  height: 2rem;

  svg {
    opacity: 0.6;
    width: 1.25rem;
    height: 1.25rem;
  }
`;
