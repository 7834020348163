import { useState } from 'react';
import { Segment } from '../../../shared/types/segment';
import { FunnelApi } from '../../../api';

const useLoadSegments = () => {
  const [segments, setSegments] = useState<Segment[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getSegments = async () => {
    setIsLoading(true);

    const resp = await FunnelApi.getSegments()
      .then((response) => {
        setSegments(response);
        return response;
      })
      .finally(() => setIsLoading(false));
    return resp;
  };

  return { segments, getSegments, isLoading };
};

export default useLoadSegments;
