import React from 'react';
import {
  StyledArtistsHeader,
  StyledPostcardWrapper,
  StyledArtistsSubheader,
  StyledList,
  StyledPhotoWrapper,
  StyledTextWrapper,
} from './styled';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import PostcardImage from '../../../../../../shared/theme/assets/img/landing/postcard.png';

const Postcard = () => {
  const { t } = useScopedTranslation('landing.postcard');

  return (
    <StyledPostcardWrapper>
      <StyledPhotoWrapper>
        <img src={PostcardImage} alt={'postcard'} />
      </StyledPhotoWrapper>
      <StyledTextWrapper>
        <StyledArtistsHeader>
          {t('header')} <span>{t('header_2')}</span> {t('header_3')}
        </StyledArtistsHeader>
        <StyledArtistsSubheader>{t('subheader')}</StyledArtistsSubheader>
        <StyledList>
          <div>
            <div>1</div> {t('upload_photo')}
          </div>
          <div>
            <div>2</div> {t('write_message')}
          </div>
          <div>
            <div>3</div> {t('free')}
          </div>
        </StyledList>
      </StyledTextWrapper>
    </StyledPostcardWrapper>
  );
};

export default Postcard;
