import styled from '@emotion/styled';
import { Flex, Grid, Text } from '@chakra-ui/react';

export const StyledHeader = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl4};
  color: ${({ theme }) => theme.colors.text.darker};
  margin-bottom: 1.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    line-height: 2.25rem;
    max-width: 14rem;
    margin-bottom: 0.75rem;
  }

  > div {
    display: inline-block;
  }
`;

export const StyledPrimaryText = styled.span`
  color: ${({ theme }) => theme.palette.primary};
`;

export const StyledSubheader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xl};
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 1.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: 1.25rem;
    margin-bottom: 1rem;
  }
`;

export const StyledCheckoutOptionsWrapper = styled(Grid)`
  margin-bottom: 1.5rem;
`;

export const StyledButtonWrapper = styled(Flex)`
  justify-content: center;
  margin-bottom: 2rem;

  button {
    width: 9rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-bottom: 1rem;

    button {
      width: 100%;
    }
  }
`;

export const StyledHint = styled(Text)`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.main};
  white-space: pre-line;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-inline: auto;
    max-width: 15rem;
  }
`;

export const StyledAgreement = styled.a`
  color: ${({ theme }) => theme.palette.primary};
`;
