import styled from '@emotion/styled';
import { Flex } from '@chakra-ui/react';
import {
  StyledHeader,
  StyledSubHeader,
} from '../../../../modules/order/views/OrderVideoEditor/styled';

export const StyledContainer = styled.div`
  min-height: calc(100vh - 14rem);
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background: ${({ theme }) => theme.palette.neutral5};
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
`;

export const StyledInWorkWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1;
  margin-top: 5rem;

  img {
    max-width: 16rem;
    margin-bottom: 2rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background: ${({ theme }) => theme.palette.white};
    max-width: 26rem;
    border-radius: ${({ theme }) => theme.radii.sm};
    padding: 2rem;

    img {
      margin-top: -7rem;
      max-width: 22rem;
    }
  }
`;

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1;
`;

export const StyledInWorkHeader = styled(StyledHeader)`
  text-align: center;
  margin-bottom: 1rem;
`;

export const StyledInWorkSubHeader = styled(StyledSubHeader)`
  text-align: center;
`;

export const StyledButtonWrapper = styled.div`
  margin-top: 1rem;
  width: 100%;

  button,
  a {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-inline: auto;

    button,
    a {
      width: unset;
      min-width: 11rem;
    }
  }
`;
