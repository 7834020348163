import React from 'react';
import useProcessOptionDeadline from '../hooks/useProcessOptionDeadline';
import { OrderOption } from '../../../../../../shared/types/orderOption';
import OptionProgress from './OptionProgress';
import { StyledOptionCard, StyledOptionCardHeader, StyledOptionCardIcon } from '../styled';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import { Spacer } from '@chakra-ui/react';
import { Icon } from '../../../../../../shared/components/icons';
import PaymentIsProcessing from './PaymentIsProcessing';
import PaidOverlay from './PaidOverlay';

type IOptionCard = {
  option: OrderOption;
};

const DuetOptionCard = ({ option }: IOptionCard) => {
  const { t } = useScopedTranslation('order.editors.duet');
  const { completion, isInProgress, totalTimeDays, isWaitingForPayment } =
    useProcessOptionDeadline(option);

  return (
    ((isInProgress || isWaitingForPayment) && (
      <StyledOptionCard>
        <PaidOverlay />
        {(isWaitingForPayment && (
          <>
            <StyledOptionCardHeader>{t('header')}</StyledOptionCardHeader>
            <Spacer />
            <StyledOptionCardIcon>
              <Icon type={'Mic'} />
            </StyledOptionCardIcon>
            <Spacer />
            <PaymentIsProcessing />
          </>
        )) || (
          <OptionProgress
            completion={completion}
            icon={'Mic'}
            title={t('processing_header')}
            hint={t('processing_text', { totalTimeDays })}
          />
        )}
      </StyledOptionCard>
    )) || <></>
  );
};

export default DuetOptionCard;
