import { Grid, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Card, Stepper } from '../../shared/components/ui';
import { CardArtist } from '../../shared/components/custom';

export const StyledFunnelContainer = styled.div`
  min-height: calc(100vh - 14.75rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FunnelWrapper = styled.div`
  max-width: 46rem;
  margin-inline: auto;
  width: 100%;
`;

export const StyledStepForm = styled.form``;

export const StyledCustomizationForm = styled.form`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background: ${({ theme }) => theme.palette.white};
    border-radius: ${({ theme }) => theme.radii.sm};
    padding: 2rem;
  }
`;

export const StyledStepHeading = styled.div`
  margin-bottom: 1.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-bottom: 2rem;
  }
`;

export const StyledStepper = styled(Stepper)`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledHeaderWithStepper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledStepHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl4};
  color: ${({ theme }) => theme.colors.text.darker};
  margin-bottom: 0.75rem;
  line-height: 3rem;
  white-space: pre-line;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.xl3};
    line-height: 2.5rem;
  }
`;

export const StyledStepSubheader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.body};
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xl};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    white-space: pre-line;
  }
`;

export const StyledSelectorsWrapper = styled(Grid)`
  grid-row-gap: 0.75rem;
`;

export const StyledStepperContainer = styled.div`
  margin-bottom: 1.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledFunnelActionWrapper = styled(Grid)`
  margin-top: 1.5rem;
  grid-gap: 0.75rem;
`;

export const StyledCustomizationActionsWrapper = styled(StyledFunnelActionWrapper)`
  padding-bottom: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;

    > button:first-of-type {
      order: 1;
    }
  }
`;

export const StyledArtistsWrapper = styled.div`
  position: relative;
  min-height: 12rem;
  margin-top: 3rem;

  img {
    position: absolute;

    &:nth-of-type(1) {
      left: 45%;
    }
    &:nth-of-type(2) {
      right: 15%;
    }
    &:nth-of-type(3) {
      bottom: 10%;
      right: 29%;
    }
    &:nth-of-type(4) {
      left: 25%;
    }
    &:nth-of-type(5) {
      left: 35%;
      bottom: 15%;
    }
    &:nth-of-type(6) {
      left: 25%;
      top: 40%;
    }
    &:nth-of-type(7) {
      bottom: 0;
      left: 47%;
    }
    &:nth-of-type(8) {
      bottom: 30%;
      right: 20%;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      &:nth-of-type(1) {
        left: 35%;
      }
      &:nth-of-type(2) {
        right: 0;
      }
      &:nth-of-type(3) {
        bottom: 10%;
        right: 0;
      }
      &:nth-of-type(4) {
        bottom: 15%;
        left: 0;
      }
      &:nth-of-type(5) {
        left: 25%;
        bottom: 7%;
      }
      &:nth-of-type(6) {
        left: 15%;
        top: 15%;
      }
      &:nth-of-type(7) {
        bottom: 0;
        left: 45%;
      }
      &:nth-of-type(8) {
        bottom: 30%;
        right: 20%;
      }
    }
  }
`;

export const StyledArtistsHeader = styled(Text)`
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xl};
  margin-top: 3rem;
`;

export const StyledArtistsSubheader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.sm};
  text-align: center;
  margin-inline: auto;
  margin-top: 0.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: 15rem;
  }
`;

export const StyledFoundArtistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
  }
`;

export const StyledHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xl3};
  margin-bottom: 0.75rem;
  line-height: 2rem;
  white-space: pre-line;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl4};
    color: ${({ theme }) => theme.colors.text.darker};
    line-height: 3rem;
  }
`;

export const StyledSubheader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.darker};
  font-size: ${({ theme }) => theme.fontSize.md};
  line-height: 1.5rem;
  white-space: pre-line;
  margin-bottom: 2rem;
`;

export const StyledGenresWrapper = styled.div`
  margin-right: -1.25rem;
`;

export const StyledArtistsTitle = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.md};
  margin-bottom: 1.25rem;
  margin-top: 1.5rem;
  max-width: 70%;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl};
  }
`;

export const StyledArtistsListWrapper = styled(Grid)`
  grid-gap: 1.25rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const StyledCardArtist = styled(CardArtist)`
  padding: 1rem !important;
`;

export const StyledEmailHint = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.placeholder};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledChildrenCard = styled(Card)`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid !important;
    grid-template-columns: 1fr auto;
  }
`;

export const StyledChildrenSelect = styled(Grid)`
  gap: 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;
