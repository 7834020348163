export enum OrderOptions {
  clientVoice = 'clientVoice',
  duet = 'duet',
  video = 'video',
}

export enum OrderOptionStatus {
  waitingForPayment = 'waitingForPayment',
  pending = 'pending',
  processing = 'inWork',
  generate = 'generate',
  finished = 'finished',
}
