import React from 'react';
import { Icon } from '../../../../shared/components/icons';
import { Card, Input } from '../../../../shared/components/ui';
import { useScopedTranslation } from '../../../../shared/hooks';
import { FieldErrorsImpl } from 'react-hook-form';

type IRecipient = {
  register: Function;
  errors: FieldErrorsImpl;
};

const Receiver = ({ register, errors }: IRecipient) => {
  const { t, rootT } = useScopedTranslation('funnel.contact_information');

  return (
    <Card title={t('recipient')} icon={<Icon type={'Heart'} color={'primary'} />}>
      <Input {...{ register, errors, name: 'receiver', placeholder: rootT('funnel.enter_name') }} />
    </Card>
  );
};

export default Receiver;
