import { useMemo } from 'react';
import useFunnelContext from '../../../hooks/useFunnelContext';
import { Artist } from '../../../../../shared/types/artist';

const useFilteredArtists = (artists: Artist[]) => {
  const { parameters, setParameters } = useFunnelContext();
  const selectedGenre = useMemo(() => parameters?.genre, [parameters]);

  const genres = useMemo(() => {
    const initialGenres: string[] = [];
    const artistGenres = artists.reduce((allGenres, artist) => {
      artist?.genres?.forEach((genre) => {
        if (!allGenres.includes(genre)) allGenres.push(genre);
      });
      return allGenres;
    }, initialGenres);

    return artistGenres.map((genre) => ({ value: genre, label: genre }));
  }, [artists]);

  const setGenre = (genre: string) => {
    setParameters({ ...parameters, genre });
  };

  const filteredArtists = useMemo(() => {
    return artists.filter((artist) => !selectedGenre || artist.genres?.includes(selectedGenre));
  }, [artists, selectedGenre]);

  return { genres, setGenre, filteredArtists };
};

export default useFilteredArtists;
