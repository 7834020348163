import { OrderOptionStatus } from '../../../shared/constants/OrderOptions';
import { useOrderContext } from './index';
import { useMemo } from 'react';

const useGetAvailableOrderOptions = () => {
  const { order } = useOrderContext();
  return useMemo(
    () =>
      order?.options?.filter((option) => option.status !== OrderOptionStatus.waitingForPayment) ??
      [],
    [order],
  );
};

export default useGetAvailableOrderOptions;
