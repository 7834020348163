import { useState } from 'react';
import { useGetOrderId } from '../hooks';
import { OrderApi } from '../../../api';
import { blobToFile } from '../../../shared/utils/utils';

const useUploadVoice = () => {
  const orderId = useGetOrderId();
  const [isLoading, setIsLoading] = useState(false);

  const uploadVoice = async (voice: Blob) => {
    setIsLoading(true);

    const formData = new FormData();
    try {
      const ext = voice.type.split('/')[1];
      const file = blobToFile(voice, `voice.${ext}`);
      formData.append('voice', file);
    } catch (e) {
      formData.append('voice', voice);
    }

    await OrderApi.uploadClientVoice(orderId, formData)
      .then(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { uploadVoice, isLoading };
};

export default useUploadVoice;
