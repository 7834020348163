import React, { useMemo } from 'react';
import { FunnelWrapper, StyledFunnelContainer, StyledStepperContainer } from './styled';
import { Stepper } from '../../shared/components/ui';
import { GeneralInformation } from './components/GeneralInformation';
import PersonalFacts from './components/PersonalFacts/PersonalFacts';
import useFunnelContext from './hooks/useFunnelContext';
import { ContactInformation } from './components/ContactInformation';
import { ArtistSelect, Customization } from './components/Customization';
import { FunnelSteps } from '../../shared/constants/FunnelSteps';
import { Preloader } from './components/Preloader';
import { MotionConfig } from 'framer-motion';
import { MotionContainer } from '../../shared/components/custom';
import { useScrollTo } from '../../shared/hooks';
import FoundArtist from './components/Customization/FoundArtist';

const CurrentStepComponent = ({ funnelStep }: { funnelStep: FunnelSteps }) => {
  const components: Record<number, React.ReactElement> = {
    [FunnelSteps.GENERAL]: <GeneralInformation />,
    [FunnelSteps.PERSONAL]: <PersonalFacts />,
    [FunnelSteps.CONTACTS]: <ContactInformation />,
    [FunnelSteps.CUSTOMIZATION]: <Customization />,
  };
  return useMemo(() => components[funnelStep], [funnelStep]);
};

const Funnel = () => {
  const {
    artistFound,
    funnelStep,
    funnelTotalSteps,
    isCreatingOrder,
    isLookingForArtist,
    artistSelect,
  } = useFunnelContext();

  useScrollTo({ deps: [funnelStep] });

  return (
    <StyledFunnelContainer>
      {((isLookingForArtist || isCreatingOrder) && <Preloader />) ||
        (artistSelect && <ArtistSelect />) ||
        (!!artistFound && <FoundArtist />) || (
          <FunnelWrapper>
            <StyledStepperContainer>
              <Stepper step={funnelStep + 1} totalSteps={funnelTotalSteps} />
            </StyledStepperContainer>
            <MotionConfig transition={{ duration: 0.5 }}>
              <MotionContainer key={funnelStep}>
                <CurrentStepComponent funnelStep={funnelStep} />
              </MotionContainer>
            </MotionConfig>
          </FunnelWrapper>
        )}
    </StyledFunnelContainer>
  );
};

export default Funnel;
