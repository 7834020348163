import { useEffect, useRef, useState } from 'react';
import { isIOS } from '../../../../utils/utils';
import { Events } from '../../../../constants/Events';

const useAudioPlayer = (src: string) => {
  const [id] = useState(Math.random().toString(16).slice(2));
  const [audio] = useState<HTMLAudioElement>(new Audio());
  const [playing, setPlaying] = useState(false);
  const [currentPercent, setCurrentPercent] = useState(0);
  const [iOS] = useState(isIOS());

  useEffect(() => {
    audio.src = src;
    audio.load();
  }, [src]);

  const playingRef = useRef(playing);
  const setPlayingRef = (state: boolean) => {
    playingRef.current = state;
    setPlaying(state);
  };

  useEffect(() => {
    window.addEventListener(
      `audiostatechanged-${id}`,
      (e: CustomEventInit & Event) => {
        e.stopImmediatePropagation();
        const { state } = e.detail;
        setPlayingRef(state);
      },
      false,
    );

    return () => {
      window.removeEventListener(`${Events.audioStateChanged}-${id}`, () => {});
      window.dispatchEvent(new CustomEvent(Events.audioDestroyed, { detail: { id } }));
      audio.srcObject = null;
    };
  }, [id]);

  const fireStartStopEvent = () => {
    window.dispatchEvent(
      new CustomEvent((playing && Events.audioStopped) || Events.audioStarted, { detail: { id } }),
    );
  };

  const touchToggle = () => {
    if (iOS) {
      !playing ? audio.play() : audio.pause();
      setPlaying(!audio.paused);
      fireStartStopEvent();
    }
  };

  const toggle = () => {
    if (!iOS) fireStartStopEvent();
  };

  const duration = (!isNaN(audio.duration) && audio.duration) || 100;

  audio.ontimeupdate = () => {
    setCurrentPercent((audio.currentTime * 100) / duration);
  };

  const setTime = (time: number) => {
    audio.currentTime = time;
    if (!playing) toggle();
  };

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return { playing, toggle, touchToggle, duration, currentPercent, setTime };
};

export default useAudioPlayer;
