import styled from '@emotion/styled';
import { AccordionItem, Grid, Text } from '@chakra-ui/react';

export const StyledFAQBg = styled.div`
  background: ${({ theme }) => theme.palette.neutral5};
  padding-top: 6.25rem;
  padding-bottom: 5.5rem;
  position: relative;

  > img {
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    > img {
      display: none;
    }
  }
`;

export const StyledFAQHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.darker};
  font-size: ${({ theme }) => theme.fontSize.xl5};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  line-height: 3.75rem;
  margin-bottom: 2rem;
  white-space: pre-line;

  span {
    color: ${({ theme }) => theme.palette.primary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    line-height: 2.25rem;
    margin-bottom: 1.5rem;
    white-space: unset;
  }
`;

export const StyledFAQWrapper = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.875rem;
  position: relative;
  z-index: 2;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  > div > div {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;

export const StyledAccordionItem = styled(AccordionItem)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  line-height: 1.5rem;

  background: ${({ theme }) => theme.palette.white};
  box-shadow: ${({ theme }) => theme.shadow.card};
  border: none;
  border-radius: ${({ theme }) => theme.radii.sm};

  > h2 > button {
    height: 4.5rem;
    padding-inline: 1.5rem;
    border-radius: ${({ theme }) => theme.radii.sm};

    &:hover {
      background: ${({ theme }) => theme.palette.white};
    }
  }

  .chakra-accordion__panel {
    border-top: 1px solid ${({ theme }) => theme.palette.neutral10};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.body};
    color: #3d3d40;
    margin: 0 1.5rem 1.5rem;
    padding: 1rem 0 0;
  }
`;

export const StyledAccordionIconBg = styled.div`
  background: ${({ theme }) => theme.palette.neutral5};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
