import { httpPost } from '../../shared/utils/http';

export type MailingApiType = {
  subscribe: (email: string) => Promise<string>;
  unsubscribe: (email: string) => Promise<string>;
};

export const MailingApi: MailingApiType = {
  subscribe: (email) => {
    return httpPost(`/mailing/subscribe`, { email });
  },
  unsubscribe: (email) => {
    return httpPost(`/mailing/unsubscribe`, { email });
  },
};
