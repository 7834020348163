import React, { useContext, useEffect, useMemo } from 'react';
import OrderSongDownload from './OrderSongDownload';
import { StyledUploadOptionsWrapper, StyledWrapper } from './styled';
import { PromocodeCard } from '../../../../../shared/components/custom';
import OrderUploadOptions from './OrderUploadOptions';
import { AppContext } from '../../../../../shared/context';
import { ContentContainer } from '../../../../../shared/components/ui';
import OrderAddMoreOptions from './OrderAddMoreOptions';
import { useHandleBackgroundColor, useHandleCartButton } from '../../../../../shared/hooks';
import { useOrderContext } from '../../../hooks';
import { designSystem } from '../../../../../shared/theme';
import useCheckStatus from '../../../requests/useCheckStatus';
import { OrderStatuses } from '../../../../../shared/constants/OrderStatuses';

const OrderDone = () => {
  useHandleBackgroundColor(designSystem.palette.white);
  const { setFluid } = useContext(AppContext);
  const { setCartButton, updatePositions } = useHandleCartButton();
  const { order, getOrder } = useOrderContext();
  const isFinished = useCheckStatus(order, OrderStatuses.done);

  useEffect(() => {
    updatePositions();
    setCartButton(true);
    setFluid(true);
    return () => {
      setCartButton(false);
      setFluid(false);
    };
  }, []);

  useEffect(() => {
    if (isFinished) getOrder();
  }, [isFinished]);

  const promocode = useMemo(() => order?.promo_code, [order]);

  return (
    <StyledWrapper>
      <OrderSongDownload />

      <StyledUploadOptionsWrapper>
        <ContentContainer>
          <OrderUploadOptions />
          <OrderAddMoreOptions />
          {promocode && (
            <PromocodeCard
              code={promocode.code}
              validity={promocode.valid}
              discount={promocode.discount}
            />
          )}
        </ContentContainer>
      </StyledUploadOptionsWrapper>
    </StyledWrapper>
  );
};

export default OrderDone;
