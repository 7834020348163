import React from 'react';
import { StyledOptionPrice, StyledPriceCrossed, StyledPriceWrapper } from '../styled';
import PriceOverline from '../../../../theme/assets/img/order/price_overline.svg';
import { Skeleton } from '@chakra-ui/react';

const OrderOptionPrice = ({
  discountedPrice,
  price,
}: {
  discountedPrice: number | null;
  price: number | undefined;
}) => {
  return (
    (price && (
      <StyledPriceWrapper>
        {((!discountedPrice || discountedPrice >= price) && (
          <StyledOptionPrice>${price}</StyledOptionPrice>
        )) || (
          <>
            <StyledOptionPrice>${discountedPrice}</StyledOptionPrice>
            <StyledPriceCrossed>
              <span>${price}</span>
              <img src={PriceOverline} alt={'price overline'} />
            </StyledPriceCrossed>
          </>
        )}
      </StyledPriceWrapper>
    )) || <Skeleton w={'3rem'} h={'2rem'} />
  );
};

export default OrderOptionPrice;
