import React, { useMemo } from 'react';
import {
  StyledOccasionsContainer,
  StyledOccasionsWrapper,
  StyledOccasionsHeader,
  StyledOccasion,
  StyledRopeImage,
  StyledRopeBg,
  StyledOccasionsCarousel,
} from './styled';
import { useBreakpoints, useScopedTranslation } from '../../../../../../shared/hooks';
import Birthday from '../../../../../../shared/theme/assets/img/landing/birthday.png';
import Anniversary from '../../../../../../shared/theme/assets/img/landing/anniversary.png';
import NewYear from '../../../../../../shared/theme/assets/img/landing/new_year.png';
import Christmas from '../../../../../../shared/theme/assets/img/landing/christmast.png';
import Valentine from '../../../../../../shared/theme/assets/img/landing/valentine.png';
import NoReason from '../../../../../../shared/theme/assets/img/landing/no_reason.png';
import Wedding from '../../../../../../shared/theme/assets/img/landing/wedding.png';
import Rope from '../../../../../../shared/theme/assets/img/landing/occasion_bg.png';
import Bg1 from '../../../../../../shared/theme/assets/img/landing/occasion_add_1.svg';
import Bg2 from '../../../../../../shared/theme/assets/img/landing/occasion_add_2.svg';
import { CarouselArrow } from '../../../../../../shared/components/ui';
import slides from '../../../../../../shared/components/icons/components/Slides';

const Occasions = () => {
  const { t } = useScopedTranslation('landing.occasions');
  const { isLg, isSm } = useBreakpoints();

  const slidesCount = useMemo(() => {
    if (isLg) return 4;
    if (isSm) return 3;
    return 1;
  }, [slides, isLg, isSm]);

  return (
    <StyledRopeBg>
      <StyledRopeImage src={Rope} alt={'rope'} />
      <StyledOccasionsContainer>
        <img src={Bg1} alt={'note'} />
        <img src={Bg2} alt={'note'} />
        <StyledOccasionsHeader>
          {t('header')} <span>{t('header_2')}</span>
        </StyledOccasionsHeader>

        <StyledOccasionsCarousel
          slidesToShow={slidesCount}
          arrows={isLg}
          infinite={false}
          nextArrow={<CarouselArrow />}
          prevArrow={<CarouselArrow prev />}
        >
          <StyledOccasion>
            <img src={Birthday} alt={'birthday'} />
          </StyledOccasion>
          <StyledOccasion>
            <img src={Anniversary} alt={'anniversary'} />
          </StyledOccasion>
          <StyledOccasion>
            <img src={NoReason} alt={'with no reason'} />
          </StyledOccasion>
          <StyledOccasion>
            <img src={Wedding} alt={'wedding'} />
          </StyledOccasion>
          <StyledOccasion>
            <img src={Christmas} alt={'christmas'} />
          </StyledOccasion>
          <StyledOccasion>
            <img src={NewYear} alt={'new year'} />
          </StyledOccasion>
          <StyledOccasion>
            <img src={Valentine} alt={'valentine'} />
          </StyledOccasion>
        </StyledOccasionsCarousel>
      </StyledOccasionsContainer>
    </StyledRopeBg>
  );
};

export default Occasions;
