import React, { Suspense } from 'react';
import { PageLoader } from '../shared/components/custom';
import { NotFound } from '../shared/components/custom';
import { Route, Routes } from 'react-router-dom';
import { Paths } from './routes';
import {
  AppRoot,
  FunnelPage,
  Landing,
  LandingPage,
  OrderPage,
  SuccessfulPayment,
  UnsubscribedFromNewsletter,
} from '../modules';
import { useRouteGuard } from './hooks/useRouteGuard';
import {
  Order,
  OrderAdditionalOptionsCheckout,
  OrderCheckout,
  OrderClientVoiceEditor,
  OrderClientVoiceRecorder,
  OrderOptions,
  OrderPhotosDropzone,
  OrderVideoEditor,
  OrderVideoSongSelect,
} from '../modules/order/views';
import {
  OrderPostcardEditor,
  OrderPostcardForm,
  OrderPostcardPreview,
  OrderPostcardSongSelect,
} from '../modules/order/views/OrderPostcardEditor';

const RootRouter = () => {
  useRouteGuard();

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route element={<LandingPage />}>
          <Route path={''} element={<Landing />} />
          <Route path={'unsubscribe'} element={<UnsubscribedFromNewsletter />} />
        </Route>
        <Route path={Paths.funnel} element={<AppRoot />}>
          <Route path={''} element={<FunnelPage />} />
          <Route path={Paths.order} element={<OrderPage />}>
            <Route path={''} element={<Order />} />
            <Route path={Paths.orderOptions} element={<OrderOptions />} />
            <Route path={Paths.orderCheckout} element={<OrderCheckout />} />
            <Route path={Paths.orderVideoEditor} element={<OrderVideoEditor />}>
              <Route path={''} element={<OrderPhotosDropzone />} />
              <Route path={Paths.orderVideoEditorSongSelect} element={<OrderVideoSongSelect />} />
            </Route>
            <Route path={Paths.orderPostcard} element={<OrderPostcardEditor />}>
              <Route path={''} element={<OrderPostcardPreview />} />
              <Route path={Paths.orderPostcardEditorForm} element={<OrderPostcardForm />} />
              <Route
                path={Paths.orderPostcardEditorSongSelect}
                element={<OrderPostcardSongSelect />}
              />
            </Route>
            <Route path={Paths.orderClientVoiceEditor} element={<OrderClientVoiceEditor />}>
              <Route
                path={Paths.orderClientVoiceEditorRecorder}
                element={<OrderClientVoiceRecorder />}
              />
            </Route>
            <Route path={Paths.addOptionsCheckout} element={<OrderAdditionalOptionsCheckout />} />
          </Route>
          <Route path={Paths.paymentSuccessful} element={<SuccessfulPayment />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default RootRouter;
