import React, { useState } from 'react';
import { StyledButtonWrapper, StyledHeader, StyledSongSelectWrapper } from './styled';
import {
  useBreakpoints,
  useHandleBackgroundColor,
  useHandleHeaderBackClick,
  useScopedTranslation,
} from '../../../../shared/hooks';
import useRedirectToOrder from '../../hooks/useRedirectToOrder';
import { Paths } from '../../../../router/routes';
import { useOrderContext } from '../../hooks';
import { MotionContainer, SongVersionSelect } from '../../../../shared/components/custom';
import { designSystem } from '../../../../shared/theme';
import { Button } from '../../../../shared/components/ui';
import { SongVersions } from '../../../../shared/constants/SongVersions';
import useHandleSlideshow from '../../requests/useHandleSlideshow';

const OrderVideoSongSelect = () => {
  const { t, rootT } = useScopedTranslation('order.editors.video');
  const { navigateWithOrder } = useRedirectToOrder();
  const { order, getOrder } = useOrderContext();
  const { isLg } = useBreakpoints();
  useHandleBackgroundColor(designSystem.palette.white);

  useHandleHeaderBackClick(
    true,
    () => {
      if (order) navigateWithOrder(Paths.orderVideoEditor);
    },
    [order],
  );

  const [selectedVersion, setSelectedVersion] = useState(SongVersions.track);
  const onSelect = (version: SongVersions) => {
    setSelectedVersion(version);
  };

  const { createSlideshow, isLoading } = useHandleSlideshow();

  const generateSlideshow = () => {
    createSlideshow(selectedVersion).then(() => {
      getOrder();
    });
  };

  return (
    <MotionContainer>
      <StyledHeader>{t('select_song')}</StyledHeader>

      <StyledSongSelectWrapper>
        <SongVersionSelect
          title={(isLg && rootT('order.editors.select_song')) || ''}
          selectedVersion={selectedVersion}
          onSelect={onSelect}
        />
      </StyledSongSelectWrapper>

      <StyledButtonWrapper mt={'2rem'}>
        <Button onClick={generateSlideshow} isLoading={isLoading} type={'button'} color={'primary'}>
          {t('continue')}
        </Button>
      </StyledButtonWrapper>
    </MotionContainer>
  );
};

export default OrderVideoSongSelect;
