import React, { useMemo } from 'react';
import { Icon } from '../../../../../../shared/components/icons';
import {
  StyledEditOption,
  StyledOptionCard,
  StyledOptionCardButton,
  StyledOptionCardHeader,
  StyledOptionCardHeaderWithEdit,
  StyledOptionCardIcon,
  StyledOptionCardLinkButton,
  StyledVideoContainer,
} from '../styled';
import useProcessOptionDeadline from '../hooks/useProcessOptionDeadline';
import { OrderOption } from '../../../../../../shared/types/orderOption';
import { Spacer, useDisclosure } from '@chakra-ui/react';
import OptionProgress from './OptionProgress';
import { ButtonSizes } from '../../../../../../shared/theme';
import { VideoPlayer } from '../../../../../../shared/components/ui';
import useRedirectToOrder from '../../../../hooks/useRedirectToOrder';
import { Paths } from '../../../../../../router/routes';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import PaymentIsProcessing from './PaymentIsProcessing';
import { downloadFromUrl } from '../../../../../../shared/utils/utils';
import { useOrderContext } from '../../../../hooks';
import { Alert } from '../../../../../../shared/components/custom';
import PaidOverlay from './PaidOverlay';

type IOptionCard = {
  option: OrderOption;
};

const SlideOptionCard = ({ option }: IOptionCard) => {
  const { t, rootT } = useScopedTranslation('order.editors.video');
  const { order } = useOrderContext();
  const { completion, isInProgress, totalTimeDays, isFinished, isWaitingForPayment } =
    useProcessOptionDeadline(option);

  const slideshow = useMemo(() => order?.slideshow, [order]);
  const slideshowRemakesLeft = useMemo(() => order?.slideshow?.remakes_available || 0, [order]);

  const download = () => downloadFromUrl(slideshow?.url ?? '');
  const { navigateWithOrder, routeWithOrder } = useRedirectToOrder();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const onAlertClose = () => {
    onClose();
    navigateWithOrder(Paths.orderVideoEditor);
  };

  return (
    <StyledOptionCard>
      <PaidOverlay />
      {(isInProgress && (
        <OptionProgress
          completion={completion}
          icon={'Slides'}
          title={t('processing_header')}
          hint={t('processing_text', { totalTimeDays })}
        />
      )) ||
        (isFinished && slideshow?.url && (
          <>
            <StyledOptionCardHeaderWithEdit>
              <span>{t('video_slideshow')}</span>
              {slideshowRemakesLeft > 0 && (
                <>
                  <StyledEditOption role={'button'} onClick={onOpen}>
                    {t('edit')}
                  </StyledEditOption>
                  <Alert
                    title={rootT('slideshow.remake', { count: slideshowRemakesLeft })}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onAlertClose}
                  />
                </>
              )}
            </StyledOptionCardHeaderWithEdit>
            <StyledVideoContainer>
              <VideoPlayer src={slideshow.url} color={'primary'} />
            </StyledVideoContainer>
            <StyledOptionCardButton onClick={download} size={ButtonSizes.md}>
              {t('download')}
            </StyledOptionCardButton>
          </>
        )) || (
          <>
            <StyledOptionCardHeader>{t('upload_photos_card')}</StyledOptionCardHeader>
            <Spacer />
            <StyledOptionCardIcon>
              <Icon type={'Slides'} />
            </StyledOptionCardIcon>
            <Spacer />
            {(isWaitingForPayment && <PaymentIsProcessing />) || (
              <StyledOptionCardLinkButton to={routeWithOrder(Paths.orderVideoEditor)}>
                <Icon type={'Plus'} color={'primary'} />
                <span>{t('add_photo')}</span>
              </StyledOptionCardLinkButton>
            )}
          </>
        )}
    </StyledOptionCard>
  );
};

export default SlideOptionCard;
