import { useContext, useMemo } from 'react';
import { AppContext } from '../context';
import { matchPath, useLocation } from 'react-router-dom';
import { Paths } from '../../router/routes';
import { useNavigateWithParams } from './useNavigateWithParams';
import { appLocalStorage } from '../utils/appLocalStorage';
import { LocalStorageKeys } from '../constants/LocalStorageKeys';
import { useOrderContext } from '../../modules/order/hooks';
import useGetAvailableOrderOptions from '../../modules/order/hooks/useGetAvailableOrderOptions';

export const useHandleCartButton = () => {
  const { selectedAdditionalOptions, setSelectedAdditionalOptions, cartButton, setCartButton } =
    useContext(AppContext);
  const { order } = useOrderContext();
  const { pathname } = useLocation();
  const { navigate } = useNavigateWithParams();
  const options = useGetAvailableOrderOptions();
  const orderOptions = useMemo(() => options?.map((option) => option.name) ?? [], [order]);

  const onCartClick = () => {
    const orderId = matchPath(Paths.order, pathname)?.params?.orderId;
    if (orderId) navigate(Paths.addOptionsCheckout, { orderId });
  };

  const updatePositions = () => {
    const storedOptions = appLocalStorage.getItem(LocalStorageKeys.orderAdditionalOptions) ?? [];
    const updatedOptions = storedOptions?.filter(
      (option: string) => !orderOptions.includes(option),
    );
    appLocalStorage.setItem(LocalStorageKeys.orderAdditionalOptions, updatedOptions);
    setSelectedAdditionalOptions(updatedOptions);
  };

  return {
    isShown: cartButton,
    onCartClick,
    cartPositions: selectedAdditionalOptions,
    setCartButton,
    updatePositions,
  };
};
