import React, { useEffect } from 'react';
import { designSystem } from '../../shared/theme';
import { useHandleBackgroundColor } from '../../shared/hooks';
import useFunnel from './hooks/useFunnel';
import { FunnelContextProvider } from './context/FunnelContext';
import { Funnel } from './index';

const FunnelPage = () => {
  const setBackground = useHandleBackgroundColor();

  const {
    funnelTotalSteps,
    funnelStep,
    setFunnelStep,
    artistSelect,
    setArtistSelect,
    parameters,
    setParameters,
    createOrder,
    values,
    setValues,
    isCreatingOrder,
    artistFound,
    setArtistFound,
    isLookingForArtist,
    setIsLookingForArtist,
  } = useFunnel();

  useEffect(() => {
    setBackground((!artistSelect && designSystem.palette.neutral5) || designSystem.palette.white);
  }, [funnelStep, artistSelect]);

  return (
    <FunnelContextProvider
      value={{
        funnelTotalSteps,
        funnelStep,
        setFunnelStep,
        parameters,
        setParameters,
        createOrder,
        artistSelect,
        setArtistSelect,
        values,
        setValues,
        isCreatingOrder,
        artistFound,
        setArtistFound,
        isLookingForArtist,
        setIsLookingForArtist,
      }}
    >
      <Funnel />
    </FunnelContextProvider>
  );
};

export default FunnelPage;
