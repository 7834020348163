import styled from '@emotion/styled';
import { Grid, Link, Text } from '@chakra-ui/react';
import { ContentContainer, LinkButton } from '../../ui';

export const StyledFooterBg = styled.div`
  padding: 1.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 1.25rem;
  }
`;

export const StyledFooterWrapper = styled(ContentContainer)`
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  padding: 0 !important;
  margin-bottom: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-gap: 1.5rem;
    flex-direction: column;
  }
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1;
  grid-gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-gap: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: start;
    grid-gap: 1rem;
  }
`;

export const StyledCopyrightWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 0;
    flex-direction: column-reverse;
    grid-gap: 0.5rem;
    align-items: start;
  }
`;

export const StyledNewsletterWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto 1fr;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledLogo = styled.div`
  margin-right: 1.25rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const StyledXSLogo = styled.div`
  margin-top: 0.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const StyledCopyrights = styled.div`
  margin-right: 2rem;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.palette.neutral70};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
    grid-gap: 0.3rem;
    text-align: center;
    margin-right: 0;
  }
`;

export const StyledXsCopyrights = styled(StyledCopyrights)`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
  }
`;

export const StyledMdCopyrights = styled(StyledCopyrights)`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const StyledSocialsWrapper = styled(Grid)`
  align-items: center;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(3, 1fr);
`;

export const StyledSocialButton = styled(LinkButton)`
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
  background: #71778814;

  svg {
    height: 1rem;
    width: auto;
    fill: #7f8596;

    path {
      fill: #7f8596;
    }
  }
`;

export const StyledLinksWrapper = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    grid-gap: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
  }
`;

export const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.darker};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
`;

export const StyledMailingText = styled(Text)`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    color: #7f8596;
  }
`;

export const StyledInputWrapper = styled.form`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  background: ${({ theme }) => theme.palette.white};
  box-shadow: ${({ theme }) => theme.shadow.card};
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 0.25rem;

  input {
    background: transparent;
    border: none;
    height: 2.5rem;
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-family: ${({ theme }) => theme.fontFamily.body};
  }

  button {
    height: 2.5rem;

    svg {
      transform: rotate(180deg);
    }
  }
`;
