import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useGetOrderOption from '../../hooks/useGetOrderOption';
import { OrderOptions } from '../../../../shared/constants/OrderOptions';
import useOrderOptionAvailable from '../../hooks/useOrderOptionAvailable';
import useRedirectToOrder from '../../hooks/useRedirectToOrder';
import { useOrderContext } from '../../hooks';

const OrderClientVoiceEditor = () => {
  const { navigateToOrder } = useRedirectToOrder();
  const { order } = useOrderContext();
  const currentOption = useGetOrderOption(OrderOptions.clientVoice);
  const isAvailable = useOrderOptionAvailable(currentOption);

  useEffect(() => {
    if (order && !isAvailable) navigateToOrder();
  }, [order, isAvailable]);

  return <Outlet />;
};

export default OrderClientVoiceEditor;
