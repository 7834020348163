import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Modal, ModalCloseButton } from '../../../../../../shared/components/ui';
import {
  StyledMenuDivider,
  StyledMenuItem,
  StyledMenuItemsWrapper,
  StyledModalBody,
  StyledTryButton,
} from './styled';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import { Paths } from '../../../../../../router/routes';

const MobileMenuModal = ({ activator }: { activator: React.ReactNode }) => {
  const { t } = useScopedTranslation('landing.header');
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {activator && (
        <div className={'activator'} onClick={onOpen}>
          {activator}
        </div>
      )}

      <Modal
        {...{
          isOpen,
          onOpen,
          onClose,
          size: 'full',
          motionPreset: 'scale',
          returnFocusOnClose: false,
        }}
      >
        <ModalCloseButton />
        <StyledModalBody>
          <StyledMenuItemsWrapper>
            <StyledMenuItem onClick={onClose} href={'#how'}>
              {t('how')}
            </StyledMenuItem>
            <StyledMenuItem onClick={onClose} href={'#why'}>
              {t('why')}
            </StyledMenuItem>
            <StyledMenuItem onClick={onClose} href={'#options'}>
              {t('extra')}
            </StyledMenuItem>
            <StyledMenuItem onClick={onClose} href={'#artists'}>
              {t('artists')}
            </StyledMenuItem>
            <StyledMenuItem onClick={onClose} href={'#faq'}>
              {t('faq')}
            </StyledMenuItem>
          </StyledMenuItemsWrapper>
          <StyledMenuDivider />
          <StyledTryButton to={Paths.funnel} color={'primary'} noShadow>
            {t('try_it_free')}
          </StyledTryButton>
        </StyledModalBody>
      </Modal>
    </>
  );
};

export default MobileMenuModal;
