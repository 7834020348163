import { useState } from 'react';
import { PricesResponse } from '../types/pricesResponse';
import { FunnelApi } from '../../api';

const useLoadPrices = () => {
  const [prices, setPrices] = useState<PricesResponse | null>(null);

  const getPrices = () => {
    FunnelApi.getPrices()
      .then((response) => {
        setPrices(response);
      })
      .catch(() => {});
  };

  return { prices, getPrices };
};

export default useLoadPrices;
