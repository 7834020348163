import React, { useEffect, useState } from 'react';
import { StyledAppLayout, StyledContentContainer } from './styled';
import { Header } from '../index';
import { AppContextProvider, defaultState } from '../../../context';
import { Events } from '../../../constants/Events';
import { useLoadPrices } from '../../../requests';
import { OrderOptions } from '../../../constants/OrderOptions';
import { Footer } from '../Footer';

type IAppLayout = {
  children: React.ReactNode | null;
  headerBackButton?: boolean | false;
};

const AppLayout = ({ children }: IAppLayout) => {
  const {
    background: defaultBg,
    backButton: defaultBackButton,
    fluid: defaultFluid,
    selectedAdditionalOptions: defaultSelectedAdditionalOptions,
    cartButton: defaultCartButton,
  } = defaultState;
  const [background, setBackground] = useState<string>(defaultBg);
  const [backButton, setBackButton] = useState<boolean>(defaultBackButton);
  const [cartButton, setCartButton] = useState<boolean>(defaultCartButton);
  const [selectedAdditionalOptions, setSelectedAdditionalOptions] = useState<OrderOptions[]>(
    defaultSelectedAdditionalOptions,
  );
  const [fluid, setFluid] = useState<boolean>(defaultFluid);
  const { prices, getPrices } = useLoadPrices();

  const onBackClick = () => {
    window.dispatchEvent(new CustomEvent(Events.backClick));
  };

  useEffect(() => {
    getPrices();
  }, []);

  return (
    <AppContextProvider
      value={{
        background,
        setBackground,
        backButton,
        setBackButton,
        prices,
        fluid,
        setFluid,
        selectedAdditionalOptions,
        setSelectedAdditionalOptions,
        cartButton,
        setCartButton,
      }}
    >
      <StyledAppLayout background={background}>
        <Header backButton={backButton} onBackClick={onBackClick} />
        <StyledContentContainer fluid={fluid}>{children}</StyledContentContainer>
        <Footer />
      </StyledAppLayout>
    </AppContextProvider>
  );
};

export default AppLayout;
