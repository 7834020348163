import { OrderApi } from '../../../api';
import { useEffect } from 'react';

const useSuccessfulPayment = (orderId: string, txnId: string) => {
  const sendRequest = async () => {
    await OrderApi.paymentSuccessful(orderId, txnId);
  };

  useEffect(() => {
    if (orderId && txnId) sendRequest();
  }, [orderId, txnId]);
};

export default useSuccessfulPayment;
