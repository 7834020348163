import { IFunnelParameters } from '../context/FunnelContext';
import { useState } from 'react';
import { OrderApi } from '../../../api';
import { OrderStoreRequest } from '../../../shared/types/order';
import { formatISO } from 'date-fns';

const useCreateOrder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const minimalPreloaderDuration: number = 4000;

  const checkIfDisabled = (
    value: string | number | boolean | null | undefined,
    disabled: boolean,
  ) => {
    if (disabled) return null;
    return value;
  };

  const createOrder = async (data: IFunnelParameters, onOrderCreated: Function = () => {}) => {
    setIsLoading(true);
    const startedAt = Date.now();

    const preparedData: OrderStoreRequest = {
      segment_id: data.recipient,
      occasion_id: data.occasion,
      date: (!!data.date && formatISO(data.date)) || null,
      artist_id: data.artistId,
      kids: !!data.children,
      kid_gender: checkIfDisabled(data.childType, data.childrenDisabled),
      eye_color: checkIfDisabled(data.eyesColor, data.eyesColorDisabled),
      dating_month: checkIfDisabled(data.month, data.monthDisabled),
      together_time: checkIfDisabled(data.together, data.togetherDisabled),
      recipient_name: data.receiver,
      customer: {
        name: data.gifter,
        email: data.email,
      },
    };

    OrderApi.createOrder(preparedData)
      .then((response) => {
        const now = Date.now();
        const diff = now - startedAt;
        if (diff > minimalPreloaderDuration) {
          setIsLoading(false);
          onOrderCreated(response);
        } else {
          setTimeout(() => {
            setIsLoading(false);
            onOrderCreated(response);
          }, minimalPreloaderDuration - diff);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return { createOrder, isLoading };
};

export default useCreateOrder;
