/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgPause = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="4.28571" height="12.8571" rx="2.14286" fill="#6097FB" />
    <rect x="9.71436" y="2" width="4.28571" height="12.8571" rx="2.14286" fill="#6097FB" />
  </svg>
);

export default SvgPause;
