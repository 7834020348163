import styled from '@emotion/styled';
import { Box, Text } from '@chakra-ui/react';

export const StyledOfferWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  margin-bottom: 3.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
    grid-gap: 0;
    margin-bottom: 2rem;
  }
`;

export const StyledPhotoWrapper = styled.div`
  margin-top: 5vh;
  position: relative;

  img {
    max-width: unset;
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 0;

    img {
      max-width: 100%;
      position: unset;
    }
  }
`;

export const StyledTextWrapper = styled.div`
  margin-top: 10vh;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 0;
  }
`;

export const StyledSubheader = styled(Text)`
  color: ${({ theme }) => theme.palette.neutral80};
  font-size: ${({ theme }) => theme.fontSize.xl};
  margin-bottom: 0.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    color: ${({ theme }) => theme.palette.neutral100};
    font-size: ${({ theme }) => theme.fontSize.sm};
    margin-bottom: 1rem;
  }
`;

export const StyledHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.darker};
  font-size: ${({ theme }) => theme.fontSize.xl5};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  line-height: 3.75rem;
  margin-bottom: 3rem;
  white-space: pre-line;

  > span {
    color: ${({ theme }) => theme.palette.primary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    color: ${({ theme }) => theme.palette.neutral100};
    font-size: ${({ theme }) => theme.fontSize.xl3};
    margin-bottom: 1rem;
    line-height: 2.25rem;
  }
`;

export const StyledTryCard = styled(Box)`
  border-radius: ${({ theme }) => theme.radii.sm};
  border: 4px solid ${({ theme }) => theme.palette.neutral5};
  padding: 1.25rem;
`;

export const StyledTryHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xl32};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  line-height: 2.5rem;
  margin-bottom: 0.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    color: ${({ theme }) => theme.palette.neutral100};
    font-size: ${({ theme }) => theme.fontSize.xl2};
  }
`;

export const StyledTryText = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.md};
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  white-space: pre-line;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    white-space: unset;
  }
`;

export const StyledTryActionWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1rem;

  a {
    min-width: 7rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-direction: column;
    grid-gap: 1.5rem;
    align-items: flex-start;

    a {
      min-width: 100%;
    }
  }
`;

export const StyledTryActionText = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.sm};
  white-space: pre-line;
`;
