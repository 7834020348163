import { useMemo } from 'react';
import { Paths } from '../../router/routes';
import { matchPath, useLocation } from 'react-router-dom';

export const useMatchPath = (paths: Paths[]) => {
  const { pathname } = useLocation();

  return useMemo(
    () => !!paths.filter((path) => !!matchPath(path, pathname)).length,
    [pathname, paths],
  );
};
