import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';

export const StyledOfferWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;

export const StyledPhotoWrapper = styled.div`
  margin-top: 5.125rem;
  position: relative;

  img {
    max-width: unset;
    position: absolute;

    &:first-of-type {
      bottom: 5.5rem;
      right: 0;
      z-index: 2;
    }

    &:last-of-type {
      top: 0;
      right: 2rem;
      z-index: 1;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 0;

    img {
      &:first-of-type {
        bottom: 2.5rem;
        right: 0;
        z-index: 2;
        width: 13rem;
      }

      &:last-of-type {
        max-width: 100%;
        position: unset;
        z-index: 1;
        margin-inline: auto;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 0;
    position: relative;

    img {
      max-width: unset;
      position: absolute;

      &:first-of-type {
        bottom: -2rem;
        left: 47%;
        z-index: 2;
      }

      &:last-of-type {
        max-width: 100%;
        position: unset;
        z-index: 1;
        margin-inline: auto;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    img {
      &:first-of-type {
        bottom: 1rem;
        left: 47%;
        z-index: 2;
        width: 12rem;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    img {
      &:first-of-type {
        bottom: -2rem;
        left: 37%;
        z-index: 2;
        width: 10rem;
      }
    }
  }
`;

export const StyledTextWrapper = styled.div`
  margin-top: 6.125rem;
  margin-bottom: 7.4rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
`;

export const StyledSubheader = styled(Text)`
  color: ${({ theme }) => theme.palette.neutral80};
  font-size: ${({ theme }) => theme.fontSize.xl};
  margin-bottom: 0.5rem;
`;

export const StyledHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.darker};
  font-size: ${({ theme }) => theme.fontSize.xl5};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  line-height: 3.75rem;
  margin-bottom: 1rem;
  white-space: pre-line;

  > span {
    color: ${({ theme }) => theme.palette.primary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    color: ${({ theme }) => theme.palette.neutral100};
    line-height: 2.25rem;
  }
`;

export const StyledTryText = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xl};
  line-height: 2rem;
  margin-bottom: 2rem;
  white-space: pre-line;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.palette.neutral100};
    line-height: 1.25rem;
    white-space: unset;
    margin-bottom: 1rem;
  }
`;

export const StyledTryActionWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1.5rem;

  a {
    min-width: 7rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-direction: column;
    align-items: start;
    grid-gap: 1rem;

    a {
      min-width: 100%;
    }
  }
`;

export const StyledTryActionText = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.md};
  white-space: pre-line;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    white-space: unset;
  }
`;
