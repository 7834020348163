import React from 'react';
import { useScopedTranslation } from '../../../hooks';
import { AudioPlayer, Button } from '../../ui';
import { Icon } from '../../icons';
import { StyledVersionCard } from './styled';

type IVersionCard = {
  name: string;
  isSelected: boolean;
  onSelect: Function;
  trackUrl: string;
};

const VersionCard = ({ name, trackUrl, isSelected, onSelect, ...props }: IVersionCard) => {
  const { t } = useScopedTranslation('order.versions');

  const selectVersion = () => {
    onSelect(name);
  };

  return (
    <StyledVersionCard {...props} isSelected={isSelected} onClick={selectVersion}>
      <AudioPlayer src={trackUrl} label={t(name)} />
      <Button fab noShadow onClick={selectVersion}>
        <Icon type={'Check'} />
      </Button>
    </StyledVersionCard>
  );
};

export default VersionCard;
