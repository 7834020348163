import React from 'react';
import { StyledCounter, StyledProgress, StyledProgressWrapper } from './styled';

type IStepper = {
  step: number;
  totalSteps: number;
};

const Stepper = ({ step, totalSteps, ...props }: IStepper) => {
  const maxValue = 100;
  const currentProgress = (maxValue / ((totalSteps > 0 && totalSteps) || 1)) * step;

  return (
    <StyledProgressWrapper {...props}>
      <StyledCounter>
        {step} <span>/ {totalSteps}</span>
      </StyledCounter>
      <StyledProgress value={currentProgress} />
    </StyledProgressWrapper>
  );
};

export default Stepper;
