import { useOrderContext } from '../../../hooks';
import { useState } from 'react';
import { OrderOptions } from '../../../../../shared/constants/OrderOptions';
import { useNavigateWithParams } from '../../../../../shared/hooks';
import { Paths } from '../../../../../router/routes';
import { OrderApi } from '../../../../../api';

const useHandlePayment = () => {
  const { order } = useOrderContext();

  const [isPaying, setIsPaying] = useState(false);
  const { fullPathWithParams, fullPathWithSearchParams } = useNavigateWithParams();

  const createPayment = ({ options }: { options: Array<OrderOptions> }) => {
    if (order) {
      const { id: orderNumber, uuid: orderId } = order;
      setIsPaying(true);
      OrderApi.createOptionsPayment(orderId, {
        success_url: fullPathWithSearchParams(Paths.paymentSuccessful, {
          orderId,
          orderNumber: orderNumber.toString(),
        }),
        cancel_url: fullPathWithParams(Paths.addOptionsCheckout, { orderId }),
        options,
      })
        .then((response) => {
          const { payment_url: url } = response;
          window.location.href = url;
        })
        .finally(() => {
          setIsPaying(false);
        });
    }
  };

  return { createPayment, isPaying };
};

export default useHandlePayment;
