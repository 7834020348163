import styled from '@emotion/styled';
import { StyledContainer } from '../../styled';
import { Grid, Text } from '@chakra-ui/react';
import { Carousel } from '../../../../../../shared/components/ui';

export const StyledRopeBg = styled.div`
  position: relative;
`;

export const StyledOccasionsContainer = styled(StyledContainer)`
  position: relative;
  padding-top: 8rem;
  padding-bottom: 8rem;

  > img {
    position: absolute;

    &:first-of-type {
      left: 8rem;
      top: 7.5rem;
    }

    &:last-of-type {
      right: 3rem;
      bottom: 3.5rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-top: 3rem;
    padding-bottom: 3rem;

    > img {
      display: none;
    }
  }
`;

export const StyledOccasionsHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.darker};
  font-size: ${({ theme }) => theme.fontSize.xl5};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  line-height: 3.75rem;
  margin-bottom: 1.75rem;
  white-space: pre-line;
  text-align: center;

  > span {
    color: ${({ theme }) => theme.palette.primary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    line-height: 2.25rem;
    white-space: unset;
    text-align: start;
    max-width: 20rem;
    margin-bottom: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    max-width: unset;
  }
`;

export const StyledOccasionsWrapper = styled(Grid)`
  grid-template-columns: repeat(4, 1fr);

  div:nth-of-type(1) {
    img {
      top: 0;
    }
  }

  div:nth-of-type(2) {
    img {
      top: 27px;
      left: 25px;
    }
  }

  div:nth-of-type(3) {
    img {
      top: 7px;
    }
  }

  div:nth-of-type(4) {
    img {
      top: -31px;
    }
  }

  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
  }
`;

export const StyledOccasionsCarousel = styled(Carousel)`
  margin-left: -1.25rem;
  margin-right: -1.25rem;

  .slick-track {
    padding-bottom: 2rem;
    padding-top: 3rem;

    .slick-slide {
      transition: 0.3s ease;
      position: relative;
    }

    .slick-slide[data-index='0'] {
      top: -2rem;
      transform: rotate(4deg);
    }

    .slick-slide[data-index='1'] {
      top: -1.5rem;
      transform: rotate(3deg);
    }

    .slick-slide[data-index='2'] {
      top: -1rem;
      transform: rotate(1deg);
    }

    .slick-slide[data-index='3'] {
      top: -1rem;
      transform: rotate(-2deg);
    }

    .slick-slide[data-index='4'] {
      top: -1.5rem;
      transform: rotate(-2deg);
    }

    .slick-slide[data-index='5'] {
      top: -2rem;
      transform: rotate(-3deg);
    }

    .slick-slide[data-index='6'] {
      top: -3rem;
      transform: rotate(-4deg);
    }
  }
`;

export const StyledRopeImage = styled.img`
  position: absolute;
  top: 18rem;
  width: 100%;

  @media (max-width: 1400px) {
    top: 21rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    top: 15rem;
  }
`;

export const StyledOccasion = styled.div`
  position: relative;
`;
