import React, { useMemo } from 'react';
import { StyledOptionsWrapper, StyledOptionsHeader, StyledOptionsBg } from './styled';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import OrderOptionVoice from '../../../../../../shared/components/custom/OrderOptionCard/OrderOptionVoice';
import OrderOptionVideo from '../../../../../../shared/components/custom/OrderOptionCard/OrderOptionVideo';
import OrderOptionDuet from '../../../../../../shared/components/custom/OrderOptionCard/OrderOptionDuet';
import useLoadOrderOptions from '../../../../../../shared/requests/useLoadOrderOptions';
import { OrderOptions } from '../../../../../../shared/constants/OrderOptions';
import { StyledContainer } from '../../styled';

const Options = () => {
  const { t } = useScopedTranslation('landing.options');
  const { options } = useLoadOrderOptions();

  const optionPrices = useMemo(() => {
    const prices: Record<string, number> = {};
    options.forEach((option) => {
      prices[option.name] = option.price;
    });
    return prices;
  }, [options]);

  return (
    <StyledOptionsBg id={'options'}>
      <StyledContainer>
        <StyledOptionsHeader>
          {t('header_1')} <span>{t('header_2')}</span> {t('header_3')}
        </StyledOptionsHeader>

        <StyledOptionsWrapper>
          <OrderOptionVoice bordered price={optionPrices[OrderOptions.clientVoice]} />
          <OrderOptionVideo bordered price={optionPrices[OrderOptions.video]} />
          <OrderOptionDuet bordered price={optionPrices[OrderOptions.duet]} />
        </StyledOptionsWrapper>
      </StyledContainer>
    </StyledOptionsBg>
  );
};

export default Options;
