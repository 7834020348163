import React from 'react';
import {
  StyledOptionCardIcon,
  StyledOptionProgressHeader,
  StyledOptionProgressHint,
  StyledProgress,
} from '../styled';
import { Spacer } from '@chakra-ui/react';
import { Icon, SVGKey } from '../../../../../../shared/components/icons';

type IOptionProgress = {
  completion: number;
  icon: SVGKey;
  title: string;
  hint: string;
};

const OptionProgress = ({ completion, icon, title, hint }: IOptionProgress) => {
  return (
    <>
      <StyledOptionCardIcon>
        <Icon type={icon} />
      </StyledOptionCardIcon>
      <StyledOptionProgressHeader>{title}</StyledOptionProgressHeader>
      <StyledOptionProgressHint>{hint}</StyledOptionProgressHint>
      <Spacer />
      <StyledProgress value={completion} />
    </>
  );
};

export default OptionProgress;
