import React from 'react';
import { StyledModal, StyledModalOverlay, StyledModalContent } from './styled';

type IModal = {
  activator?: React.ReactNode;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  children: React.ReactNode;
};

const Modal = ({ activator, size = 'md', children, isOpen, onOpen, onClose, ...props }: IModal) => {
  return (
    <StyledModal
      {...{
        isOpen,
        onClose,
        size,
        isCentered: true,
        motionPreset: 'slideInBottom',
        ...props,
      }}
    >
      <StyledModalOverlay />
      <StyledModalContent>{children}</StyledModalContent>
    </StyledModal>
  );
};

export default Modal;
