import { httpDelete, httpGet, httpPost, httpPostFormData, httpPut } from '../../shared/utils/http';
import { ORDERS_ROUTE } from '../contstants';
import { Order, OrderStoreRequest } from '../../shared/types/order';
import { SlidesPhotosResponse } from '../../shared/types/slidesPhoto';
import { OrderPostcard } from '../../shared/types/orderPostcard';
import { OrderOptions } from '../../shared/constants/OrderOptions';
import { SongVersions } from '../../shared/constants/SongVersions';
import { OrderSpeedUp } from '../../shared/constants/OrderSpeedUp';
import { PostcardRequest } from '../../shared/hooks/usePostcard';
import { OrderStatuses } from '../../shared/constants/OrderStatuses';

export type OrderApiType = {
  getOrder: (orderId: string) => Promise<Order>;
  createOrder: (data: OrderStoreRequest) => Promise<Order>;
  getPostcard: (
    orderId: string,
    params?: Record<string, string>,
    silent?: boolean,
  ) => Promise<OrderPostcard>;
  createPostcard: (orderId: string, data: PostcardRequest) => Promise<OrderPostcard>;
  updatePostcard: (orderId: string, data: PostcardRequest) => Promise<OrderPostcard>;
  uploadPostcardPhoto: (orderId: string, data: FormData) => Promise<{ url: string }>;
  getSlideshowPhotos: (orderId: string) => Promise<SlidesPhotosResponse[]>;
  syncSlideshowPhotos: (
    orderId: string,
    data: { photos: { id: string | number; order: number }[] },
  ) => Promise<SlidesPhotosResponse[]>;
  uploadSlideshowPhotos: (orderId: string, data: FormData) => Promise<SlidesPhotosResponse[]>;
  uploadSlideshowPhoto: (orderId: string, data: FormData) => Promise<SlidesPhotosResponse>;
  deleteSlideshowPhoto: (orderId: string, photoId: string | number) => Promise<never>;
  startSlideshow: (orderId: string, data: { track_variant: SongVersions }) => Promise<never>;
  createOrderPayment: (
    orderId: string,
    data: {
      promocode: string | null;
      options: OrderOptions[];
      success_url: string;
      cancel_url: string;
    },
  ) => Promise<{ order: Order; payment_url: string }>;
  createOptionsPayment: (
    orderId: string,
    data: {
      options: OrderOptions[];
      success_url: string;
      cancel_url: string;
    },
  ) => Promise<{ order: Order; payment_url: string }>;
  createSpeedUpPayment: (
    orderId: string,
    data: {
      speed_up: OrderSpeedUp;
      success_url: string;
      cancel_url: string;
    },
  ) => Promise<{ payment_url: string }>;
  uploadClientVoice: (orderId: string, data: FormData) => Promise<never>;
  paymentSuccessful: (orderId: string, txnId: string) => Promise<never>;
  checkStatus: (orderId: string, status: OrderStatuses) => Promise<boolean>;
};

export const OrderApi: OrderApiType = {
  getOrder: (orderId) => {
    return httpGet(`${ORDERS_ROUTE}/${orderId}`);
  },
  createOrder: (data) => {
    return httpPost(`${ORDERS_ROUTE}/store`, data);
  },
  createOrderPayment: (orderId, data) => {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/payment/full`, data);
  },
  createOptionsPayment: (orderId, data) => {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/payment/options`, data);
  },
  createSpeedUpPayment: (orderId, data) => {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/payment/speed-up`, data);
  },
  getPostcard: (orderId, params = {}, silent = false) => {
    return httpGet(`${ORDERS_ROUTE}/${orderId}/postcard`, params, silent);
  },
  createPostcard: (orderId, data) => {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/postcard`, data);
  },
  updatePostcard: (orderId, data) => {
    return httpPut(`${ORDERS_ROUTE}/${orderId}/postcard`, data);
  },
  uploadPostcardPhoto: (orderId, data) => {
    return httpPostFormData(`${ORDERS_ROUTE}/${orderId}/postcard/image`, data);
  },
  getSlideshowPhotos(orderId) {
    return httpGet(`${ORDERS_ROUTE}/${orderId}/photos`);
  },
  uploadSlideshowPhotos(orderId, data) {
    return httpPostFormData(`${ORDERS_ROUTE}/${orderId}/photos/batch`, data);
  },
  uploadSlideshowPhoto(orderId, data) {
    return httpPostFormData(`${ORDERS_ROUTE}/${orderId}/photos`, data);
  },
  deleteSlideshowPhoto(orderId, photoId) {
    return httpDelete(`${ORDERS_ROUTE}/${orderId}/photos/${photoId}`);
  },
  syncSlideshowPhotos(orderId, data) {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/photos/sync`, data);
  },
  startSlideshow(orderId, data) {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/slideshow/start`, data);
  },
  uploadClientVoice: (orderId, data) => {
    return httpPostFormData(`${ORDERS_ROUTE}/${orderId}/voice`, data);
  },
  paymentSuccessful: (orderId, txnId) => {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/payment/success/${txnId}`, {});
  },
  checkStatus: (orderId, status) => {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/is-status`, { status });
  },
};
