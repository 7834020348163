import React, { useEffect, useState } from 'react';
import {
  useBreakpoints,
  useHandleBackgroundColor,
  useHandleHeaderBackClick,
  useScopedTranslation,
} from '../../../../shared/hooks';
import { designSystem } from '../../../../shared/theme';
import { useOrderContext } from '../../hooks';
import useRedirectToOrder from '../../hooks/useRedirectToOrder';
import {
  MotionContainer,
  OrderOptionIsProcessing,
  VoiceRecorder,
} from '../../../../shared/components/custom';
import { RecordingResultType } from '../../../../shared/utils/audioRecorder';
import VoicePreview from './components/VoicePreview';
import useUploadVoice from '../../requests/useUploadVoice';
import useGetOrderOption from '../../hooks/useGetOrderOption';
import { OrderOptions, OrderOptionStatus } from '../../../../shared/constants/OrderOptions';
import { LinkButton } from '../../../../shared/components/ui';
import { Paths } from '../../../../router/routes';
import { MAX_CLIENT_VOICE_DURATION } from '../../../../shared/constants';
import RecorderWrapper from './components/RecorderWrapper';

const OrderClientVoiceEditor = () => {
  const { t } = useScopedTranslation('order.editors.client_voice');
  const { navigateToOrder, routeWithOrder } = useRedirectToOrder();
  const { order, getOrder } = useOrderContext();
  const voiceOption = useGetOrderOption(OrderOptions.clientVoice);
  const [isProcessing, setIsProcessing] = useState(false);
  const { isLg } = useBreakpoints();
  const [recordedVoice, setRecordedVoice] = useState<RecordingResultType | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);

  useHandleBackgroundColor(designSystem.palette.white);
  useHandleHeaderBackClick(
    true,
    () => {
      if (!!recordedVoice) {
        onStop(null);
      } else if (order) navigateToOrder();
    },
    [order, recordedVoice],
  );

  useEffect(() => {
    setIsProcessing(voiceOption?.status === OrderOptionStatus.processing);
  }, [voiceOption]);

  const { uploadVoice, isLoading } = useUploadVoice();

  const onStart = () => {
    setIsRecording(true);
  };
  const onStop = (result: RecordingResultType | null) => {
    setRecordedVoice(result);
    setIsRecording(false);
  };
  const onCancel = () => {
    setRecordedVoice(null);
  };
  const onUpload = async () => {
    if (recordedVoice) {
      await uploadVoice(recordedVoice.audioBlob).then(() => {
        getOrder();
      });
    }
  };

  return (
    <MotionContainer>
      {(isProcessing && (
        <OrderOptionIsProcessing
          title={t('congratulations')}
          subheader={t('as_soon_as')}
          type={OrderOptions.clientVoice}
          actionButton={
            <LinkButton to={routeWithOrder(Paths.order)} color={'primary'}>
              {t('go_to_home')}
            </LinkButton>
          }
        />
      )) || (
        <RecorderWrapper
          isRecording={isRecording}
          isPreview={!!recordedVoice}
          previewComponent={
            <VoicePreview
              url={recordedVoice?.audioUrl ?? ''}
              isLoading={isLoading}
              onUpload={onUpload}
              onDelete={onCancel}
            />
          }
        >
          <VoiceRecorder
            onStart={onStart}
            onStop={onStop}
            withHeader={isLg && !isRecording}
            maxDuration={MAX_CLIENT_VOICE_DURATION}
          />
        </RecorderWrapper>
      )}
    </MotionContainer>
  );
};

export default OrderClientVoiceEditor;
