import React, { useMemo } from 'react';
import { OrderCreated, OrderCreatedSkeleton } from './OrderCreated';
import { OrderReady } from './OrderReady';
import { useOrderContext } from '../../hooks';
import { useHandleHeaderBackClick } from '../../../../shared/hooks';
import { MotionContainer } from '../../../../shared/components/custom';
import { OrderStatuses } from '../../../../shared/constants/OrderStatuses';
import { OrderDone } from './OrderDone';
import useIsPaid from '../../hooks/useIsPaid';

const OrderComponent = ({ isReady }: { isReady: boolean }) =>
  (isReady && <OrderReady />) || <OrderCreated />;

const Order = () => {
  useHandleHeaderBackClick(false);
  const { order } = useOrderContext();
  const isPaid = useIsPaid();
  const orderIsReady = useMemo(
    () => !!order?.result && order?.status === OrderStatuses.waitingForPayment,
    [order],
  );
  const orderIsDone = useMemo(() => order?.status === OrderStatuses.done || isPaid, [order]);

  return (
    <MotionContainer>
      {(orderIsDone && <OrderDone />) || (order && <OrderComponent isReady={orderIsReady} />) || (
        <OrderCreatedSkeleton />
      )}
    </MotionContainer>
  );
};

export default Order;
