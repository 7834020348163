import React, { useEffect, useRef } from 'react';
import {
  StyledLabel,
  StyledPlayerWrapper,
  StyledPlayIcon,
  StyledPlayPauseButton,
  StyledSlider,
  StyledSliderFilledTrack,
  StyledSliderTrack,
} from './styled';
import useAudioPlayer from './hooks/useAudioPlayer';
import { Icon } from '../../icons';
import { ThemeColorButton } from '../../../theme';

type IAudioPlayer = {
  src: string;
  color?: ThemeColorButton;
  withProgress?: boolean;
  label?: string;
};

const AudioPlayer = ({ src, withProgress, label, color = 'primary', ...props }: IAudioPlayer) => {
  const { playing, toggle, touchToggle, duration, currentPercent, setTime } = useAudioPlayer(src);
  const track = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    track.current?.style.setProperty('width', `${currentPercent}%`);
  }, [currentPercent]);

  return (
    <>
      <StyledPlayerWrapper {...props}>
        <StyledPlayPauseButton
          fab
          noShadow
          color={color}
          onTouchStart={touchToggle}
          onClick={toggle}
        >
          {(playing && <Icon type="Pause" />) || <StyledPlayIcon type="Play" />}
        </StyledPlayPauseButton>
        {withProgress && (
          <StyledSlider
            color={color}
            min={0}
            max={duration}
            step={0.1}
            defaultValue={0}
            onChangeEnd={setTime}
            focusThumbOnChange={false}
          >
            <StyledSliderTrack>
              <StyledSliderFilledTrack ref={track} color={color} />
            </StyledSliderTrack>
          </StyledSlider>
        )}
        {label && <StyledLabel>{label}</StyledLabel>}
      </StyledPlayerWrapper>
    </>
  );
};

export default AudioPlayer;
