import React from 'react';
import { Skeleton } from '@chakra-ui/react';

const PostcardFormSkeleton = () => {
  return (
    <>
      <Skeleton w={'80%'} h={'3rem'} mb={'0.75rem'} />
      <Skeleton w={'80%'} h={'1rem'} mb={'0.25rem'} />
      <Skeleton w={'70%'} h={'1rem'} mb={'0.25rem'} />
      <Skeleton w={'30%'} h={'1rem'} mb={'0.50rem'} />
      <Skeleton w={'85%'} h={'1rem'} mb={'0.25rem'} />
      <Skeleton w={'90%'} h={'1rem'} mb={'0.25rem'} />
      <Skeleton w={'65%'} h={'1rem'} mb={'0.25rem'} />
      <Skeleton w={'4rem'} h={'1rem'} mb={'0.25rem'} />
    </>
  );
};

export default PostcardFormSkeleton;
