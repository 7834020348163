import React, { useMemo } from 'react';
import { StyledInput, StyledInputGroup } from './styled';
import { InputProps } from '@chakra-ui/react';
import { InputRightElement } from '@chakra-ui/react';
import { InputLeftElement } from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { FormErrorMessage } from '../FormErrorMessage';
import { FieldErrorsImpl } from 'react-hook-form';
import { Spinner } from '..';

type CustomInputProps = {
  inputRightElement?: React.ReactNode;
  inputLeftElement?: React.ReactNode;
  ref?: React.Ref<HTMLInputElement>;
  mask?: string;
  name: string;
  register?: Function;
  errors?: FieldErrorsImpl;
  isLoading?: boolean;
};

const Input = ({
  ref,
  inputRightElement,
  inputLeftElement,
  mask,
  register = () => {},
  errors = {},
  name,
  isLoading = false,
  ...props
}: InputProps & CustomInputProps) => {
  const { placeholder, defaultValue, value, onChange, onClick } = props;
  const errorMessage = useMemo(() => errors[name]?.message as string, [errors]);

  return (
    <div>
      <StyledInputGroup>
        {inputLeftElement && <InputLeftElement children={inputLeftElement} />}
        {(mask && (
          <InputMask
            {...{
              placeholder,
              value,
              defaultValue,
              onChange,
              onClick,
              mask,
              ...register(name),
              name,
              maskPlaceholder: '',
            }}
          >
            <StyledInput />
          </InputMask>
        )) || <StyledInput {...{ ...props, ref, ...register(name), name }} />}
        {inputRightElement && <InputRightElement children={inputRightElement} />}
        {isLoading && (
          <InputRightElement>
            <Spinner size={'sm'} />
          </InputRightElement>
        )}
      </StyledInputGroup>
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </div>
  );
};

export default Input;
