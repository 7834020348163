import React, { useEffect, useState } from 'react';
import { StyledButtonWrapper, StyledHeader, StyledSongSelectWrapper } from './styled';
import {
  useBreakpoints,
  useHandleBackgroundColor,
  useHandleHeaderBackClick,
  useScopedTranslation,
} from '../../../../shared/hooks';
import useRedirectToOrder from '../../hooks/useRedirectToOrder';
import { Paths } from '../../../../router/routes';
import { useOrderContext } from '../../hooks';
import { MotionContainer, SongVersionSelect } from '../../../../shared/components/custom';
import { designSystem } from '../../../../shared/theme';
import { Button } from '../../../../shared/components/ui';
import { SongVersions } from '../../../../shared/constants/SongVersions';

const ContinueButton = ({
  isLoading,
  onClick = () => {},
  block = false,
}: {
  isLoading: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  block?: boolean;
}) => {
  const { t } = useScopedTranslation('order.editors.postcard');

  return (
    <Button isLoading={isLoading} onClick={onClick} block={block} type={'button'} color={'primary'}>
      {t('continue')}
    </Button>
  );
};

const OrderPostcardSongSelect = () => {
  const { t } = useScopedTranslation('order.editors.postcard');
  const { navigateWithOrder, navigateToOrder } = useRedirectToOrder();
  const { order, postcard, createPostcard, updatePostcard, isLoadingPostcard } = useOrderContext();
  const { isLg } = useBreakpoints();
  useHandleBackgroundColor((isLg && designSystem.palette.neutral5) || designSystem.palette.white);

  useHandleHeaderBackClick(
    true,
    () => {
      if (order) navigateToOrder();
    },
    [order],
  );

  const [selectedVersion, setSelectedVersion] = useState(SongVersions.track);
  const onSelect = (version: SongVersions) => {
    setSelectedVersion(version);
  };

  useEffect(() => {
    if (postcard) setSelectedVersion(postcard.track_variant);
  }, [postcard]);

  const songSelected = async () => {
    if (postcard) await updatePostcard({ track_variant: selectedVersion });
    else await createPostcard({ track_variant: selectedVersion });
    navigateWithOrder(Paths.orderPostcardEditorForm);
  };

  return (
    <MotionContainer>
      {!isLg && <StyledHeader>{t('select_song')}</StyledHeader>}

      <StyledSongSelectWrapper>
        <SongVersionSelect
          title={(isLg && t('select_song')) || ''}
          selectedVersion={selectedVersion}
          onSelect={onSelect}
          actionButton={
            isLg && <ContinueButton block isLoading={isLoadingPostcard} onClick={songSelected} />
          }
        />
      </StyledSongSelectWrapper>

      {!isLg && (
        <StyledButtonWrapper mt={'2rem'}>
          <ContinueButton isLoading={isLoadingPostcard} onClick={songSelected} />
        </StyledButtonWrapper>
      )}
    </MotionContainer>
  );
};

export default OrderPostcardSongSelect;
