import React, { useEffect, useMemo } from 'react';
import { StyledArtistsBg, StyledArtistsHeader, StyledCarousel } from './styled';
import { useBreakpoints, useScopedTranslation } from '../../../../../../shared/hooks';
import { StyledContainer } from '../../styled';
import useLoadArtists from '../../../../../../shared/requests/useLoadArtists';
import ArtistCard from './ArtistCard';
import ArtistCardSkeleton from './ArtistCardSkeleton';
import { CarouselArrow } from '../../../../../../shared/components/ui';

const Artists = () => {
  const { t } = useScopedTranslation('landing.artists');
  const { getAllArtists, artists, isLoading } = useLoadArtists();

  useEffect(() => {
    getAllArtists();
  }, []);

  const { isLg, isSm } = useBreakpoints();

  const slidesToShow = useMemo(() => {
    if (isSm) return 1.5;
    return 1.15;
  }, [isLg, isSm]);

  return (
    <StyledArtistsBg id={'artists'}>
      <StyledContainer>
        <StyledArtistsHeader>
          {t('header')} <span>{t('header_2')}</span>
        </StyledArtistsHeader>

        <StyledCarousel
          infinite={false}
          slidesToShow={slidesToShow}
          arrows={isLg}
          showIndicators={!isLg}
          nextArrow={<CarouselArrow />}
          prevArrow={<CarouselArrow prev />}
        >
          {((isLoading || !artists.length) &&
            Array.from(Array(3), (e, i) => <ArtistCardSkeleton key={`artist-skeleton-${i}`} />)) ||
            artists.map((artist) => <ArtistCard artist={artist} key={artist.id} />)}
        </StyledCarousel>
      </StyledContainer>
    </StyledArtistsBg>
  );
};

export default Artists;
