import React from 'react';
import { StyledSocialButton } from './styled';
import { SocialNetworks } from '../../../constants/SocialNetworks';
import { LinkProps } from '@chakra-ui/react';
import { mapSocialButtonContent } from './helpers';
import { ButtonSizes } from '../../../theme';

type IButtonSocial = {
  size?: ButtonSizes;
  socialNetwork: SocialNetworks;
};

const ButtonSocial = ({
  socialNetwork,
  size = ButtonSizes.md,
  children,
  ...props
}: IButtonSocial & LinkProps) => {
  return (
    <StyledSocialButton {...{ ...props, socialnetwork: socialNetwork, size, target: '_blank' }}>
      {mapSocialButtonContent(socialNetwork)}
    </StyledSocialButton>
  );
};

export default ButtonSocial;
