import React from 'react';
import { Artist } from '../../../../../../shared/types/artist';
import { StyledArtistCard, StyledArtistInfo } from './styled';
import { AudioPlayer } from '../../../../../../shared/components/ui';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import { Text } from '@chakra-ui/react';

const ArtistCard = ({ artist }: { artist: Artist }) => {
  const { t } = useScopedTranslation('landing.artists');

  return (
    <StyledArtistCard>
      <img src={artist.photo_url} alt={artist.name} />
      <StyledArtistInfo>
        <div>
          <h3>{artist.name}</h3>
          <Text noOfLines={3}>{artist.description}</Text>
        </div>
        <AudioPlayer src={artist.example_url} label={t('play')} />
      </StyledArtistInfo>
    </StyledArtistCard>
  );
};

export default ArtistCard;
