import React from 'react';
import {
  StyledDemo,
  StyledDemoDescription,
  StyledDemoPreview,
  StyledEmotionsHint,
  StyledHeader,
  StyledHintWrapper,
  StyledMobileArtistCard,
  StyledMobileHeader,
  StyledOrderId,
  StyledOrderImage,
  StyledOrderImageWrapper,
  StyledWrapper,
} from './styled';
import { AudioPlayer } from '../../../../../shared/components/ui';
import { Icon } from '../../../../../shared/components/icons';
import OrderImage from '../../../../../shared/theme/assets/img/order/order_1.png';
import OrderArtistCard from './components/OrderArtistCard';
import { useScopedTranslation } from '../../../../../shared/hooks';
import { useOrderContext } from '../../../hooks';

const OrderDemoPreview = () => {
  const { t } = useScopedTranslation('order');
  const { order } = useOrderContext();

  return (
    <>
      <StyledMobileHeader>
        {t('order_ready_1')} <StyledOrderId>#{order?.id}</StyledOrderId> {t('order_ready_2')}
      </StyledMobileHeader>

      <StyledWrapper>
        <StyledDemo>
          <StyledHeader>
            {t('order_ready_1')} <StyledOrderId>#{order?.id}</StyledOrderId> {t('order_ready_2')}
          </StyledHeader>

          <StyledMobileArtistCard>
            <OrderArtistCard />
          </StyledMobileArtistCard>

          <StyledDemoPreview>
            <AudioPlayer src={order?.result?.demo?.url ?? ''} withProgress color={'primaryLight'} />
            {/*<StyledDemoDescription>{t('order_preview_hint')}</StyledDemoDescription>*/}
          </StyledDemoPreview>

          <StyledHintWrapper>
            <Icon type={'Info'} />
            <span>
              {t('order_expiration_1')} <br />
              {t('order_expiration_2')}
            </span>
          </StyledHintWrapper>
        </StyledDemo>
        <StyledOrderImageWrapper>
          <StyledEmotionsHint>{t('emotions')}</StyledEmotionsHint>
          <StyledOrderImage src={OrderImage} alt={'order image'} />
        </StyledOrderImageWrapper>
      </StyledWrapper>
    </>
  );
};

export default OrderDemoPreview;
