import React from 'react';
import { AudioPlayer } from '../../../../../shared/components/ui';
import { Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import { StyledPlayerCard } from '../styled';
import { useScopedTranslation } from '../../../../../shared/hooks';

const PostcardTrack = ({
  track,
}: {
  track: { url: string; lyrics?: string | undefined } | undefined;
}) => {
  const { t } = useScopedTranslation('order.editors.postcard');

  return (
    <StyledPlayerCard>
      {(track && <AudioPlayer src={track.url} label={t('listen_to_the_song')} />) || (
        <Flex alignItems={'center'} gap={'0.5rem'}>
          <SkeletonCircle w={'2.5rem'} h={'2.5rem'} />
          <Skeleton w={'70%'} h={'1rem'} />
        </Flex>
      )}
    </StyledPlayerCard>
  );
};

export default PostcardTrack;
