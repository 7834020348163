import styled from '@emotion/styled';
import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { Button, Input, Textarea } from '../../../../shared/components/ui';

export const StyledEditorWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 1.75rem 1.5rem 1.5rem;
    border-radius: ${({ theme }) => theme.radii.sm};
    max-width: 36.75rem;
    background: ${({ theme }) => theme.palette.white};
    margin-inline: auto;
  }
`;

export const StyledHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl3};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 2rem;
  white-space: pre-line;
  margin-bottom: 1.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl4};
    color: ${({ theme }) => theme.colors.text.darker};
    line-height: 3rem;
  }
`;

export const StyledSongSelectWrapper = styled.div`
  min-height: calc(100vh - 24.5rem);

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-height: calc(100vh - 16.5rem);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledButtonWrapper = styled(Flex)`
  width: 100%;
  button,
  a {
    width: 100%;
  }
`;

export const StyledImageCover = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const StyledEditButton = styled(Button)`
  width: 3.75rem;
  height: 3.75rem;
  background: #00000047;
  border-radius: 100%;
  position: absolute;

  &:hover,
  &:focus {
    background: #00000066;
  }
`;

export const StyledImageInput = styled.div`
  min-height: 14.5rem;
  margin: -1.25rem;
  background: ${({ theme }) => theme.palette.neutral5};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-height: 18.75rem;
    margin: 0;
    padding: 0;
    border-radius: ${({ theme }) => theme.radii.sm};
  }
`;

export const StyledUploadPlaceholder = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.palette.neutral70};
`;

export const StyledSquareFormWrapper = styled.div`
  background: ${({ theme }) => theme.palette.white};
  padding-top: 1.5rem;
  min-height: 17rem;
  position: relative;
  margin-inline: -1.25rem;
  padding-inline: 1.25rem;
`;

export const StyledFormWrapper = styled.div`
  border-top-left-radius: ${({ theme }) => theme.radii.xl};
  border-top-right-radius: ${({ theme }) => theme.radii.xl};
  background: ${({ theme }) => theme.palette.white};
  z-index: 2;
  margin-inline: -1.25rem;
  padding-inline: 1.25rem;
  padding-top: 1.5rem;
  min-height: 17rem;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const StyledPlayerCard = styled(Box)`
  border-radius: ${({ theme }) => theme.radii.sm};
  background: #f8f7fa;
  padding: 1rem;
  margin-bottom: 1rem;

  p {
    font-size: ${({ theme }) => theme.fontSize.md};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    border-radius: ${({ theme }) => theme.radii.sm};
  }
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: 1.5rem;
  border-color: #e5e2eb;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledTitleInput = styled(Input)`
  font-size: ${({ theme }) => theme.fontSize.xl2};
  line-height: 2rem;
  border: none;
  background: ${({ theme }) => theme.palette.white};
  padding: 0;
  border-radius: 0;
`;

export const StyledTextarea = styled(Textarea)`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.sm};
  line-height: 1.5rem;
  border: none;
  background: ${({ theme }) => theme.palette.white};
  padding: 0;
  border-radius: 0;
`;

export const StyledTextCounter = styled(Text)<{ isred: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme, isred }) => (isred && theme.palette.error) || theme.palette.neutral70};

  > span:first-of-type {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }
`;

export const StyledPostcardTitle = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.xl2};
  line-height: 2rem;
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
`;

export const StyledPostcardText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.sm};
  line-height: 1.5rem;
  white-space: pre-wrap;
  word-break: break-word;
  margin-bottom: 1.5rem;
`;
