import React, { useState } from 'react';
import {
  StyledCopyrights,
  StyledCopyrightWrapper,
  StyledFooterBg,
  StyledFooterWrapper,
  StyledLink,
  StyledLinksWrapper,
  StyledLogo,
  StyledLogoWrapper,
  StyledMdCopyrights,
  StyledSocialButton,
  StyledSocialsWrapper,
  StyledXsCopyrights,
  StyledXSLogo,
} from './styled';
import Logo from '../../../theme/assets/img/logo.svg';
import { Link } from 'react-router-dom';
import { RootPaths } from '../../../../router/routes';
import { FlexSpacer } from '../../ui';
import { Icon } from '../../icons';
import { SocialNetworkUrls } from '../../../constants/SocialNetworks';
import { useScopedTranslation } from '../../../hooks';
import { getYear } from 'date-fns';

const Footer = () => {
  const [year] = useState(getYear(new Date()));
  const { t } = useScopedTranslation('footer');

  return (
    <StyledFooterBg>
      <StyledFooterWrapper>
        <StyledLogoWrapper>
          <StyledLogo>
            <Link to={RootPaths.landing}>
              <img src={Logo} alt={'logo'} />
            </Link>
          </StyledLogo>
          <StyledCopyrights>
            <p>{t('copyright', { year })}</p>
            <p>{t('all_rights_reserved')}</p>
          </StyledCopyrights>
          <StyledLinksWrapper>
            <StyledLink href={'#'}>{t('terms_of_use')}</StyledLink>
            <StyledLink href={'#'}>{t('privacy')}</StyledLink>
          </StyledLinksWrapper>
        </StyledLogoWrapper>
        <StyledCopyrightWrapper>
          <StyledXsCopyrights>
            <p>
              {t('copyright', { year })} {t('all_rights_reserved')}
            </p>
          </StyledXsCopyrights>
          <StyledXSLogo>
            <Link to={RootPaths.landing}>
              <img src={Logo} alt={'logo'} />
            </Link>
          </StyledXSLogo>
          <FlexSpacer />
          <StyledSocialsWrapper>
            <StyledSocialButton href={SocialNetworkUrls.facebook} fab color={'secondary'} noShadow>
              <Icon type={'Facebook'} />
            </StyledSocialButton>
            <StyledSocialButton href={SocialNetworkUrls.twitter} fab color={'secondary'} noShadow>
              <Icon type={'Twitter'} />
            </StyledSocialButton>
            <StyledSocialButton href={SocialNetworkUrls.instagram} fab color={'secondary'} noShadow>
              <Icon type={'Instagram'} />
            </StyledSocialButton>
          </StyledSocialsWrapper>
        </StyledCopyrightWrapper>
      </StyledFooterWrapper>
      <StyledMdCopyrights>
        <p>
          {t('copyright', { year })} {t('all_rights_reserved')}
        </p>
      </StyledMdCopyrights>
    </StyledFooterBg>
  );
};

export default Footer;
