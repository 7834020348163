import styled from '@emotion/styled';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

export const StyledModal = styled(Modal)``;

export const StyledModalContent = styled(ModalContent)`
  border-radius: ${({ theme }) => theme.radii.sm};
  box-shadow: none;
`;

export const StyledModalHeader = styled(ModalHeader)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xl2};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 2rem;
  padding: 1.25rem 1.25rem 0.5rem;
`;

export const StyledModalBody = styled(ModalBody)`
  padding-inline: 1.25rem;
  padding-bottom: 1.25rem;
`;

export const StyledModalFooter = styled(ModalFooter)`
  padding: 1rem 1.25rem 1.25rem;
`;

export const StyledModalOverlay = styled(ModalOverlay)`
  background: ${({ theme }) => theme.palette.overlay};
`;

export const StyledCloseButton = styled(ModalCloseButton)`
  background: transparent;
  top: 1.2rem;
  right: 1.65rem;

  svg {
    width: 1rem;
    height: 1rem;

    path {
      fill: ${({ theme }) => theme.colors.text.main};
    }
  }
`;
