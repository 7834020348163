import React, { useMemo } from 'react';
import {
  StyledPromocodeCard,
  StyledPromocodeCardImageContainer,
  StyledPromocodeCode,
  StyledPromocodeCodeWrapper,
  StyledPromocodeDivider,
  StyledPromocodeDividerCircle,
  StyledPromocodeDividerContainer,
  StyledPromocodeHeader,
  StyledPromocodeValidity,
} from './styled';
import PromoImage from '../../../theme/assets/img/misc/promo.svg';
import { Icon } from '../../icons';
import { useScopedTranslation } from '../../../hooks';
import { CopyButton } from '../CopyButton';
import { formatDuration, intervalToDuration, parseISO } from 'date-fns';

type PromoType = {
  code: string;
  validity: string;
  discount: number;
};

const PromocodeCard = ({ code, validity, discount, ...props }: PromoType) => {
  const { t, rootT } = useScopedTranslation('general');

  const validityDays = useMemo(() => {
    if (!validity) return 14;

    const duration = intervalToDuration({
      start: new Date(),
      end: parseISO(validity),
    });

    return parseInt(formatDuration(duration, { format: ['days'] })) || 1;
  }, [validity]);

  return (
    <>
      <StyledPromocodeCard {...props}>
        <StyledPromocodeCardImageContainer>
          <img src={PromoImage} alt={'heart'} />
        </StyledPromocodeCardImageContainer>
        <StyledPromocodeHeader>{t('discount_for_friends', { discount })}</StyledPromocodeHeader>

        <StyledPromocodeDividerContainer>
          <StyledPromocodeDividerCircle />
          <StyledPromocodeDivider />
          <StyledPromocodeDividerCircle />
        </StyledPromocodeDividerContainer>

        <StyledPromocodeCodeWrapper>
          <CopyButton text={code}>
            <StyledPromocodeCode>{code}</StyledPromocodeCode>
            <Icon type={'Copy'}></Icon>
          </CopyButton>
        </StyledPromocodeCodeWrapper>
      </StyledPromocodeCard>

      <StyledPromocodeValidity>
        {rootT('general.promo_valid', { count: validityDays })}
      </StyledPromocodeValidity>
    </>
  );
};

export default PromocodeCard;
