import React from 'react';
import { Order, Option } from '../../../shared/types/order';
import { OrderPostcard } from '../../../shared/types/orderPostcard';

export type IOrderContext = {
  order: Order | null;
  options: Option[];
  getOrder: Function;
  postcard: OrderPostcard | null;
  getPostcard: Function;
  createPostcard: Function;
  updatePostcard: Function;
  updatePostcardImage: Function;
  isLoadingPostcard: boolean;
};

export const defaultState = {
  order: null,
  options: [],
  getOrder: () => {},
  postcard: null,
  getPostcard: () => {},
  createPostcard: () => {},
  updatePostcard: () => {},
  updatePostcardImage: () => {},
  isLoadingPostcard: false,
};

export const OrderContext = React.createContext<IOrderContext>(defaultState);

export const OrderContextProvider = OrderContext.Provider;
