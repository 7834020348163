import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';

export const StyledStepsBackgroundWrapper = styled.div`
  background: ${({ theme }) => theme.palette.neutral5};
  padding-top: 6.25rem;
  padding-bottom: 9.625rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
`;

export const StyledStepsHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.darker};
  font-size: ${({ theme }) => theme.fontSize.xl5};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  line-height: 3rem;
  margin-bottom: 0.75rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    line-height: 2.25rem;
  }
`;

export const StyledStepsSubheader = styled(Text)`
  color: ${({ theme }) => theme.palette.neutral80};
  font-size: ${({ theme }) => theme.fontSize.xl};
  margin-bottom: 2.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
  }
`;

export const StyledStepsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.75rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    grid-gap: 0.75rem;
  }
`;

export const StyledStepCard = styled.div`
  background: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.radii.sm};
  border: 8px solid #e6e9eccc;
  padding: 1.5rem;

  p {
    color: ${({ theme }) => theme.palette.neutral100};
    font-size: ${({ theme }) => theme.fontSize.xl};
    line-height: 2rem;
    white-space: pre-line;
  }

  img {
    margin: 1rem auto 1.5rem;
    min-height: 10rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    p {
      font-size: ${({ theme }) => theme.fontSize.md};
      line-height: 1.75rem;
      white-space: unset;
      text-align: center;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    p {
      text-align: start;
      max-width: 15rem;
    }
  }
`;
