import React, { useEffect, useState } from 'react';
import {
  StyledArtistHeader,
  StyledArtistWrapper,
  StyledCountdown,
  StyledCountdownLabel,
  StyledCountdownWrapper,
  StyledCounterWrapper,
  StyledDesktopArtistCard,
  StyledHeader,
  StyledHint,
  StyledMobileArtistCard,
  StyledOrderId,
  StyledOrderInfoWrapper,
  StyledSpeedupModalWrapper,
  StyledWrapper,
} from './styled';
import OrderSpeedupModal from './OrderSpeedupModal';
import { Button } from '../../../../../shared/components/ui';
import { useScopedTranslation, useTimeLeft } from '../../../../../shared/hooks';
import { Artist } from '../../../../../shared/types/artist';
import { CardArtist } from '../../../../../shared/components/custom';
import { useGetOrderDeadline, useOrderContext } from '../../../hooks';
import { formatISO } from 'date-fns';
import useProcessSpeedUpValues from './hooks/useProcessSpeedUpValues';
import Note1 from '../../../../../shared/theme/assets/img/order/created/note_1.svg';
import Note2 from '../../../../../shared/theme/assets/img/order/created/note_2.svg';
import useCheckStatus from '../../../requests/useCheckStatus';
import { OrderStatuses } from '../../../../../shared/constants/OrderStatuses';

const ArtistCard = ({ artist, withLabel = false }: { artist: Artist; withLabel?: boolean }) => (
  <StyledArtistWrapper>
    {withLabel && <StyledArtistHeader>Your Artist</StyledArtistHeader>}
    <CardArtist {...artist} withExample={false} />
  </StyledArtistWrapper>
);

export const OrderInfo = () => {
  const { t } = useScopedTranslation('order');
  const { order, getOrder } = useOrderContext();
  const orderDeadline = useGetOrderDeadline();
  const { isAbleToSpeedUp } = useProcessSpeedUpValues();

  const [isDeadlineOut, setIsDeadlineOut] = useState(false);
  const targetReached = () => {
    setIsDeadlineOut(true);
  };

  const isReady = useCheckStatus((isDeadlineOut && order) || null, OrderStatuses.waitingForPayment);
  useEffect(() => {
    if (isReady) getOrder();
  }, [isReady]);

  const timeLeft = useTimeLeft(orderDeadline);

  return (
    <StyledWrapper>
      <StyledOrderInfoWrapper>
        <StyledHeader>
          <span>{t('order_accepted_1')}</span> <StyledOrderId>#{order?.id}</StyledOrderId>{' '}
          <span>{t('order_accepted_2')}</span>
        </StyledHeader>
        <StyledHint>
          {t('order_will_be_ready_1')} <b>{timeLeft}</b>
        </StyledHint>
        {order?.artist && (
          <StyledMobileArtistCard>
            <ArtistCard artist={order.artist.data} withLabel />
          </StyledMobileArtistCard>
        )}
        <StyledCountdownWrapper>
          <StyledSpeedupModalWrapper>
            <OrderSpeedupModal
              activator={
                <Button isDisabled={!isAbleToSpeedUp} type={'button'} color={'primary'}>
                  {t('faster')}
                </Button>
              }
            />
          </StyledSpeedupModalWrapper>
          <StyledCounterWrapper>
            <StyledCountdownLabel>{t('left_before_order')}</StyledCountdownLabel>
            {order?.deadline_at && (
              <StyledCountdown
                successTitle={t('almost_there')}
                date={formatISO(orderDeadline)}
                onTargetReach={targetReached}
              />
            )}
          </StyledCounterWrapper>
        </StyledCountdownWrapper>
      </StyledOrderInfoWrapper>

      {order?.artist && (
        <StyledDesktopArtistCard>
          <ArtistCard artist={order?.artist.data} withLabel />
          <img src={Note1} alt={'Note'} />
          <img src={Note2} alt={'Note'} />
        </StyledDesktopArtistCard>
      )}
    </StyledWrapper>
  );
};
