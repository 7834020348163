import React from 'react';
import {
  StyledStepCard,
  StyledStepsBackgroundWrapper,
  StyledStepsHeader,
  StyledStepsSubheader,
  StyledStepsWrapper,
} from './styled';
import { StyledContainer } from '../../styled';
import Step1 from '../../../../../../shared/theme/assets/img/landing/step_1.svg';
import Step2 from '../../../../../../shared/theme/assets/img/landing/step_2.svg';
import Step3 from '../../../../../../shared/theme/assets/img/landing/step_3.svg';
import { useScopedTranslation } from '../../../../../../shared/hooks';

const HowItWorks = () => {
  const { t } = useScopedTranslation('landing.steps');

  return (
    <StyledStepsBackgroundWrapper id={'how'}>
      <StyledContainer>
        <StyledStepsHeader>{t('simple_steps')}</StyledStepsHeader>
        <StyledStepsSubheader>{t('gift')}</StyledStepsSubheader>
        <StyledStepsWrapper>
          <StyledStepCard>
            <img src={Step1} alt={'form'} />
            <p>{t('step_1_text')}</p>
          </StyledStepCard>
          <StyledStepCard>
            <img src={Step2} alt={'song'} />
            <p>
              {t('step_2_text_1')}
              <b>{t('step_2_text_2')}</b>
            </p>
          </StyledStepCard>
          <StyledStepCard>
            <img src={Step3} alt={'result'} />
            <p>{t('step_3_text')}</p>
          </StyledStepCard>
        </StyledStepsWrapper>
      </StyledContainer>
    </StyledStepsBackgroundWrapper>
  );
};

export default HowItWorks;
