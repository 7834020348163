import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMailingHook } from '../../../shared/requests/useMailingHook';
import { useScopedTranslation } from '../../../shared/hooks';
import { ContentContainer, Spinner } from '../../../shared/components/ui';
import { StyledMailingWrapper } from './styled';

const UnsubscribedFromNewsletter = () => {
  const { t } = useScopedTranslation('footer');
  const [searchParams] = useSearchParams();
  const [success, setIsSuccess] = useState(false);

  const email = useMemo(() => searchParams.get('email'), [searchParams]);

  const { unsubscribe } = useMailingHook();

  useEffect(() => {
    if (email) unsubscribe(email).then(() => setIsSuccess(true));
  }, [email]);

  return (
    <ContentContainer>
      <StyledMailingWrapper>
        {(success && t('unsubscribed')) || (
          <div>
            <Spinner /> {t('unsubscribing')}
          </div>
        )}
      </StyledMailingWrapper>
    </ContentContainer>
  );
};

export default UnsubscribedFromNewsletter;
