import React, { useMemo } from 'react';
import { Button } from '../../../../../../shared/components/ui';
import { StyledAudioPlayer, StyledSongCard } from '../styled';
import { ButtonSizes } from '../../../../../../shared/theme';
import { useOrderContext } from '../../../../hooks';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import { downloadFromUrl } from '../../../../../../shared/utils/utils';

const FinishedSong = () => {
  const { t } = useScopedTranslation('order.finished_song');
  const { order } = useOrderContext();
  const src = useMemo(() => order?.result?.track?.url ?? '', [order]);
  const download = () => downloadFromUrl(src);

  return (
    <StyledSongCard>
      <StyledAudioPlayer src={src} label={t('your_song')} />
      <div>
        <Button onClick={download} isDisabled={!src} color={'primary'} size={ButtonSizes.md}>
          {t('download_song')}
        </Button>
      </div>
    </StyledSongCard>
  );
};

export default FinishedSong;
