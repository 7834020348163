import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';

export const StyledInputWithAction = styled.form`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.25rem;

  button {
    height: 3.25rem;
    min-width: 4.75rem;
  }
`;

export const StyledPromocodeError = styled(Text)`
  color: ${({ theme }) => theme.palette.error};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
`;

export const StyledPromocodeWrapper = styled.div`
  margin: 1.5rem 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: 1rem 0;
  }
`;
