import React from 'react';
import {
  StyledHeader,
  StyledOfferWrapper,
  StyledPhotoWrapper,
  StyledTextWrapper,
  StyledTryActionText,
  StyledTryActionWrapper,
  StyledTryText,
} from './styled';
import { LinkButton } from '../../../../../../shared/components/ui';
import OfferImage2 from '../../../../../../shared/theme/assets/img/landing/bottom_offer_2.png';
import OfferImage1 from '../../../../../../shared/theme/assets/img/landing/bottom_offer_1.svg';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import { Paths } from '../../../../../../router/routes';
import { StyledContainer } from '../../styled';

const BottomOffer = () => {
  const { t } = useScopedTranslation('landing.bottom_offer');

  return (
    <StyledContainer>
      <StyledOfferWrapper>
        <StyledPhotoWrapper>
          <img src={OfferImage1} alt={'robot'} />
          <img src={OfferImage2} alt={'offer'} />
        </StyledPhotoWrapper>
        <StyledTextWrapper>
          <StyledHeader>
            {t('still_in_doubt')} <span>{t('try_for_free')}</span>
          </StyledHeader>

          <StyledTryText>
            {t('assemble')} <b>{t('24_hours')}</b>
          </StyledTryText>

          <StyledTryActionWrapper>
            <LinkButton to={Paths.funnel} color={'primary'} noShadow>
              {t('try_it_free')}
            </LinkButton>
            <StyledTryActionText>
              {t('like_it')} <b>{t('for_only')}</b>
            </StyledTryActionText>
          </StyledTryActionWrapper>
        </StyledTextWrapper>
      </StyledOfferWrapper>
    </StyledContainer>
  );
};

export default BottomOffer;
