import styled from '@emotion/styled';
import { Input, InputGroup } from '@chakra-ui/react';

export const StyledInputGroup = styled(InputGroup)`
  .chakra-input__right-element,
  .chakra-input__left-element {
    top: 0.375rem;
  }
`;

export const StyledInput = styled(Input)`
  padding: 1rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: ${({ theme }) => theme.radii.sm};
  height: 3.25rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-weight: 500;

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.placeholder};
  }

  &:focus,
  &[data-focus] {
    border-color: ${({ theme }) => theme.palette.primary};
    box-shadow: none;
  }
`;
