import React, { useEffect, useState } from 'react';
import { Button } from '../../ui';
import { ButtonSizes } from '../../../theme';
import { StyledButtonGroup } from './styled';
import { useScopedTranslation } from '../../../hooks';
import { ButtonGroupTypes } from './types';
import { Skeleton } from '@chakra-ui/react';

type IButtonGroup = {
  type?: ButtonGroupTypes;
  defaultValue?: string | number | null;
  options: Array<{ value: string | number; label: string; isDisabled?: boolean }>;
  onChange: Function;
  size?: ButtonSizes;
  translationScope?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  skeletonNumber?: number;
};

const ButtonGroup = ({
  type = ButtonGroupTypes.stack,
  options,
  onChange,
  defaultValue = null,
  size = ButtonSizes.sm,
  translationScope = '',
  isDisabled = false,
  isLoading = false,
  skeletonNumber = 6,
  ...props
}: IButtonGroup) => {
  const { t } = useScopedTranslation(translationScope);
  const [selectedValue, setSelectedValue] = useState<number | string | null>();

  const selectValue = (value: string | number | null) => () => {
    setSelectedValue(value);
    onChange(value);
  };

  useEffect(() => {
    selectValue(defaultValue)();
  }, [defaultValue]);

  return (
    <StyledButtonGroup type={type} {...props}>
      {(isLoading &&
        Array.from(Array(skeletonNumber), (e, i) => (
          <Skeleton w={'5rem'} height={'3rem'} key={`skel-button-${i}`} />
        ))) ||
        options.map(({ value, label, isDisabled: isOptionDisabled }) => (
          <Button
            isDisabled={isDisabled || isOptionDisabled}
            noShadow
            isLoading={isLoading}
            key={value}
            onClick={selectValue(value)}
            color={(selectedValue === value && 'primary') || 'secondary'}
            size={size}
          >
            {t(label)}
          </Button>
        ))}
    </StyledButtonGroup>
  );
};

export default ButtonGroup;
