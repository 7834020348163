import React, { useEffect, useRef, useState } from 'react';
import {
  StyledEditButton,
  StyledImageCover,
  StyledImageInput,
  StyledUploadPlaceholder,
} from '../styled';
import { Icon } from '../../../../../shared/components/icons';
import { readAsDataUrl } from '../../../../../shared/utils/imageUtils';
import { useScopedTranslation } from '../../../../../shared/hooks';
import { useOrderContext } from '../../../hooks';
import { Spinner } from '../../../../../shared/components/ui';

type IPostcardFormImage = {
  isEdit?: boolean;
  setImage?: Function;
  cover: string;
};

const PostcardFormImage = ({
  setImage = () => {},
  cover = '',
  isEdit = false,
}: IPostcardFormImage) => {
  const { t } = useScopedTranslation('order.editors.postcard');
  const [imageCover, setImageCover] = useState<string>(cover);
  const { isLoadingPostcard } = useOrderContext();

  useEffect(() => {
    setImageCover(cover);
  }, [cover]);

  const imageInputRef = useRef<HTMLInputElement>(null);
  const pickImage = () => {
    if (isEdit && imageInputRef.current && !isLoadingPostcard) imageInputRef.current.click();
  };
  const onImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    if (input && input.files) {
      const fileUploaded = input.files[0];
      setImage(fileUploaded);
      readAsDataUrl(fileUploaded).then((src) => setImageCover(src));
    }
  };

  return (
    <StyledImageInput onClick={pickImage}>
      {(imageCover && (
        <>
          <StyledImageCover src={imageCover} alt="cover" />
          {isEdit && (
            <StyledEditButton>
              {(isLoadingPostcard && <Spinner />) || <Icon type={'Edit'} />}
            </StyledEditButton>
          )}
        </>
      )) || (
        <div>
          {(isLoadingPostcard && <Spinner />) || (
            <>
              <Icon type={'Picture'} />
              <StyledUploadPlaceholder>{t('upload_cover')}</StyledUploadPlaceholder>
            </>
          )}
        </div>
      )}
      <input
        ref={imageInputRef}
        onChange={onImageSelect}
        style={{ display: 'none' }}
        type={'file'}
        accept={'image/jpeg,image/png,image/jpg,image/heic'}
      />
    </StyledImageInput>
  );
};

export default PostcardFormImage;
