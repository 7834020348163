import styled from '@emotion/styled';
import { Card } from '../../ui';
import { Avatar, Flex, Grid, Text } from '@chakra-ui/react';

export const StyledArtistCard = styled(Card)`
  padding: 1rem;
  grid-template-rows: auto 1fr auto auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 1rem;
  }
`;

export const StyledHeaderWrapper = styled(Grid)`
  align-items: center;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
`;

export const StyledArtistAvatar = styled(Avatar)`
  width: 4.5rem;
  height: 4.5rem;
`;

export const StyledArtistInfo = styled(Grid)``;

export const StyledArtistName = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl2};
  color: ${({ theme }) => theme.colors.text.darker};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 2rem;
  margin-bottom: 0.5rem;
`;

export const StyledStatGrid = styled(Flex)`
  align-items: center;
  column-gap: 0.7rem;
  flex-wrap: wrap;
`;

export const StyledArtistStat = styled(Flex)`
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.darker};
  margin-right: 0.4rem;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.3rem;
  }
`;

export const StyledArtistDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.main};
`;

export const StyledArtistExampleWrapper = styled.div``;
