import React from 'react';
import { AppLayout } from '../../shared/components/custom';
import { Outlet } from 'react-router-dom';

const AppRoot = () => {
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export default AppRoot;
