export const isHeic = (file: File) => {
  return file.name.toLowerCase().includes('.heic') || file.name.toLowerCase().includes('.heif');
};

export const readAsDataUrl = (fileData: File) => {
  return new Promise<string>(async (resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      resolve('');
    };

    reader.readAsDataURL(fileData);
  });
};
