import React from 'react';
import {
  useHandleHeaderBackClick,
  useNavigateWithParams,
  useScopedTranslation,
} from '../../../../shared/hooks';
import {
  StyledArtistsListWrapper,
  StyledArtistsTitle,
  StyledCardArtist,
  StyledGenresWrapper,
  StyledHeader,
} from '../../styled';
import { ButtonGroup, MotionContainer } from '../../../../shared/components/custom';
import { ButtonGroupTypes } from '../../../../shared/components/custom/ButtonGroup/types';
import { Button } from '../../../../shared/components/ui';
import { ButtonSizes } from '../../../../shared/theme';
import useFunnelContext from '../../hooks/useFunnelContext';
import { Artist } from '../../../../shared/types/artist';
import { Order } from '../../../../shared/types/order';
import { CardArtistSkeleton } from '../../../../shared/components/custom/CardArtist';
import { Paths } from '../../../../router/routes';
import { FunnelSteps } from '../../../../shared/constants/FunnelSteps';
import useFilteredArtists from './hooks/useFilteredArtists';

const ArtistSelect = () => {
  const {
    setParameters,
    parameters,
    values: { artists },
    createOrder,
    setFunnelStep,
    setArtistSelect,
  } = useFunnelContext();
  const { t } = useScopedTranslation('funnel.customization');
  const { navigate } = useNavigateWithParams();
  const { genres, setGenre, filteredArtists } = useFilteredArtists(artists);

  useHandleHeaderBackClick(true, () => {
    setArtistSelect(false);
    setFunnelStep(FunnelSteps.CUSTOMIZATION);
  });

  const onOrderCreated = (order: Order) => {
    navigate(Paths.order, { orderId: order?.uuid });
  };

  const selectArtist = (artist: Artist) => () => {
    const params = { ...parameters, artistId: artist.id };
    setParameters(params);
    createOrder({ ...params }, onOrderCreated);
  };

  return (
    <MotionContainer>
      <StyledHeader>{t('genre')}</StyledHeader>
      <StyledGenresWrapper>
        <ButtonGroup
          isLoading={!artists.length}
          defaultValue={genres[0]?.value}
          options={genres}
          onChange={setGenre}
          type={ButtonGroupTypes.sidescroll}
          translationScope={'funnel.customization.genres'}
        />
      </StyledGenresWrapper>
      <StyledArtistsTitle>{t('best_performers')}</StyledArtistsTitle>
      <StyledArtistsListWrapper>
        {(!!artists.length &&
          filteredArtists?.map((artist, key) => (
            <StyledCardArtist {...artist} key={key}>
              <Button
                onClick={selectArtist(artist)}
                size={ButtonSizes.sm}
                type={'button'}
                color={'secondary'}
                noShadow
              >
                {t('choose')}
              </Button>
            </StyledCardArtist>
          ))) ||
          Array.from(Array(3), (e, i) => <CardArtistSkeleton key={`artist-skeleton-${i}`} />)}
      </StyledArtistsListWrapper>
    </MotionContainer>
  );
};

export default ArtistSelect;
