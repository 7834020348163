import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';
import { Carousel } from '../../../../shared/components/ui';

export const StyledPreloaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: -1.25rem;
  height: calc(100vh - 14.75rem);

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background: ${({ theme }) => theme.palette.white};
    border-radius: ${({ theme }) => theme.radii.sm};
    margin: unset;
    height: 37rem;
  }
`;

export const StyledPreloaderText = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl4};
  color: ${({ theme }) => theme.colors.text.darker};
  text-align: center;
  white-space: pre-line;
  line-height: 3rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    color: ${({ theme }) => theme.colors.text.darker};
    line-height: 2.5rem;
  }
`;

export const StyledPreloaderArtistImageWrapper = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 100%;
  overflow: hidden;
  transition: 0.5s ease-in-out;
  margin-inline: auto;

  .chakra-avatar {
    width: 100%;
    height: 100%;

    > div {
      font-size: ${({ theme }) => theme.fontSize.xl3};
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const StyledSelectedArtistPreloader = styled.div`
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 100%;
  overflow: hidden;
  transition: 0.5s ease-in-out;
  margin-inline: auto;
  margin-bottom: 3rem;

  box-shadow: 0 0 0 0 ${({ theme }) => theme.palette.primary};
  transform: scale(1);
  animation: pulse 2s infinite;

  .chakra-avatar {
    width: 100%;
    height: 100%;

    > div {
      font-size: ${({ theme }) => theme.fontSize.xl3};
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 ${({ theme }) => theme.palette.primary60};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 16px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

export const StyledCarousel = styled(Carousel)`
  width: 100%;
  min-height: 11.25rem;
  display: flex;
  align-items: center;
  padding-bottom: 2rem;

  .slick-track {
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    .slick-slide {
      opacity: 0.3;
      transition: all 0.3s ease;

      > div {
        width: 8.25rem;
      }

      &.slick-active {
        opacity: 1;

        > div > div > div {
          width: 8.25rem;
          height: 8.25rem;
        }
      }
    }
  }
`;
