import React from 'react';
import { StyledCarousel } from './styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider, { Settings } from 'react-slick';

const Carousel = ({
  showIndicators = true,
  autoPlay = false,
  interval = 3000,
  swipeable = true,
  centerMode = false,
  variableWidth = false,
  selectedItem = 0,
  infinite = true,
  arrows = false,
  slidesToShow = 1,
  nextArrow,
  prevArrow,
  ...props
}: {
  showIndicators?: boolean;
  autoPlay?: boolean;
  interval?: number;
  swipeable?: boolean;
  centerMode?: boolean;
  variableWidth?: boolean;
  infinite?: boolean;
  arrows?: boolean;
  selectedItem?: number;
  slidesToShow?: number;
  nextArrow?: React.ReactNode;
  prevArrow?: React.ReactNode;
} & Settings) => {
  return (
    <StyledCarousel>
      <Slider
        {...{
          ...props,
          dots: showIndicators,
          infinite,
          autoplaySpeed: interval,
          centerMode,
          variableWidth: variableWidth,
          autoplay: autoPlay,
          pauseOnHover: false,
          swipeToSlide: swipeable,
          arrows,
          initialSlide: selectedItem,
          draggable: swipeable,
          slidesToShow,
          nextArrow,
          prevArrow,
        }}
      >
        {props.children}
      </Slider>
    </StyledCarousel>
  );
};

export default Carousel;
