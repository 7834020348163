/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgMenu = (props) => (
  <svg
    {...props}
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4997 15.6085H1.23332C0.552153 15.6085 0 15.0563 0 14.3752C0 13.694 0.552153 13.1418 1.23332 13.1418H18.4997C19.1808 13.1418 19.733 13.694 19.733 14.3752C19.733 15.0563 19.1808 15.6085 18.4997 15.6085Z"
      fill="#12213B"
      fillOpacity="0.7"
    />
    <path
      d="M18.4997 9.4417H1.23332C0.552153 9.4417 0 8.88954 0 8.20842C0 7.52729 0.552153 6.9751 1.23332 6.9751H18.4997C19.1808 6.9751 19.733 7.52725 19.733 8.20842C19.733 8.88959 19.1808 9.4417 18.4997 9.4417Z"
      fill="#12213B"
      fillOpacity="0.7"
    />
    <path
      d="M18.4997 3.27524H1.23332C0.552153 3.27524 0 2.72308 0 2.04191C0 1.36075 0.552153 0.808594 1.23332 0.808594H18.4997C19.1808 0.808594 19.733 1.36075 19.733 2.04191C19.733 2.72308 19.1808 3.27524 18.4997 3.27524Z"
      fill="#12213B"
      fillOpacity="0.7"
    />
  </svg>
);

export default SvgMenu;
