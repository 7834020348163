import React, { useEffect } from 'react';
import useFunnelContext from '../../hooks/useFunnelContext';
import {
  useBreakpoints,
  useHandleBackgroundColor,
  useHandleHeaderBackClick,
  useScopedTranslation,
} from '../../../../shared/hooks';
import {
  StyledArtistsSubheader,
  StyledArtistsHeader,
  StyledStepHeader,
  StyledStepHeading,
  StyledStepSubheader,
  StyledStepper,
  StyledHeaderWithStepper,
  StyledCustomizationForm,
  StyledCustomizationActionsWrapper,
} from '../../styled';
import { Button } from '../../../../shared/components/ui';
import { FunnelSteps } from '../../../../shared/constants/FunnelSteps';
import useLoadArtists from '../../../../shared/requests/useLoadArtists';
import { designSystem } from '../../../../shared/theme';
import ArtistsImages from './components/ArtistsImages';
import { httpErrorHandler } from '../../../../shared/utils/httpErrorHandler';

const Customization = () => {
  const { t } = useScopedTranslation('funnel.customization');
  const minimalPreloaderDuration: number = 4000;

  const {
    setArtistSelect,
    funnelStep,
    funnelTotalSteps,
    setFunnelStep,
    parameters: { recipient: segmentId, occasion: occasionId },
    setIsLookingForArtist,
    setArtistFound,
    values,
    setValues,
  } = useFunnelContext();

  const chooseArtist = () => {
    setArtistSelect(true);
  };

  const { artists, getArtists } = useLoadArtists();

  useEffect(() => {
    getArtists({ segmentId, occasionId });
  }, []);

  useEffect(() => {
    setValues({ ...values, artists });
  }, [artists]);

  const findArtist = async () => {
    setIsLookingForArtist(true);
    const startedAt = Date.now();

    try {
      const loadedArtists =
        (!!artists?.length && artists) || (await getArtists({ segmentId, occasionId }));

      const randomIndex = Math.floor(Math.random() * loadedArtists.length);
      const randomArtist = loadedArtists[randomIndex];

      const now = Date.now();
      const diff = now - startedAt;
      if (diff > minimalPreloaderDuration) {
        setIsLookingForArtist(false);
        setArtistFound(randomArtist);
      } else {
        setTimeout(() => {
          setIsLookingForArtist(false);
          setArtistFound(randomArtist);
        }, minimalPreloaderDuration - diff);
      }
    } catch (error) {
      httpErrorHandler({ message: '' });
      setIsLookingForArtist(false);
    }
  };

  useHandleHeaderBackClick(true, () => setFunnelStep(FunnelSteps.CONTACTS));

  const { isLg } = useBreakpoints();
  useHandleBackgroundColor((!isLg && designSystem.palette.white) || designSystem.palette.neutral5);

  return (
    <StyledCustomizationForm>
      <StyledStepHeading>
        <StyledHeaderWithStepper>
          <StyledStepHeader>{t('header')}</StyledStepHeader>
          <StyledStepper step={funnelStep + 1} totalSteps={funnelTotalSteps} />
        </StyledHeaderWithStepper>
        <StyledStepSubheader>{t('subheader')}</StyledStepSubheader>
      </StyledStepHeading>
      <ArtistsImages />
      <StyledArtistsHeader>{t('pick_an_artist')}</StyledArtistsHeader>
      <StyledArtistsSubheader>{t('best_artist')}</StyledArtistsSubheader>
      <StyledCustomizationActionsWrapper>
        <Button onClick={findArtist} type={'button'} color={'primary'}>
          {t('auto_selection')}
        </Button>
        <Button onClick={chooseArtist} noShadow type={'button'} color={'secondary'}>
          {t('manual_selection')}
        </Button>
      </StyledCustomizationActionsWrapper>
    </StyledCustomizationForm>
  );
};

export default Customization;
