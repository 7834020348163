import React, { useContext, useMemo } from 'react';
import { useOrderContext } from '../../../hooks';
import {
  StyledAddMoreOptionsCountdown,
  StyledAddMoreOptionsGrid,
  StyledAddMoreOptionsHeader,
  StyledAddMoreOptionsWrapper,
  StyledCardPlaceholder,
  StyledCheckoutButton,
  StyledCheckoutWrapper,
  StyledPrimaryHeader,
} from './styled';
import {
  useCalculateTotalPrice,
  useNavigateWithParams,
  useScopedTranslation,
} from '../../../../../shared/hooks';
import AdditionalOptionCard from './components/AdditionalOptionCard';
import { isAfter, parseISO } from 'date-fns';
import { Paths } from '../../../../../router/routes';
import { AppContext } from '../../../../../shared/context';
import useGetAvailableOrderOptions from '../../../hooks/useGetAvailableOrderOptions';

const OrderAddMoreOptions = () => {
  const { t } = useScopedTranslation('order.additional_options');
  const { selectedAdditionalOptions } = useContext(AppContext);
  const { order, options, getOrder } = useOrderContext();
  const { urlWithParams } = useNavigateWithParams();
  const orderOptions = useGetAvailableOrderOptions();

  const availableOptions = useMemo(() => {
    const orderOptionNames = orderOptions?.map((option) => option.name) ?? [];
    return (
      options
        ?.map(({ name }) => name)
        .filter((optionName) => !orderOptionNames.includes(optionName)) ?? []
    );
  }, [order, options]);

  const discountExpiresAt = useMemo(() => {
    const expiresAt = order?.options_prices?.expires_at;
    if (!expiresAt) return null;
    return (isAfter(parseISO(expiresAt), new Date()) && expiresAt) || null;
  }, [order]);

  const { totalPrice } = useCalculateTotalPrice({
    storedOptions: selectedAdditionalOptions,
    withOrder: false,
  });

  const placeholderNum = useMemo(() => 3 - availableOptions.length, [availableOptions]);

  return (
    (!!availableOptions.length && (
      <StyledAddMoreOptionsWrapper>
        <StyledAddMoreOptionsHeader>
          {(discountExpiresAt && (
            <>
              <StyledPrimaryHeader>
                {t('header_discount_primary', { discount: 25 })}
              </StyledPrimaryHeader>
              <br />
              <span>{t('header_discount')}</span>
            </>
          )) ||
            t('header')}
        </StyledAddMoreOptionsHeader>

        {discountExpiresAt && (
          <StyledAddMoreOptionsCountdown
            date={discountExpiresAt}
            units={['hours', 'minutes', 'seconds']}
            onTargetReach={getOrder}
          />
        )}

        <StyledAddMoreOptionsGrid>
          {availableOptions.map((option) => (
            <AdditionalOptionCard key={option} option={option} />
          ))}
          {Array.from(Array(placeholderNum), (e, i) => (
            <StyledCardPlaceholder key={`skel-${i}`} />
          ))}
        </StyledAddMoreOptionsGrid>

        {!!parseInt(totalPrice) && (
          <StyledCheckoutWrapper>
            <StyledCheckoutButton
              to={urlWithParams(Paths.addOptionsCheckout, {
                orderId: order?.uuid?.toString() ?? '',
              })}
              noShadow
              color={'primary'}
            >
              {t('checkout', { total: parseInt(totalPrice) })}
            </StyledCheckoutButton>
          </StyledCheckoutWrapper>
        )}
      </StyledAddMoreOptionsWrapper>
    )) || <></>
  );
};

export default OrderAddMoreOptions;
