import React from 'react';
import {
  StyledAudioPlayer,
  StyledExampleText,
  StyledHeader,
  StyledRecommendation,
  StyledRecommendationsWrapper,
  StyledRecorderWrapper,
  StyledSubheader,
  StyledWaveIcon,
} from '../styled';
import { useScopedTranslation } from '../../../../../shared/hooks';

const RecorderInfo = ({ limit }: { limit: number }) => {
  const { t } = useScopedTranslation('order.editors.client_voice');

  return (
    <StyledRecorderWrapper>
      <StyledWaveIcon type={'Wave'} />

      <StyledHeader>{t('recording_voices')}</StyledHeader>
      <StyledSubheader>{t('recording_recommendations')}</StyledSubheader>

      <StyledRecommendationsWrapper>
        <StyledRecommendation>
          <span>01</span>
          <span>{t('quiet_place')}</span>
        </StyledRecommendation>
        <StyledRecommendation>
          <span>02</span>
          <span>{t('limited_time', { limit })}</span>
        </StyledRecommendation>
        <StyledRecommendation>
          <span>03</span>
          <span>{t('iphone')}</span>
        </StyledRecommendation>
      </StyledRecommendationsWrapper>

      <StyledExampleText>{t('listen_to_example')}</StyledExampleText>
      <StyledAudioPlayer src={''} label={'Example'} />
    </StyledRecorderWrapper>
  );
};

export default RecorderInfo;
