import styled from '@emotion/styled';
import { Grid, Text } from '@chakra-ui/react';

export const StyledOptionsBg = styled.div`
  background: ${({ theme }) => theme.palette.neutral5};
  padding-top: 6.25rem;
  padding-bottom: 5.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

export const StyledOptionsHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.darker};
  font-size: ${({ theme }) => theme.fontSize.xl5};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  line-height: 3.75rem;
  margin-bottom: 3.75rem;
  white-space: pre-line;

  span {
    color: ${({ theme }) => theme.palette.primary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    white-space: unset;
    line-height: 2.25rem;
    margin-bottom: 1.5rem;
    max-width: 20rem;
  }
`;

export const StyledOptionsWrapper = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.875rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;
