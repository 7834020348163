import React from 'react';
import {
  useBreakpoints,
  useHandleBackgroundColor,
  useHandleHeaderBackClick,
  useScopedTranslation,
} from '../../../../shared/hooks';
import { designSystem } from '../../../../shared/theme';
import { MotionContainer, OrderOptionIsProcessing } from '../../../../shared/components/custom';
import useRedirectToOrder from '../../hooks/useRedirectToOrder';
import { Paths } from '../../../../router/routes';
import { LinkButton } from '../../../../shared/components/ui';
import { useOrderContext } from '../../hooks';
import { OrderOptions } from '../../../../shared/constants/OrderOptions';

const OrderVideoInWork = () => {
  const { t } = useScopedTranslation('order.editors.video');
  const { routeWithOrder } = useRedirectToOrder();
  const { order } = useOrderContext();
  const { navigateToOrder } = useRedirectToOrder();

  const { isLg } = useBreakpoints();
  useHandleBackgroundColor((isLg && designSystem.palette.neutral5) || designSystem.palette.white);
  useHandleHeaderBackClick(
    true,
    () => {
      if (order) navigateToOrder();
    },
    [order],
  );

  return (
    <MotionContainer>
      <OrderOptionIsProcessing
        title={t('congratulations')}
        subheader={t('as_soon_as')}
        type={OrderOptions.video}
        actionButton={
          <LinkButton to={routeWithOrder(Paths.order)} color={'primary'}>
            {t('go_to_home')}
          </LinkButton>
        }
      />
    </MotionContainer>
  );
};

export default OrderVideoInWork;
