import React from 'react';
import { Icon } from '../../../../../../shared/components/icons';
import {
  StyledOptionCard,
  StyledOptionCardHeader,
  StyledOptionCardIcon,
  StyledOptionCardLinkButton,
} from '../styled';
import useProcessOptionDeadline from '../hooks/useProcessOptionDeadline';
import { OrderOption } from '../../../../../../shared/types/orderOption';
import OptionProgress from './OptionProgress';
import { Spacer } from '@chakra-ui/react';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import useRedirectToOrder from '../../../../hooks/useRedirectToOrder';
import { Paths } from '../../../../../../router/routes';
import PaymentIsProcessing from './PaymentIsProcessing';
import PaidOverlay from './PaidOverlay';

type IOptionCard = {
  option: OrderOption;
};

const VoiceOptionCard = ({ option }: IOptionCard) => {
  const { t } = useScopedTranslation('order.editors.client_voice');
  const { routeWithOrder } = useRedirectToOrder();
  const { completion, isInProgress, isFinished, isWaitingForPayment, totalTimeDays } =
    useProcessOptionDeadline(option);

  return (
    (!isFinished && (
      <StyledOptionCard>
        <PaidOverlay />
        {(isInProgress && (
          <OptionProgress
            completion={completion}
            icon={'Wave'}
            title={t('processing_header')}
            hint={t('processing_text', { totalTimeDays })}
          />
        )) || (
          <>
            <StyledOptionCardHeader>{t('record_voice_header')}</StyledOptionCardHeader>
            <Spacer />
            <StyledOptionCardIcon>
              <Icon type={'Wave'} />
            </StyledOptionCardIcon>
            <Spacer />
            {(isWaitingForPayment && <PaymentIsProcessing />) || (
              <StyledOptionCardLinkButton to={routeWithOrder(Paths.orderClientVoiceEditorRecorder)}>
                <Icon type={'Dot'} color={'primary'} />
                <span>{t('record_voice')}</span>
              </StyledOptionCardLinkButton>
            )}
          </>
        )}
      </StyledOptionCard>
    )) || <></>
  );
};

export default VoiceOptionCard;
