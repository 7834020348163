import React, { useEffect } from 'react';
import useFunnelContext from '../../hooks/useFunnelContext';
import { useHandleHeaderBackClick, useScopedTranslation } from '../../../../shared/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  StyledFunnelActionWrapper,
  StyledHeaderWithStepper,
  StyledSelectorsWrapper,
  StyledStepForm,
  StyledStepHeader,
  StyledStepHeading,
  StyledStepper,
  StyledStepSubheader,
} from '../../styled';
import { Button } from '../../../../shared/components/ui';
import { ContactInformationSchema } from '../../validationSchema';
import Receiver from './Receiver';
import Gifter from './Gifter';
import Email from './Email';
import { FunnelSteps } from '../../../../shared/constants/FunnelSteps';

const ContactInformation = () => {
  const { parameters, setParameters, funnelStep, setFunnelStep, funnelTotalSteps } =
    useFunnelContext();
  const { t, rootT } = useScopedTranslation('funnel.contact_information');
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ContactInformationSchema),
  });

  useHandleHeaderBackClick(true, () => setFunnelStep(FunnelSteps.PERSONAL));

  useEffect(() => {
    reset({ ...parameters });
  }, []);

  const nextStep = (args: Object) => {
    setParameters({ ...parameters, ...args });
    setFunnelStep(funnelStep + 1);
  };

  return (
    <StyledStepForm onSubmit={handleSubmit(nextStep)}>
      <StyledStepHeading>
        <StyledHeaderWithStepper>
          <StyledStepHeader>{t('header')}</StyledStepHeader>
          <StyledStepper step={funnelStep + 1} totalSteps={funnelTotalSteps} />
        </StyledHeaderWithStepper>
        <StyledStepSubheader>{t('subheader')}</StyledStepSubheader>
      </StyledStepHeading>
      <StyledSelectorsWrapper>
        <Receiver register={register} errors={errors} />
        <Gifter register={register} errors={errors} />
        <Email register={register} errors={errors} />
      </StyledSelectorsWrapper>
      <StyledFunnelActionWrapper>
        <Button type={'submit'} color={'primary'}>
          {rootT('funnel.continue')}
        </Button>
      </StyledFunnelActionWrapper>
    </StyledStepForm>
  );
};

export default ContactInformation;
