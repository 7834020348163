import React from 'react';
import { StyledDesktopDivider, StyledDivider, StyledFAQWrapper } from './styled';
import OrderDemoPreview from './OrderDemoPreview';
import OrderOffer from './OrderOffer';
import OrderAreYouStillThinking from './OrderAreYouStillThinking';
import OrderOptions from './OrderOptions';
import { useHandleBackgroundColor } from '../../../../../shared/hooks';
import { designSystem } from '../../../../../shared/theme';
import FAQTabs from '../../../../../shared/components/custom/FAQ/components/FAQTabs';

const OrderReady = () => {
  useHandleBackgroundColor(designSystem.palette.white);

  return (
    <>
      <OrderDemoPreview />
      <StyledDivider />
      <OrderOffer />
      <StyledDivider />
      <OrderAreYouStillThinking />
      <StyledDesktopDivider />
      <OrderOptions />
      <StyledFAQWrapper>
        <FAQTabs />
      </StyledFAQWrapper>
    </>
  );
};

export default OrderReady;
