import React from 'react';
import { StyledArtistCard, StyledArtistInfo } from './styled';
import { Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';

const ArtistCardSkeleton = () => {
  return (
    <StyledArtistCard>
      <Skeleton w={'12.5rem'} h={'12.5rem'} />
      <StyledArtistInfo>
        <div>
          <Skeleton w={'10rem'} h={'1.5rem'} mb={'1rem'} />
          <Skeleton w={'100%'} h={'1rem'} mb={'0.5rem'} />
          <Skeleton w={'100%'} h={'1rem'} mb={'0.5rem'} />
          <Skeleton w={'85%'} h={'1rem'} mb={'0.5rem'} />
        </div>
        <Flex alignItems={'center'}>
          <SkeletonCircle w={'2.5rem'} h={'2.5rem'} mr={'1rem'} />
          <Skeleton w={'5rem'} h={'1rem'} />
        </Flex>
      </StyledArtistInfo>
    </StyledArtistCard>
  );
};

export default ArtistCardSkeleton;
