import React, { useEffect, useState } from 'react';
import {
  StyledCounter,
  StyledHeader,
  StyledLabel,
  StyledRecorderButton,
  StyledRecorderContainer,
} from './styled';
import { audioRecorder, RecorderType } from '../../../utils/audioRecorder';
import { addMilliseconds, formatISO } from 'date-fns';
import { useScopedTranslation } from '../../../hooks';
import { Alert } from '../Alert';
import { useDisclosure } from '@chakra-ui/react';

type IVoiceRecorder = {
  onStart: Function;
  onStop: Function;
  maxDuration?: number;
  withHeader?: boolean;
};

const VoiceRecorder = ({ onStart, onStop, maxDuration, withHeader = false }: IVoiceRecorder) => {
  const { t, rootT } = useScopedTranslation('order.editors.client_voice');
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState<RecorderType | null>(null);
  const [timer, setTimer] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    audioRecorder().then((rec) => setRecorder(rec));

    return () => {
      if (recorder) recorder.stop();
    };
  }, []);

  const startRecording = async () => {
    if (!recorder) return;
    recorder
      .start()
      .then(() => {
        onStart();
        setIsRecording(true);
        if (maxDuration) setTimer(formatISO(addMilliseconds(new Date(), maxDuration)));
      })
      .catch(() => onOpen());
  };

  const stopRecording = async () => {
    if (!recorder) return;
    const result = await recorder.stop();
    onStop(result);
    setIsRecording(false);
  };

  const toggleRecording = async () => {
    if (isRecording) await stopRecording();
    else await startRecording();
  };

  return (
    <>
      <StyledRecorderContainer>
        {withHeader && <StyledHeader>Press "Record" to start</StyledHeader>}
        <StyledCounter
          date={timer}
          initialTime={maxDuration}
          units={['minutes', 'seconds']}
          milliseconds
          onTargetReach={stopRecording}
          labels={false}
        />
        <StyledRecorderButton
          isDisabled={!recorder || recorder?.isSettingUp}
          onClick={toggleRecording}
          recording={isRecording}
          color={'primary'}
        />
        <StyledLabel>{t((isRecording && 'stop') || 'record')}</StyledLabel>
      </StyledRecorderContainer>
      <Alert
        title={rootT('general.alerts.no_mic')}
        text={rootT('general.alerts.no_mic_text')}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
    </>
  );
};

export default VoiceRecorder;
