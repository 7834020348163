import styled from '@emotion/styled';
import { ContentContainer } from '../../ui';

export const StyledApp = styled.div`
  background: ${({ theme }) => theme.palette.white};
`;

export const StyledContentContainer = styled(ContentContainer)`
  min-height: calc(100vh - 13.75rem);
  padding-top: 1.75rem;
`;
