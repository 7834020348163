import React from 'react';
import { StyledOptionOverlay } from '../styled';
import useIsPaid from '../../../../hooks/useIsPaid';
import { useScopedTranslation } from '../../../../../../shared/hooks';

const PaidOverlay = () => {
  const { t } = useScopedTranslation('general');
  const isPaid = useIsPaid();

  return (isPaid && <StyledOptionOverlay>{t('song_not_ready')}</StyledOptionOverlay>) || <></>;
};

export default PaidOverlay;
