import { Paths } from '../../../router/routes';
import { useNavigateWithParams } from '../../../shared/hooks';
import { useGetOrderId } from './index';

const useRedirectToOrder = () => {
  const orderId = useGetOrderId();
  const { urlWithParams, navigate } = useNavigateWithParams();

  const navigateToOrder = () => navigate(Paths.order, { orderId });

  const navigateWithOrder = (path: Paths) => navigate(path, { orderId });

  const routeWithOrder = (path: Paths) => urlWithParams(path, { orderId });

  return { navigateToOrder, navigateWithOrder, routeWithOrder };
};

export default useRedirectToOrder;
