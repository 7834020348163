import React from 'react';
import { StyledReviewsCarousel, StyledReviewsWrapper } from './styled';
import OrderReview1 from './OrderReview1';
import OrderReview2 from './OrderReview2';
import OrderReview3 from './OrderReview3';
import OrderReview4 from './OrderReview4';

const Reviews = () => {
  return (
    <>
      <StyledReviewsCarousel>
        <OrderReview1 />
        <OrderReview2 />
        <OrderReview3 />
        <OrderReview4 />
      </StyledReviewsCarousel>

      <StyledReviewsWrapper>
        <OrderReview1 />
        <OrderReview2 />
        <OrderReview3 />
        <OrderReview4 />
      </StyledReviewsWrapper>
    </>
  );
};

export default Reviews;
