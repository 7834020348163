import styled from '@emotion/styled';
import { Flex } from '@chakra-ui/react';
import { Button } from '../../ui';

export const StyledHeaderWrapper = styled(Flex)`
  align-items: center;
  height: 4rem;
  padding: 0.75rem 1.25rem;
  grid-gap: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.card};
`;

export const StyledBackButton = styled(Button)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  box-shadow: none;
  background: ${({ theme }) => theme.palette.neutral10};
  position: relative;
`;

export const StyledPriceBadge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.primary};
  position: absolute;
  top: -0.35rem;
  right: -0.35rem;
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.palette.white};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  border-radius: ${({ theme }) => theme.radii.xl};
`;

export const StyledLogo = styled(Flex)`
  flex: 1 1;
  justify-content: center;
`;

export const StyledMenuButtonWrapper = styled.div`
  width: 2.5rem;
  height: 2.5rem;
`;

export const StyledMenuButton = styled(Button)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  box-shadow: none;
  background: ${({ theme }) => theme.palette.neutral10};
`;
