import React from 'react';
import { Outlet } from 'react-router-dom';
import { OrderContextProvider } from './context/OrderContext';
import useLoadOrderById from './requests/useLoadOrderById';
import useLoadOrderOptions from '../../shared/requests/useLoadOrderOptions';
import { usePostcard } from '../../shared/hooks';

const OrderPage = () => {
  const { order, getOrder } = useLoadOrderById();
  const { options } = useLoadOrderOptions();
  const {
    postcard,
    getPostcard,
    createPostcard,
    updatePostcard,
    updatePostcardImage,
    isLoading: isLoadingPostcard,
  } = usePostcard(order?.uuid);

  return (
    <OrderContextProvider
      value={{
        order,
        options,
        getOrder,
        postcard,
        getPostcard,
        createPostcard,
        updatePostcard,
        updatePostcardImage,
        isLoadingPostcard,
      }}
    >
      <Outlet />
    </OrderContextProvider>
  );
};

export default OrderPage;
