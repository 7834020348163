export const items = [
  [
    {
      title: 'How soon will I have my song ready?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor' +
        'incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud' +
        'exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      title: 'How long will my song be?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor' +
        'incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud' +
        'exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      title: 'How do I receive my song?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor' +
        'incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud' +
        'exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
  ],
  [
    {
      title: 'What requests can I leave to my order?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor' +
        'incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud' +
        'exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      title: 'Do you provide any guarantees?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor' +
        'incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud' +
        'exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      title: 'Can I order only lyrics or instrumental?',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor' +
        'incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud' +
        'exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
  ],
];
