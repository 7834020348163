import { Box, Flex, Grid, Progress, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import {
  AudioPlayer,
  Button,
  ContentContainer,
  LinkButton,
} from '../../../../../shared/components/ui';
import { Link } from 'react-router-dom';
import { Countdown } from '../../../../../shared/components/custom';

export const StyledWrapper = styled(Grid)`
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    margin-top: 1rem;
  }
`;

export const StyledHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl4};
  color: ${({ theme }) => theme.colors.text.darker};
  margin-bottom: 1rem;
  line-height: 3rem;
  max-width: 23rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    line-height: 2.25rem;
    margin-bottom: 0.75rem;
    color: ${({ theme }) => theme.colors.text.main};
  }
`;

export const StyledOrderPaidWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;

  background: ${({ theme }) => theme.palette.neutral5};
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 1.25rem 1.5rem;

  h3 {
    font-size: ${({ theme }) => theme.fontSize.xl};
    color: ${({ theme }) => theme.colors.text.main};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    margin-bottom: 0.25rem;
  }

  p {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.placeholder};
  }

  > div:last-of-type {
    margin-right: 1rem;
    width: 2rem;
    height: 2rem;
  }
`;

export const StyledOrderId = styled.span`
  color: ${({ theme }) => theme.palette.primary};
`;

export const StyledSongContentContainer = styled(ContentContainer)`
  padding-bottom: 2.75rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-bottom: 0;
  }
`;

export const StyledClientVoiceTab = styled(Flex)`
  grid-gap: 0.5rem;
  align-items: center;
`;

export const StyledSongCard = styled(Box)`
  background: ${({ theme }) => theme.palette.neutral5};
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 1.25rem 1.5rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;

  > div:last-of-type > button {
    min-width: 11rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    > div:last-of-type > button {
      width: 100%;
    }
  }
`;

export const StyledAudioPlayer = styled(AudioPlayer)`
  p {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;

export const StyledUploadOptionsWrapper = styled.div`
  padding-top: 3.25rem;
  position: relative;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.palette.neutral5} 80%,
    ${({ theme }) => theme.palette.white}
  );

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-top: 0.25rem;
  }
`;

export const StyledSectionHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl3};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 2rem;
  margin-bottom: 1.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    line-height: 3rem;
    font-size: ${({ theme }) => theme.fontSize.xl4};
  }
`;

export const StyledOptionCardsWrapper = styled(Grid)`
  grid-gap: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
  }
`;

export const StyledCardPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background: #71778814;
  border-radius: ${({ theme }) => theme.radii.sm};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledOptionCard = styled(Box)`
  background: ${({ theme }) => theme.palette.white};
  box-shadow: ${({ theme }) => theme.shadow.card};
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 1rem;
  min-height: 15rem;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledOptionOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  backdrop-filter: blur(2px);

  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.palette.neutral40};
`;

export const StyledOptionCardHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.xl2};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 2rem;
  white-space: pre-line;
`;

export const StyledOptionProgressHeader = styled(StyledOptionCardHeader)`
  color: ${({ theme }) => theme.colors.text.placeholder};
  margin-bottom: 1rem;
  text-align: center;
`;

export const StyledOptionProgressHint = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 1.25rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const StyledOptionCardIcon = styled.div`
  min-height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 4.5rem;
    height: auto;

    path {
      fill: #bcbfc1;
    }
  }
`;

export const StyledOptionCardLinkButton = styled(LinkButton)`
  box-shadow: none;
  width: 100%;
`;

export const StyledOptionCardButton = styled(Button)`
  box-shadow: none;
  width: 100%;
`;

export const StyledProgress = styled(Progress)`
  background: ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
  height: 0.5rem;
  margin-bottom: 1rem;

  > div {
    transition: 0.3s ease-in;
    background: ${({ theme }) => theme.palette.success};
    border-radius: ${({ theme }) => theme.radii.sm};
  }
`;

export const StyledOptionCardHeaderWithEdit = styled(Flex)`
  align-items: center;
  justify-content: space-between;

  span:first-of-type {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    font-size: ${({ theme }) => theme.fontSize.xl2};
    color: ${({ theme }) => theme.colors.text.main};
    line-height: 2rem;
  }
`;

export const StyledPostcardPreview = styled(Link)`
  padding: 1rem;
  border-radius: ${({ theme }) => theme.radii.sm};
  border: 1px solid #efeef3;
  margin: 1rem 0;
  overflow: hidden;
  position: relative;
  max-height: 11rem;
  flex: 1 1;
  display: block;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.gradients.fadeToBottom};
  }
`;

export const StyledPostcardTitle = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.xl2};
  line-height: 2rem;
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
`;

export const StyledPostcardText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.sm};
  line-height: 1.5rem;
  white-space: pre-wrap;
  word-break: break-word;
  margin-bottom: 1.5rem;
`;

export const StyledVideoContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;

  video {
    border-radius: ${({ theme }) => theme.radii.sm};
  }
`;

export const StyledEditOption = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.palette.primary};
  line-height: 1.25rem;
`;

export const StyledEditOptionButton = styled(Link)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.palette.primary};
  line-height: 1.25rem;
`;

export const StyledAddMoreOptionsHeader = styled(StyledSectionHeader)`
  max-width: 22rem;
  white-space: pre-line;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: 30rem;
  }
`;

export const StyledPrimaryHeader = styled.span`
  color: ${({ theme }) => theme.palette.primary};
`;

export const StyledAddMoreOptionsCountdown = styled(Countdown)`
  font-size: ${({ theme }) => theme.fontSize.xl3};
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    line-height: 3rem;
    font-size: ${({ theme }) => theme.fontSize.xl3};
  }
`;

export const StyledAddMoreOptionsWrapper = styled.div`
  margin-top: 4.5rem;
`;

export const StyledAddMoreOptionsGrid = styled(Grid)`
  padding-top: 1rem;
  padding-bottom: 2rem;
  grid-gap: 0.75rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const StyledSongTextWrapper = styled.div`
  max-width: 20rem;
`;

export const StyledSongVersionSelectWrapper = styled.div`
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;

  select {
    background: ${({ theme }) => theme.palette.white};
  }
`;

export const StyledSongText = styled.div`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-style: italic;
  line-height: 1.5rem;
  white-space: pre-wrap;
`;

export const StyledSongTextHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
`;

export const StyledCheckoutWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    position: sticky;
    bottom: 0;
  }
`;

export const StyledCheckoutButton = styled(LinkButton)`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 20rem;
  }
`;
