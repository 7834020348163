import { useMemo } from 'react';
import { OrderStatuses } from '../../../shared/constants/OrderStatuses';
import { useOrderContext } from './index';

const useIsPaid = () => {
  const { order } = useOrderContext();

  return useMemo(() => order?.status === OrderStatuses.paid, [order]);
};

export default useIsPaid;
