import React from 'react';
import { Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import { StyledTabs } from './styled';

type ITabs = {
  tabs: Array<string | React.ReactNode>;
  children: React.ReactNode[];
};

const Tabs = ({ tabs, children, ...props }: ITabs) => {
  return (
    <StyledTabs {...props}>
      <TabList>
        {tabs.map((tab, index) => (
          <Tab key={`tab-${index}`}>{tab}</Tab>
        ))}
      </TabList>

      <TabPanels>
        {children.map((panel, index) => (
          <TabPanel key={`panel-${index}`}>{panel}</TabPanel>
        ))}
      </TabPanels>
    </StyledTabs>
  );
};

export default Tabs;
