import React, { useMemo } from 'react';
import { Icon, SVGKey } from '../../../../shared/components/icons';
import {
  StyledOption,
  StyledOptionIconContainer,
  StyledOptionName,
  StyledOptionPrice,
  StyledRemoveButton,
} from './styled';
import { OrderOptions } from '../../../../shared/constants/OrderOptions';
import { useScopedTranslation, useToggleOrderOption } from '../../../../shared/hooks';
import { Skeleton } from '@chakra-ui/react';
import { useGetOptionInfo, useOrderContext } from '../../hooks';
import { LocalStorageKeys } from '../../../../shared/constants/LocalStorageKeys';
import { ConfirmDialog } from '../../../../shared/components/custom';

const CheckoutOption = ({
  name,
  onRemove,
  isOrder = false,
  price = null,
  storageKey,
}: {
  name: OrderOptions;
  onRemove: Function;
  isOrder?: boolean;
  price?: number | null;
  storageKey: LocalStorageKeys;
}) => {
  const { t } = useScopedTranslation('order.checkout_page');
  const { toggleOption } = useToggleOrderOption(name, storageKey);
  const mapIconsByOption = (option: OrderOptions): SVGKey =>
    (({
      [OrderOptions.clientVoice]: 'Wave',
      [OrderOptions.duet]: 'Mic',
      [OrderOptions.video]: 'Slides',
    }[option] ?? null) as SVGKey);
  const iconType = useMemo(() => mapIconsByOption(name), [name]);
  const { order } = useOrderContext();
  const optionInfo = useGetOptionInfo(name);
  const optionName = t(`options.${(isOrder && 'your_song') || name}`);

  const optionPrice = useMemo(() => price || optionInfo?.price, [price, optionInfo]);
  const discountedPrice = useMemo(
    () => price || order?.options_prices?.prices[name],
    [order, price, name],
  );

  const removeOption = () => {
    toggleOption();
    onRemove();
  };

  return (
    <StyledOption>
      <StyledOptionIconContainer>
        <Icon type={(isOrder && 'Music') || iconType} color={'primary'} />
      </StyledOptionIconContainer>
      <div>
        <StyledOptionName>{optionName}</StyledOptionName>
        {(optionPrice && (
          <StyledOptionPrice>${discountedPrice || optionPrice}</StyledOptionPrice>
        )) || <Skeleton w={'2rem'} h={'1rem'} />}
      </div>
      {!isOrder && (
        <ConfirmDialog
          title={t('are_you_sure', { option: optionName })}
          confirmLabel={t('delete')}
          cancelLabel={t('cancel')}
          onConfirm={removeOption}
          activator={
            <StyledRemoveButton fab noShadow>
              <Icon type={'Trashcan'} />
            </StyledRemoveButton>
          }
        />
      )}
    </StyledOption>
  );
};

export default CheckoutOption;
