import React from 'react';
import RecorderInfo from './RecorderInfo';
import { StyledDesktopCard, StyledDesktopWrapper } from '../styled';
import VoiceVisual from './VoiceVisual';
import { IRecorderPage } from './RecorderWrapper';
import { MAX_CLIENT_VOICE_DURATION } from '../../../../../shared/constants';

const MobileRecorder = ({ isPreview, previewComponent, isRecording, children }: IRecorderPage) => {
  return (
    <>
      {(isPreview && previewComponent) || (
        <StyledDesktopWrapper>
          <StyledDesktopCard>
            {(isRecording && <VoiceVisual isWorking={isRecording} />) || (
              <RecorderInfo limit={MAX_CLIENT_VOICE_DURATION / 1000} />
            )}
            {children}
          </StyledDesktopCard>
        </StyledDesktopWrapper>
      )}
    </>
  );
};

export default MobileRecorder;
