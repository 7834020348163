import React, { useMemo } from 'react';
import { StyledTextarea } from './styled';
import { InputProps } from '@chakra-ui/react';
import { FormErrorMessage } from '../FormErrorMessage';
import { FieldErrorsImpl } from 'react-hook-form';

type CustomInputProps = {
  name: string;
  register?: Function;
  errors?: FieldErrorsImpl;
  autosize?: boolean;
  isDisabled?: boolean;
};

const Textarea = ({
  register = () => {},
  errors = {},
  name,
  autosize = false,
  isDisabled = false,
  ...props
}: InputProps & CustomInputProps) => {
  const errorMessage = useMemo(() => errors[name]?.message as string, [errors]);

  return (
    <div>
      <StyledTextarea {...{ ...props, ...register(name), name, disabled: isDisabled }} />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </div>
  );
};

export default Textarea;
