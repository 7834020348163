import React, { useEffect, useState } from 'react';
import useRedirectToOrder from '../../hooks/useRedirectToOrder';
import { useOrderContext } from '../../hooks';
import {
  useBreakpoints,
  useHandleBackgroundColor,
  useHandleHeaderBackClick,
  useScopedTranslation,
} from '../../../../shared/hooks';
import { designSystem } from '../../../../shared/theme';
import { Paths } from '../../../../router/routes';
import { Button } from '../../../../shared/components/ui';
import {
  StyledDivider,
  StyledEditorWrapper,
  StyledFormWrapper,
  StyledTextarea,
  StyledTextCounter,
  StyledTitleInput,
} from './styled';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PostcardSchema } from './validationSchema';
import { MAX_POSTCARD_TEXT_LENGTH } from '../../../../shared/constants';
import PostcardFormImage from './components/PostcardFormImage';
import PostcardTrack from './components/PostcardTrack';
import PostcardFormSkeleton from './components/PostcardFormSkeleton';
import { MotionContainer } from '../../../../shared/components/custom';

const OrderPostcardForm = () => {
  const { t } = useScopedTranslation('order.editors.postcard');
  const [maxTextLength] = useState(MAX_POSTCARD_TEXT_LENGTH);
  const { navigateWithOrder } = useRedirectToOrder();
  const { order, postcard, updatePostcard, updatePostcardImage, isLoadingPostcard } =
    useOrderContext();
  const { isLg } = useBreakpoints();
  useHandleBackgroundColor((isLg && designSystem.palette.neutral5) || designSystem.palette.white);

  useHandleHeaderBackClick(
    true,
    () => {
      if (order) navigateWithOrder(Paths.orderPostcardEditorSongSelect);
    },
    [order],
  );

  const [image, setImage] = useState<File | null>(null);

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PostcardSchema),
  });

  const postcardText = watch('text');

  useEffect(() => {
    if (postcard) {
      const { title, text, track_variant: trackVariant } = postcard;
      reset({ title: title || '', text: text || '', track_variant: trackVariant });
    }
  }, [postcard]);

  const onSubmit = async (args: Object) => {
    if (image) {
      const formData = new FormData();
      formData.append('image', image);
      await updatePostcardImage(formData);
    }
    await updatePostcard(args);
    navigateWithOrder(Paths.orderPostcard);
  };

  return (
    <MotionContainer>
      <StyledEditorWrapper>
        <PostcardFormImage isEdit setImage={setImage} cover={postcard?.image_url ?? ''} />

        <StyledFormWrapper>
          <PostcardTrack track={postcard?.track} />

          <StyledDivider />

          {(!postcard && isLoadingPostcard && <PostcardFormSkeleton />) || (
            <>
              <StyledTitleInput
                register={register}
                errors={errors}
                name={'title'}
                isDisabled={isLoadingPostcard}
                placeholder={t('enter_title')}
              />
              <StyledTextarea
                register={register}
                errors={errors}
                name={'text'}
                autosize
                isDisabled={isLoadingPostcard}
                placeholder={t('congratulation_text')}
              />
              <StyledTextCounter isred={(postcardText?.length ?? 0) > maxTextLength}>
                <span>{postcardText?.length ?? 0} </span>
                <span>/ {maxTextLength}</span>
              </StyledTextCounter>
            </>
          )}
        </StyledFormWrapper>

        <Button
          mt={'2rem'}
          block
          isLoading={isLoadingPostcard}
          onClick={handleSubmit(onSubmit)}
          type={'button'}
          color={'primary'}
        >
          {t((postcard?.title && 'save') || 'create_e_postcard')}
        </Button>
      </StyledEditorWrapper>
    </MotionContainer>
  );
};

export default OrderPostcardForm;
