import { useMemo } from 'react';
import { OrderOptions } from '../constants/OrderOptions';
import { useOrderContext } from '../../modules/order/hooks';
import useProcessOrderOffer from '../../modules/order/hooks/useProcessOrderOffer';

type ICalculateProps = {
  storedOptions: OrderOptions[];
  discount?: number;
  withOrder?: boolean;
};

export const useCalculateTotalPrice = ({
  storedOptions,
  discount = 0,
  withOrder = true,
}: ICalculateProps) => {
  const { order, options } = useOrderContext();
  const { price } = useProcessOrderOffer();

  const totalPrice: number = useMemo(() => {
    return (
      ((withOrder && (price.current ?? 0)) || 0) +
      options
        .filter((option) => storedOptions.includes(option.name))
        .reduce(
          (sum, option) => sum + (order?.options_prices?.prices[option.name] ?? option.price),
          0,
        )
    );
  }, [price, options, storedOptions]);

  const totalPriceWithDiscount: number = useMemo(
    () => totalPrice - (totalPrice * discount) / 100,
    [totalPrice, discount],
  );

  return {
    totalPrice: totalPrice.toFixed(2),
    totalPriceWithDiscount: totalPriceWithDiscount.toFixed(2),
  };
};
