import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Order } from '../../../shared/types/order';
import { Paths } from '../../../router/routes';
import { useMatchPath, useNavigateWithParams } from '../../../shared/hooks';
import { OrderStatuses } from '../../../shared/constants/OrderStatuses';
import { OrderApi } from '../../../api';

const useLoadOrderById = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState<Order | null>(null);
  const { navigate } = useNavigateWithParams();
  const { orderId } = useParams();
  const isGuardedRoute = useMatchPath([Paths.orderOptions, Paths.orderCheckout]);

  useEffect(() => {
    if (order && order?.status !== OrderStatuses.waitingForPayment && isGuardedRoute) {
      navigate(Paths.order, { orderId: order?.uuid });
    }
  }, [order, isGuardedRoute]);

  const getOrder = async () => {
    setIsLoading(true);

    await OrderApi.getOrder(orderId ?? '')
      .then((response) => {
        setOrder(response);
      })
      .catch(() => {
        navigate(Paths.funnel);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getOrder();
  }, [orderId]);

  return { order, isLoading, getOrder };
};

export default useLoadOrderById;
