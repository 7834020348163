import React from 'react';
import { Icon } from '../../../../shared/components/icons';
import { Card, Datepicker } from '../../../../shared/components/ui';
import { useScopedTranslation } from '../../../../shared/hooks';
import { Control, FieldErrorsImpl } from 'react-hook-form';

const Date = ({ control, errors = {} }: { control: Control; errors?: FieldErrorsImpl }) => {
  const { t } = useScopedTranslation('funnel.general_information');

  return (
    <Card title={t('date')} icon={<Icon type={'CalendarEvent'} color={'primary'} />}>
      <Datepicker control={control} errors={errors} name={'date'} placeholder={'Select a date'} />
    </Card>
  );
};

export default Date;
