import React, { useContext, useMemo } from 'react';
import { useOrderContext } from '../../../../hooks';
import { AppContext } from '../../../../../../shared/context';
import { LocalStorageKeys } from '../../../../../../shared/constants/LocalStorageKeys';
import { OrderOptions } from '../../../../../../shared/constants/OrderOptions';
import OrderOptionVoice from '../../../../../../shared/components/custom/OrderOptionCard/OrderOptionVoice';
import OrderOptionVideo from '../../../../../../shared/components/custom/OrderOptionCard/OrderOptionVideo';
import OrderOptionDuet from '../../../../../../shared/components/custom/OrderOptionCard/OrderOptionDuet';

const AdditionalOptionCard = ({ option }: { option: OrderOptions }) => {
  const { order } = useOrderContext();
  const discountedPrice = useMemo(() => order?.options_prices?.prices[option], [order, option]);

  const { selectedAdditionalOptions, setSelectedAdditionalOptions } = useContext(AppContext);
  const onToggle = () => {
    setSelectedAdditionalOptions(
      (selectedAdditionalOptions.includes(option) &&
        selectedAdditionalOptions.filter((opt) => opt !== option)) || [
        ...selectedAdditionalOptions,
        option,
      ],
    );
  };

  const storageKey = LocalStorageKeys.orderAdditionalOptions;

  const optionsComponents: Record<OrderOptions, React.ReactElement> = {
    [OrderOptions.clientVoice]: (
      <OrderOptionVoice
        key={option}
        discountedPrice={discountedPrice}
        withAction
        storageKey={storageKey}
        onToggle={onToggle}
        bordered
      />
    ),
    [OrderOptions.video]: (
      <OrderOptionVideo
        key={option}
        discountedPrice={discountedPrice}
        withAction
        storageKey={storageKey}
        onToggle={onToggle}
        bordered
      />
    ),
    [OrderOptions.duet]: (
      <OrderOptionDuet
        key={option}
        discountedPrice={discountedPrice}
        withAction
        storageKey={storageKey}
        onToggle={onToggle}
        bordered
      />
    ),
  };

  return optionsComponents[option];
};

export default AdditionalOptionCard;
