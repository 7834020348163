import { useEffect, useState } from 'react';
import { OrderPostcard } from '../types/orderPostcard';
import { SongVersions } from '../constants/SongVersions';
import { useNavigateWithParams } from './useNavigateWithParams';
import { Paths } from '../../router/routes';
import { useMatchPath } from './useMatchPath';
import { OrderApi } from '../../api';

export type PostcardRequest =
  | {
      track_variant: SongVersions;
      title?: string;
      text?: string;
      image?: File;
    }
  | FormData;

export const usePostcard = (orderId?: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [postcard, setPostcard] = useState<OrderPostcard | null>(null);
  const { navigate } = useNavigateWithParams();

  const isForm = useMatchPath([Paths.orderPostcardEditorForm]);

  const getPostcard = async () => {
    if (!orderId) return;
    setIsLoading(true);

    await OrderApi.getPostcard(orderId, {}, true)
      .then((response) => {
        setPostcard(response);
      })
      .catch(() => {
        if (isForm) navigate(Paths.orderPostcardEditorSongSelect, { orderId });
      })
      .finally(() => setIsLoading(false));
  };

  const createPostcard = async (data: PostcardRequest) => {
    if (!orderId) return;
    setIsLoading(true);

    await OrderApi.createPostcard(orderId, data)
      .then((response) => {
        setPostcard(response);
      })
      .finally(() => setIsLoading(false));
  };

  const updatePostcard = async (data: PostcardRequest) => {
    if (!orderId) return;
    setIsLoading(true);

    await OrderApi.updatePostcard(orderId, data)
      .then((response) => {
        setPostcard(response as OrderPostcard);
      })
      .finally(() => setIsLoading(false));
  };

  const updatePostcardImage = async (data: FormData) => {
    if (!orderId) return;
    setIsLoading(true);

    await OrderApi.uploadPostcardPhoto(orderId, data)
      .then((response) => {
        const updatedPostcard = { ...postcard, image_url: response.url };
        setPostcard(updatedPostcard as OrderPostcard);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getPostcard();
  }, [orderId]);

  return { postcard, isLoading, getPostcard, createPostcard, updatePostcard, updatePostcardImage };
};
