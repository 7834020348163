import styled from '@emotion/styled';
import { Button } from '../Button';
import { css } from '@emotion/react';

export const StyledCarousel = styled.div`
  width: 100%;
  padding-bottom: 1rem;

  .slick-dots {
    li {
      margin-inline: 3px;
      box-shadow: none;
      background: ${({ theme }) => theme.palette.neutral10};
      opacity: 1;
      border-radius: ${({ theme }) => theme.radii.sm};
      width: 0.5rem;
      height: 0.5rem;
      transition: 0.3s ease-in-out;

      button:before {
        content: '';
      }
    }

    .slick-active {
      background: ${({ theme }) => theme.palette.primary};
    }
  }
`;

export const StyledArrowButton = styled(Button)<{ prev: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  display: flex !important;
  transform: rotate(${({ prev }) => (prev && '0deg') || '180deg'});
  position: absolute;
  top: 38%;
  box-shadow: none;
  background: ${({ theme }) => theme.palette.neutral10};

  @media (min-width: ${({ theme }) => theme.breakpoints.xl2}) {
    ${({ prev }) =>
      (prev &&
        css`
          left: -3rem;
        `) ||
      css`
        right: -3rem;
      `}
  }

  ${({ prev }) =>
    (prev &&
      css`
        left: 2rem;
      `) ||
    css`
      right: 2rem;
    `}

  &::before {
    content: unset;
  }
`;
