import React from 'react';
import { Button, ModalFooter, ModalHeader } from '../../ui';
import { useDisclosure } from '@chakra-ui/react';
import { useScopedTranslation } from '../../../hooks';
import { StyledButtonsWrapper, StyledModal } from './styled';

type IConfirmDialog = {
  title: string;
  confirmLabel?: string;
  cancelLabel?: string;
  activator: React.ReactNode;
  onConfirm: Function;
};

const ConfirmDialog = ({
  title,
  confirmLabel,
  cancelLabel,
  activator,
  onConfirm,
  ...props
}: IConfirmDialog) => {
  const { t } = useScopedTranslation('general');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const confirmAction = () => {
    onConfirm();
    onClose();
  };

  return (
    <>
      <div className={'activator'} role={'button'} onClick={onOpen}>
        {activator}
      </div>

      <StyledModal {...{ isOpen, onOpen, onClose, size: 'xs', ...props }}>
        <ModalHeader>{title}</ModalHeader>
        <ModalFooter>
          <StyledButtonsWrapper>
            <Button noShadow color={'secondary'} onClick={onClose}>
              {cancelLabel ?? t('no')}
            </Button>
            <Button noShadow color={'primary'} onClick={confirmAction}>
              {confirmLabel ?? t('yes')}
            </Button>
          </StyledButtonsWrapper>
        </ModalFooter>
      </StyledModal>
    </>
  );
};

export default ConfirmDialog;
