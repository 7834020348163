import React from 'react';
import { StyledScreenSubheader, StyledScreenHeader } from './styled';
import { useScopedTranslation } from '../../../../../shared/hooks';
import { Reviews } from '../../../../../shared/components/custom';

const OrderAreYouStillThinking = () => {
  const { t } = useScopedTranslation('order');

  return (
    <>
      <StyledScreenHeader textAlign={'center'}>{t('are_u_still_thinking')}</StyledScreenHeader>
      <StyledScreenSubheader textAlign={'center'}>
        {t('look_at_the_emotions')}
      </StyledScreenSubheader>

      <Reviews />
    </>
  );
};

export default OrderAreYouStillThinking;
