import { useLayoutEffect, useMemo, useState } from 'react';
import { designSystem, ThemeBreakpoints } from '../theme';

export const useBreakpoints = () => {
  const [breakpoints] = useState(designSystem.breakpoints);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const isBreakpoint = (breakpoint: ThemeBreakpoints) => {
    return window.innerWidth >= parseInt(breakpoints[breakpoint]);
  };

  const updateSize = () => {
    setWindowWidth(window.innerWidth);
  };

  const isLg = useMemo(() => isBreakpoint('lg'), [windowWidth]);
  const isMd = useMemo(() => isBreakpoint('md'), [windowWidth]);
  const isSm = useMemo(() => isBreakpoint('sm'), [windowWidth]);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return { windowWidth, isLg, isMd, isSm };
};
